import { AsyncValidator, Validators } from '@angular/forms';
import { constants } from '@core/const/constants';
import PhoneValidator from '@shared/validators/phone.validator';

const PATTERNS = constants.PATTERNS;

export const validations = {
  firstName: (optional: boolean) => [null, [
    ...(!optional ? [Validators.required] : []),
    Validators.minLength(2),
    Validators.maxLength(100)
  ]],
  lastName: (optional: boolean) => [null, [
    ...(!optional ? [Validators.required] : []),
    Validators.minLength(2),
    Validators.maxLength(100)
  ]],
  location: (optional: boolean) => [null,
    !optional ? [Validators.required] : [],
  ],
  email: (optional: boolean, customEmailValidator: AsyncValidator) =>
    [null, [
      ...(!optional ? [Validators.required] : []),
      Validators.maxLength(100),
      Validators.pattern(PATTERNS.EMAIL),
      Validators.pattern(PATTERNS.ANTIBOT),
    ],
    [customEmailValidator],
  ],
  zipCode: (optional: boolean) => [null, [
    ...(!optional ? [Validators.required] : []),
    Validators.pattern(PATTERNS.ZIP),
  ]],
  phone: (optional: boolean) => [null, [
    ...(!optional ? [Validators.required] : []),
    Validators.minLength(10),
    Validators.maxLength(15),
    Validators.pattern(PATTERNS.PHONE),
    PhoneValidator,
  ]],
  time: (optional: boolean) => [
    null,
    !optional ? [Validators.required] : [],
  ],
  goal: (optional: boolean) => [
    null,
    !optional ? [Validators.required] : [],
  ],
  checkboxTerms: (optional: boolean) => [
    false,
    !optional ? [Validators.requiredTrue] : [],
  ],
  checkboxCallMe: (optional = true) => [
    false,
    !optional ? checkboxCallMeRules : [],
  ],
};

export const checkboxCallMeRules = [Validators.requiredTrue];
