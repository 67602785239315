import { CMSData, Plans } from '@models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface IIntro {
    intro_slide_image_desktop: string;
    intro_slide_image_tablet: string;
    intro_slide_image_mobile: string;
    welcome_title: string;
    welcome_title_color: string;
    welcome_title_size: number;
    club_title: string;
    club_title_color: string;
    club_title_size: number;
    promo_title: string;
    promo_title_color: string;
    promo_title_size: number;
    sticker_active: boolean;
    sticker_text_top: string;
    sticker_text_bottom: string;
    sticker_background_color: string;
    amount: string;
    equipment_image: string;
    equipment_text: SafeHtml;
    equipment_footer: SafeHtml;
    equipment_title: string;
    tour: string;
    photos: any;

    getStickerPrice(plans: any): string;
}

export class Intro implements IIntro {
    intro_slide_image_desktop: string;
    intro_slide_image_tablet: string;
    intro_slide_image_mobile: string;
    welcome_title: string;
    welcome_title_color: string;
    welcome_title_size: number;
    club_title: string;
    club_title_color: string;
    club_title_size: number;
    promo_title: string;
    promo_title_color: string;
    promo_title_size: number;
    sticker_active: boolean;
    sticker_text_top: string;
    sticker_text_bottom: string;
    sticker_background_color: string;
    amount: string;
    equipment_image: string;
    equipment_text: SafeHtml;
    equipment_footer: SafeHtml;
    equipment_title: string;
    photos: any;
    tour: string;

    constructor(data: CMSData, plans: Plans, private sanitizer: DomSanitizer) {
        this.intro_slide_image_desktop = data.acf.intro.intro_slide_image_desktop;
        this.intro_slide_image_tablet = data.acf.intro.intro_slide_image_tablet;
        this.intro_slide_image_mobile = data.acf.intro.intro_slide_image_mobile;
        this.welcome_title = data.acf.intro.intro_slide_content.welcome_title;
        this.welcome_title_color = data.acf.intro.intro_slide_content.welcome_title_color;
        this.welcome_title_size = data.acf.intro.intro_slide_content.welcome_title_size;
        this.club_title = data.acf.intro.intro_slide_content.club_title;
        this.club_title_color = data.acf.intro.intro_slide_content.club_title_color;
        this.club_title_size = data.acf.intro.intro_slide_content.club_title_size;
        this.promo_title = data.acf.intro.intro_slide_content.promo_title;
        this.promo_title_color = data.acf.intro.intro_slide_content.promo_title_color;
        this.promo_title_size = data.acf.intro.intro_slide_content.promo_title_size;
        this.sticker_active = data.acf.intro.intro_slide_content.price_sticker.sticker_active;
        this.sticker_text_top = data.acf.intro.intro_slide_content.price_sticker.sticker_text_top;
        this.sticker_text_bottom = data.acf.intro.intro_slide_content.price_sticker.sticker_text_bottom;
        this.sticker_background_color = data.acf.intro.intro_slide_content.price_sticker.sticker_background_color;
        this.amount = this.getStickerPrice(plans);
        this.equipment_image = data.acf.equipment.equipment_image;
        this.equipment_text = data.acf.equipment.text;
        this.equipment_footer = data.acf.equipment.equipment_footer;
        this.equipment_title = data.acf.equipment.title;
        this.photos = data.acf.photos;
        this.tour = data.acf['360_tour'];
    }

    getStickerPrice({ plans }: Plans) {
        if (plans && plans.length) {
            const planPrices = [];
            plans.forEach(plan => {
                if (plan.plan_price) {
                    planPrices.push(plan.plan_price);
                }
            });
            return Math.min(...planPrices) + '';
        }
    }
}
