import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { IRedirection, getRedirections } from './redirections';
import { environment } from '@environment';
import { getRedirectionPath, objectToQueryString } from '@core/helpers';
import { PrerenderService } from '@core/services/core/prerender.service';

/**
 * Redirect Guard
 */
@Injectable()
export class YextRedirectGuard  {
   /**
   * Constructor
   * @param {PrerenderService} prerenderService
   */
  constructor(private prerenderService: PrerenderService) {}
  /**
   * Redirects to yext Locations page with query
   * @param {ActivatedRouteSnapshot} route
   * @param {IRedirection} redirection
   */
  yextRedirect(route: ActivatedRouteSnapshot, redirection: IRedirection): void {
    const { queryParams } = route;
    const queryString = objectToQueryString(queryParams);
    const prefix = redirection.href.includes('?') ? '&' : '?';
    const params = queryString?.length ? `${prefix}${queryString}` : '';

    window.location.href = `${environment.domainLocations}${redirection.href}${params}`;
  }

  /**
   * Checks if the route can be accessed,
   * if not it redirects to the proper url
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   *  Wether the route can be activated or not
   */
  checkActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.prerenderService.remove301Header();
    const urls = getRedirections();
    const path = state.url.split('?')[0];
    const redirection = getRedirectionPath(path, urls);
    if (!redirection) {
      return true;
    }

    if (path.includes('/locations') || path.includes('/capacity')) {
      this.prerenderService.add301Header(`${environment.domainLocations}${redirection.href}`);
      this.yextRedirect(route, redirection);
      return false;
    }
    
    window.location.href = redirection.href;
    return false;
  }

  /**
   * CanActivate - The logic to activate or not a route
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   *  Wether the route can be activated or not
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkActivate(route, state);
  }

  /**
   * CanActivateChild - The logic to activate or not a child route
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   *  Wether the child route can be activated or not
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkActivate(route, state);
  }
}
