import { Injectable } from '@angular/core';
import { IFormConfigLeadGenModal, LeadGenModalCMS } from '@shared/modals/form-modal/form-modal.interface';
import { ScreenAndDeviceService } from './helpers/screen-and-device.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class ExitIntentPopupService {
    showLeadGenModal: boolean;
    formConfigLeadGenModal: IFormConfigLeadGenModal;
    contSetTOutLeadGenModal: NodeJS.Timeout;
    private currentPage: string;
    private pagesLeadGenModal: string[];
    private document: Document = document;
    private device: string;

    /**
     * @constructor
     * @param {PopupService} popupService
     * @param {ScreenAndDeviceService} screenAndDeviceService
     */
    constructor(
        private popupService: PopupService,
        private screenAndDeviceService: ScreenAndDeviceService
    ) { }

    /**
     * Init the Exit Intent pop-up
     * @param {IFormConfigLeadGenModal} configLeadGenModal 
     * @param {LeadGenModalCMS} acfCmsData 
     * @param {string} currentPage 
     */
    initConfiguration(acfCmsData: LeadGenModalCMS, currentPage: string, configLeadGenModal?: IFormConfigLeadGenModal): void {
        this.currentPage = currentPage;
        this.getDevice();
        this.setupLeadGenModal(acfCmsData, configLeadGenModal);
    }

    private getDevice(): void {
        this.device = this.screenAndDeviceService.getScreenType(1024, 767);
    }

    /**
     * Setup pop-up functionality
     * @param {IFormConfigLeadGenModal} configLeadGenModal 
     * @param {LeadGenModalCMS} cmsData 
     */
    private setupLeadGenModal(cmsData: LeadGenModalCMS, configLeadGenModal?: IFormConfigLeadGenModal): void {
        this.formConfigLeadGenModal = this.setFormConfigLeadGenModal(cmsData, configLeadGenModal);
        this.pagesLeadGenModal = cmsData?.show_on_page;  // Pages where to show the Lead Gen Modal
        this.showLeadGenModal = !!this.pagesLeadGenModal.find(pageModal => pageModal === this.currentPage);
        this.popupClearTimeOut();
        this.exitIntetDeviceFunctionality(cmsData);
    }

    /**
     * Lead Gen Modal form configuration
     * @param configLeadGenModal 
     * @param cmsData 
     * @returns Lead Gen Modal configuration or null
     */
    private setFormConfigLeadGenModal(cmsData: LeadGenModalCMS, configLeadGenModal?: IFormConfigLeadGenModal): IFormConfigLeadGenModal | null {
        if (!configLeadGenModal) { return null; }

        const {
            showFirstName,
            showLastName,
            resetForm,
            showEmail,
            showTerms,
            termsRequired,
            checkboxTermsId,
            submit_button_active,
            submit_button_disabled
        } = configLeadGenModal;
        const { location_fields_active, submit_button_text } = cmsData;

        return {
            showFirstName: showFirstName,
            showLastName: showLastName,
            resetForm: resetForm,
            showEmail: showEmail,
            showLocation: location_fields_active,
            showArea: location_fields_active,
            showTerms: showTerms,
            termsRequired: termsRequired,
            checkboxTermsId: checkboxTermsId,
            submit_button_active: {
                ...submit_button_active,
                text: submit_button_text
            },
            submit_button_disabled: {
                ...submit_button_disabled,
                text: submit_button_text
            }
        };
    }

    /**
     * Add the 'exit intet'/timer functionality according device
     * @param {LeadGenModalCMS} cmsData 
     */
    private exitIntetDeviceFunctionality(cmsData: LeadGenModalCMS): void {
        // Convert to milliseconds
        const delayShowLeadGenModal: number = (this.device !== 'desktop' ? cmsData?.timer_mobile : cmsData?.timer_desktop) * 1000;
        
        if (this.showLeadGenModal && 
            !localStorage.getItem('leadGenModalSent') && 
            !sessionStorage.getItem('sessionLedGenModalShow')
        ) {
            if (cmsData?.exit_intent && this.device === 'desktop') {
                // Lead Gen modal exit intent activated
                /**
                 * Adds and removes automatically the mouseleave event
                 * to the document to detect when the mouse leaves the viewport just once
                 */
                this.document.body.addEventListener('mouseleave', this.mouseLeaveExitIntent);
            } else {
                // Lead Gen modal timer activated
                this.contSetTOutLeadGenModal = setTimeout(() => {
                    this.showPopup();
                }, delayShowLeadGenModal);
            }
        }
    }

    private mouseLeaveExitIntent = () => {   
        if (this.showPopup()){
            this.document.body.removeEventListener('mouseleave', this.mouseLeaveExitIntent);
        }
    }

    private showPopup(): boolean {
        // if (this.popupService.isTrialFormOpened()) 
        if (this.popupService.isTrialFormOpened() || this.popupService.isPopupOpen()) { return false; }
        sessionStorage.setItem('sessionLedGenModalShow', 'true');
        this.popupService.toggleLeadGen();
        return true;
    }

    popupClearTimeOut(): void {
        if (this.contSetTOutLeadGenModal) { clearTimeout(this.contSetTOutLeadGenModal); }
    }
}
