import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'bw-simple-layout',
    templateUrl: './simple-layout.component.html',
})
export class SimpleLayoutComponent implements OnInit {
    public options: any;

    constructor() {
    }

    ngOnInit(): void {

    }
}
