import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Validators, UntypedFormControl, AsyncValidator } from '@angular/forms';
import { constants } from '@core/const/constants';
import PhoneValidator from '@shared/validators/phone.validator';

const PATTERNS = constants.PATTERNS;


export const FormRules = (customEmailValidator: AsyncValidator) => ({
  firstName: [null, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100)
  ]],
  lastName: [null, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100)
  ]],
  email: [null, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100),
      Validators.pattern(PATTERNS.EMAIL),
      Validators.pattern(PATTERNS.ANTIBOT),
  ],
      [customEmailValidator]
  ]
});

export function ConfirmEmailValidator(confirmEmailInput: string, destroyRef: DestroyRef) {
  let confirmEmailControl: UntypedFormControl;
  let emailControl: UntypedFormControl;

  return (control: UntypedFormControl) => {
    if (!control.parent) {
      return null;
    }

    if (!confirmEmailControl) {
      confirmEmailControl = control;
      emailControl = control.parent.get(confirmEmailInput) as UntypedFormControl;
      emailControl.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
        confirmEmailControl.updateValueAndValidity();
      });
    }

    if (emailControl.value !== confirmEmailControl.value) {
      return {
        notMatch: true
      };
    }
    return null;
  };
}

export const phoneRule = [
  Validators.minLength(10),
  Validators.maxLength(15),
  Validators.pattern(PATTERNS.PHONE),
  PhoneValidator,
];
export const termsRule = [Validators.requiredTrue];
