import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { GTMPage } from '@core/const/GTMPages';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare let $: any;

@Directive({ selector: '[tagManagerLoad]' })
export class TagManagerLoadDirective implements OnInit {
    private elRef: ElementRef;

    constructor(private gtmService: GoogleTagManagerService, elRef: ElementRef) {
        this.elRef = elRef;
    }

    ngOnInit(): any {
        TagManagerClickDirectiveHelper.pushTag(this.gtmService, this.elRef.nativeElement, GTMEvent.Pageview);
    }
}

@Directive({ selector: '[tagManagerClick]' })
export class TagManagerClickDirective {
    constructor(private gtmService: GoogleTagManagerService) {}

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        const target = event.currentTarget as HTMLElement;
        TagManagerClickDirectiveHelper.pushTag(this.gtmService, target, GTMEvent.Click);
    }
}

@Directive({ selector: '[tagManagerSubClick]' })
export class TagManagerSubClickDirective {
    constructor(private gtmService: GoogleTagManagerService) {}

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        let target = event.currentTarget as HTMLElement;
        const tagName = target.tagName;
        const srcElement = event.srcElement as HTMLElement;
        if (tagName !== 'A' && tagName !== 'BUTTON' && tagName !== 'P') return;
        if (target.tagName == 'P') {
            if (srcElement != null && (srcElement.tagName == 'A' || srcElement.tagName == 'BUTTON'))
                target = srcElement; 
            else
                return;
        }
        TagManagerClickDirectiveHelper.pushTag(this.gtmService, target, GTMEvent.Click);
    }
}

@Directive({ selector: "lib-blink-header" })
export class TagManagerHeaderClickDirective {
    constructor(private gtmService: GoogleTagManagerService) {}

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        let target = event.target as HTMLElement;
        if (!target.classList.contains("header-link"))
            target = target?.closest(".header-link");
        TagManagerClickDirectiveHelper.pushTag(this.gtmService, target, GTMEvent.Click);
    }
}

@Directive({ selector: "lib-blink-footer" })
export class TagManagerFooterClickDirective {
    constructor(private gtmService: GoogleTagManagerService) {}

    @HostListener('click', ['$event'])
    onClick(event: PointerEvent) {
        let target = event.target as HTMLElement;
        if (!target.classList.contains("footer-link"))
            target = target?.closest(".footer-link");
        TagManagerClickDirectiveHelper.pushTag(this.gtmService, target, GTMEvent.Click);
    }
}

export enum GTMEvent {
    Pageview = 'Pageview',
    Click = 'click'
}

export enum GTMData {
    LoginStatus = 'out',
    PageArea = 'pub',
    Experience = 'v2',
}

export class GTMDefaulEvent {
    event?: string;
    loginStatus?: string;
    pageArea?: string;
    experience?: string;
    pageItem?: string;
    pageSection?: string;
    pageType?: string;
}

export class GTMPaymentProperties{
    paymentType: string;
    promoCode?: string;
}

export class GTMBasicInfoProperties{
    state: string;
    city: string;
    gender: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    DOB: string;
}

export class GTMLeadGenFormProperties extends GTMDefaulEvent{
    offer: string;
    clickType: string;
}

export class GTMLeadGenFormConfirmProperties extends GTMLeadGenFormProperties{
    state: string;
    area: string;
    gym: string;
    firstName: string;
    lastName: string;
    email: string;
    memberId: string;
    clubId: string;
}
export class GTMPlanProperties extends GTMDefaulEvent{
    clubId: string;
    planColor: string;
    joinFee: number;
    discount: number;
    totalFeePerMonth: string;
    membershipType: string;
    planId: string;
    duesROM: number;
    startUpFeeDueToday: number;
    taxes: number;
    paymentType: string;
}

export interface GTMConfirmation extends GTMPlanProperties, GTMPaymentProperties, GTMBasicInfoProperties {
    memberId: string;
}

export class TagManagerClickDirectiveHelper {
    static getGTMPhoneNumber(phone: string): string{
        return "+1" + phone;
    }
    static getDefaultEventData(event: GTMEvent, pageData: GTMPage): GTMDefaulEvent {
        return {
            'event': event,
            'loginStatus': GTMData.LoginStatus,
            'pageArea': GTMData.PageArea,
            'experience': GTMData.Experience,
            'pageItem': pageData?.item,
            'pageSection': pageData?.section,
            'pageType': pageData?.type,
        }
    }
    
    static getBodyPageData(mainClass = "main-page"): GTMPage {
        return TagManagerClickDirectiveHelper.getMainPageData($('.' + mainClass)[0]);
    }   

    static getMainPageData(target: HTMLElement): GTMPage {
        const mainPage = target?.closest('.click-event-page') as HTMLElement;
        if (mainPage == null) return null;
        return { item: mainPage.dataset["pageitem"] ?? "",
            section: mainPage.dataset["pagesection"] ?? "",
            type: mainPage.dataset["pagetype"] ?? "" } as GTMPage;
    }

    static pushTag(gtmService: GoogleTagManagerService, target: HTMLElement, event: string): void {
        if (!target) return;

        const pageData = this.getMainPageData(target);

        if (pageData != null){   
            if(pageData.item != "" && pageData.section != "" && pageData.type != ""){
                if (event == GTMEvent.Pageview){
                    gtmService.pushTag(this.getDefaultEventData(event, pageData));
                }
                else if(event == GTMEvent.Click){
                    const clickType = target.dataset["clicktype"] ?? target.textContent.trim();
                    if(clickType != ""){
                        gtmService.pushTag({
                            ...this.getDefaultEventData(event, pageData),
                            'clickType': clickType
                          });
                    }
                }
            }
        }
    }
}