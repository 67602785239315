export interface IYextSettings {
  acf: {
    redirection: boolean;
  };
  date: string;
  id: number;
  link: string;
  slug: string;
  title: { rendered: string; }
  type: string;
}
