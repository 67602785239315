import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input, ViewEncapsulation, inject, DestroyRef } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { IAreasGlobal } from '@models/CMS/global-cms/areas-global';
import { TermsData } from '@shared/components/terms-content/terms-content.types';
import { ICmsPage, IFacility, TModalSizes } from '@models';
import { StreamService } from '@core/services/core/stream.service';
import { IOption } from '@shared/components/select/select.types';
import { IButtonGroup } from '@shared/components/input-button-group/input-button-group.types';
import { Required } from '@shared/decorators';
import { IFacilitiesState } from '@store/reducers/facilities-reducer.types';
import { AppState } from '@store/store.types';
import { facilities } from '@store/reducers/facilities-reducer';
import { BriteVerifyValidator } from '@shared/validators';
import { IFormData, IFields, ISubmitStyle } from './form.types';
import { goalsList, timeList } from './form.data';
import { checkboxCallMeRules, validations } from './form.validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WCAGUtils } from '@core/services/helpers/wcag.utils';

/**
 * Form Component
*/
@Component({
  selector: 'bw-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef)
  @ViewChild('termsModal') termsModal: ElementRef;
  @Output() onSubmit = new EventEmitter<IFormData>();
  @Input() @Required() id: string;
  @Input() @Required() fields: IFields;
  @Input() submitText = 'Submit';
  @Input() submitDisabledText: string;
  @Input() submitStyle: ISubmitStyle;
  @Input() submitDisabledStyle: ISubmitStyle;
  @Input() terms: TermsData[];
  @Input() callMeModal = '';
  @Input() callMeText = '';

  facilities$: Observable<IFacilitiesState>;
  facilities: IFacilitiesState;
  form: UntypedFormGroup;
  facilityAreas: IAreasGlobal[];
  termsList: TermsData[];
  termsSelected: number;
  submittedLocation: IFacility;
  termsOfUSe: ICmsPage;
  privacyPolicy: ICmsPage;
  goalsList: IOption[] = goalsList;
  timeList: IButtonGroup[] = timeList;
  get formValid(): boolean {
    return !this.termsOfUSe || !this.privacyPolicy || this.form.invalid || this.form.pending;
  }
  get emailErrorMessage(): string {
    const emailField = this.form.get('email');

    return (emailField?.errors?.Briteverify)
      ? 'Please enter a valid email address'
      : 'Please enter your email';
  }
  onCallSelected: boolean;
  modal: NgbModalRef;

  onKeyPress = WCAGUtils.onKeyPress;

  /**
   * @constructor
   * @param {NgbModal} modalService
   * @param {FormBuilder} fb
   * @param {StreamService} streamService
   * @param {Store<AppState>} store
   * @param {BriteVerifyValidator} briteVerifyValidator
   */
  constructor(
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private streamService: StreamService,
    private store: Store<AppState>,
    public briteVerifyValidator: BriteVerifyValidator,
  ) {
    this.facilities$ = this.store.select(facilities);
  }

  /**
   * Lifecycle event OnInit
   */
  ngOnInit() {
    this.createForm();
    this.getTerms();
    this.setupTerms();

    this.facilities$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onFacilitiesChange.bind(this))
  }

  /**
   * Create the form with the selected fields with validations
   */
  createForm(): void {
    if (!this.fields) {
      return;
    }

    const controlsConfig = Object.keys(this.fields)
      .reduce((acc, field) => ({
        ...acc,
        [field]: validations[field](
          this.fields[field].optional,
          field === 'email'
            ? this.briteVerifyValidator
            : undefined,
        ),
      }), {});

    this.form = this.fb.group(controlsConfig);

    if (this.fields.checkboxCallMe) {
      this.form.controls?.phone?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onPhoneChange.bind(this))
    }
  }

  /**
   * On Phone field change event
   * @param {string} value
   * @callback
   */
  onPhoneChange(value: string): void {
    const callMefield = this.form.controls?.checkboxCallMe;

    callMefield?.setValidators(
      value
        ? checkboxCallMeRules
        : []
    );

    callMefield?.updateValueAndValidity();
  }

  /**
   * Open the Terms, Privacy and Call Me terms
   *
   * @param {TModalSizes} sizes
   */
  openModal(size: TModalSizes = 'lg'): void {
    this.modal = this.modalService.open(
      this.termsModal, {
        size,
        centered: true,
        windowClass: `${this.id}-terms-modal terms-modal`,
      },
    );

    this.modal.result
      .then(
        this.onModalClose.bind(this),
        this.onModalClose.bind(this),
      );
  }

  /**
   * Facilities state change callback
   * @param {IFacilitiesState} facilities
   */
  onFacilitiesChange(facilities: IFacilitiesState): void {
    this.facilities = facilities;
  }

  /**
   * Callback on close the Terms, Privacy and Call me modals
   */
  onModalClose(): void {
    this.onCallSelected = false;
    this.termsSelected = undefined;
  }

  /**
   * Call me checkbox button click event
   *
   * @param {any} event
   */
  onCallMeClick(event: any): void {
    const href = event?.target?.href || '';

    if (href.includes('#modal')) {
      event.preventDefault();

      this.onCallSelected = true;
      this.openModal('sm');
    }
  }

  /**
   * Listen to streamService.termsOfUseCMS and streamService.privacyCMS observables
   */
  getTerms(): void {
    this.streamService.termsOfUseCMS.obs
    .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onTermsOfUseChange.bind(this))

    this.streamService.privacyCMS.obs
    .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onPrivacyChange.bind(this))
  }

  /**
   * Callback from streamService.termsOfUseCMS Observable
   *
   * @param {ICmsPage} terms;
   */
  onTermsOfUseChange(terms?: ICmsPage): void {
    if (!terms) {
      this.streamService.getTermsOfUseCMS();
      return;
    }

    this.termsOfUSe = terms;
  }

  /**
   * Callback from streamService.privacyCMS Observable
   *
   * @param {ICmsPage} privacy;
   */
  onPrivacyChange(privacy?: ICmsPage): void {
    if (!privacy) {
      this.streamService.getPrivacyCMS();
      return;
    }

    this.privacyPolicy = privacy;
  }

  /**
   * Setup terms checkbox with component depencies
   */
  setupTerms(): void {
    this.termsList = [{
      text: 'terms of use',
      service: this.streamService.termsOfUseCMS,
      retrieve: this.streamService.getTermsOfUseCMS,
    }, {
      text: 'privacy policy',
      service: this.streamService.privacyCMS,
      retrieve: this.streamService.getPrivacyCMS,
    }, ...(this.terms || [])];
  }

  /**
   * Locations component callback to option click
   *
   * @param {IOption} option
   */
  onLocationClick(option: IOption) {
    this.submittedLocation = this.facilities?.data[option.value];
  }

  /**
   * Submit button callback to event click
   *
   * @param {IFormData} formData
   * @callback
   *  Form submit
   */
  onSubmitFn(formData: IFormData): void {
    if (this.form.invalid) {
      return;
    }

    const data = { ...formData };

    if (this.fields?.phone) {
      data.phone = data.phone?.replace(/[^0-9]/g, '') || '';
    }

    if (this.fields?.checkboxTerms) {
      data.termsOfUse = this.termsOfUSe?.acf?.pdf_link;
      data.privacyPolicy = this.privacyPolicy?.acf?.pdf_link;
    }

    if (this.fields?.location) {
      data.location = this.submittedLocation;
    }

    this.onSubmit.emit(data);
  }

  /**
   * Checkbox component change event callback
   *
   * @param {boolean} checked
   */
  onTermsCheckboxChange(checked: boolean): void {
    if (checked) {
      this.onTermsClose();
    }
  }

  /**
   * Checkbox component terms link click event callback
   *
   * @param {{ index: number }} obj
   */
  onTermsClick(obj: { index: number }): void {
    this.termsSelected = (this.termsSelected !== obj.index)
      ? obj.index
      : undefined;

    this.openModal();
  }

  /**
   * Checkbox component terms content close event callback
   */
  onTermsClose(): void {
    this.termsSelected = undefined;
  }

  /**
   * Reset component
   */
  public reset() {
    this.form.reset();
    this.onTermsClose();
  }

  /**
   * Lifecycle event OnDestroy
   */
  ngOnDestroy(): void {
    this.reset();
  }
}
