import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Component, DestroyRef, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { ModalComponent } from '@shared/modals/modal/modal.component';
import { ToastService } from '@core/services/helpers/toast.service';
import { TitleService } from '@core/services/helpers/title.service';
import { Observable, Subscription } from 'rxjs';
import { StreamService } from '@core/services/core/stream.service';
import { LoadingScripts } from '@core/services/helpers/loading-scripts';
import { DomSanitizer } from '@angular/platform-browser';
import { CanonicalService } from '@core/services/helpers/canonical.service';
import { PopupService } from '@core/services/popup.service';
import { PrerenderService } from '@core/services/core/prerender.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GTMLeadGenFormProperties } from '@shared/directives/tag-manager.directive';
import { WcagService } from '@core/services/helpers/wcag.service';
import { ScriptInjectorService } from '@core/services/helpers/scripts-injector.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

declare let $: any;

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  private sub1: Subscription;
  private sub2: Subscription;
  private maintenanceSub: Subscription;
  termsOfUseCMS: any;
  privacyCMS: any;
  @ViewChild('modalRef') modalRef: ModalComponent;
  public template: string;
  public options: any;
  privacyPolicyCMS: string;
  termsCMS$: Observable<any>;
  privacyCMS$: Observable<any>;
  window: Window = window;

  WCAG_SET_LABEL_TIMEOUT_MILLI: number = 700;
  wcagLabelTimeout: NodeJS.Timeout;

  /**
   * constructor
   * @param router
   * @param toastService
   * @param titleService
   * @param _streamService
   * @param _scriptLoading
   * @param canonicalService:
   * @param sanitizer
   * @param _popupService
   * @param prerenderService:
   * @param gtmService
   * @param scriptsInjectorService
   */
  constructor(
    public router: Router,
    public toastService: ToastService,
    private titleService: TitleService,
    private _streamService: StreamService,
    private _scriptLoading: LoadingScripts,
    private canonicalService: CanonicalService,
    private sanitizer: DomSanitizer,
    private _popupService: PopupService,
    private prerenderService: PrerenderService,
    private gtmService: GoogleTagManagerService,
    private _wcagService: WcagService,
    private scriptsInjectorService: ScriptInjectorService
  ) {
    this._scriptLoading.loadScriptZendesk();
    this._scriptLoading.loadScriptYoutube();
    this.init();
    this.initPopup();
    this.initLeadGenModal();
    this.router.events.pipe(takeUntilDestroyed()).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.reloadOptimizely();
        this.getMaintenancePageData();
      }
    });
  }


  ngOnInit(): void {
    this.initializeApp();
  }

  async initializeApp(): Promise<void> {
    await this.scriptsInjectorService.injectScripts();
    this.template = '<img class="sp" src="./assets/img/loader.svg" alt="Loading..." />';
    this.getPrivacy();
    this.getTermsOfUse();
    this.initCanonicalURL();

    // Fixes the issue when opening a modal while there's another already opened
    // Then it blocks modal scroll and allow body to scroll below modal overlay
    $(document, '.modal')
      .on('show.bs.modal', this.onModalShow)
      .on('shown.bs.modal', this.onModalShown);
  }

  transformYourHtml(htmlTextWithStyle) {
    return htmlTextWithStyle ? this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle) : '';
  }

  initPopup() {
    return this.setModal('#toast', '_showToast');
  }

  initLeadGenModal() {
    return this.setModal('#leadGen', '_showLeadGen');
  }

  setModal(id: string, behaviorSubject: string) {
    const rawId = id.replace('#', '');
    return this.toastService[behaviorSubject].pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
      if (res.visible) {
        $(id)
          .off('hide.bs.modal')
          .off('show.bs.modal');

          $(id).on('show.bs.modal',
            () => {
              this._wcagService.setAriaOwnOfAnnouncer(rawId);
              const toastElementRef = new ElementRef(document.getElementById(rawId));
              if (this.wcagLabelTimeout) {
                clearTimeout(this.wcagLabelTimeout);
                this.wcagLabelTimeout = null;
              }
              this.wcagLabelTimeout = setTimeout(() => {
                this._wcagService.ariaHideInnerElementsOfHeadings(toastElementRef);
              }, this.WCAG_SET_LABEL_TIMEOUT_MILLI)
              res.options.onShow && res.options.onShow();
            });
          $(id).on('hide.bs.modal', () => {
            this._wcagService.removeAriaOwnOfAnnouncer(rawId);
            this._wcagService.removeStatusDiv(rawId);
            res.options.onHide && res.options.onHide();
          });
        $(id).modal('show');

      }
      if (res.options) {
        this.options = {
          modalTitle: res.options.modalTitle,
          modalHtmlTitle: res.options.modalHtmlTitle,
          modalMessage: res.options.modalMessage,
          modalMessageHtmlStyle: this.transformYourHtml(res.options.modalMessage),
          modalButtonTitle: res.options.modalButtonTitle,
          modalButtonAccessibilityText: res.options.modalButtonAccessibilityText,
          modalClass: res.options.modalClass,
          modalButtonClass: res.options.modalButtonClass,
          isHideButton: res.options.isHideButton,
          modalLinks: res.options.modalLinks,
          workingHours: res.options.workingHours,
          modalTitleColor: res.options.modalTitleColor,
          confirmationModalTitleColor: res.options.confirmationModalTitleColor,
          confirmationButtonBackground: res.options.confirmationButtonBackground,
          confirmationButtonColor: res.options.confirmationButtonColor,
          modalFooter: res.options.modalFooter,
          modalButtonRedirectUrl: res.options.modalButtonRedirectUrl,
          gtmEventOnConfirm: res.options.gtmEventOnConfirm,
          gtmEventOnClose: res.options.gtmEventOnClose,
          titleColor: res.options.workingHoursTitleColor,
          paragraphColor: res.options.workingHoursParagraphColor,
          accessibilityAnnouncement: res.options.accessibilityAnnouncement
        };
      } else {
        this.options = {
          modalTitle: '',
          modalMessage: '',
          modalMessageHtmlStyle: '',
          modalButtonTitle: 'OK',
          modalClass: 'payment-pop-up',
          modalButtonClass: 'btn btn-orange text-uppercase BlinkFitness_Balanced pull-right',
          workingHours: false
        };
      }
    });
  }

  init(): void {
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getMaintenancePageData();
      }
      if (event instanceof NavigationStart) {
        const navigation = this.router.getCurrentNavigation();
        if (!navigation?.extras?.state?.redirected) {
          this.prerenderService.remove301Header();
        }
      }
    })
    this.titleService.init();
  }

  onModalShow(): void {
    $('.modal.show').modal('hide');
  }

  onModalShown(): void {
    $(document.body).addClass('modal-open');
  }

  initCanonicalURL() {
    this._streamService.canonicalSettings.obs.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (!data) {
          this._streamService.getCanonicalSettings();
        } else {
          const canonicalSettings = data.acf.canonical_url_settings;
          this.canonicalService.init(canonicalSettings);
        }
      });
  }


  getMaintenancePageData() {
    if (this.maintenanceSub && !this.maintenanceSub.closed) {
      return;
    }
    this.maintenanceSub = this._streamService.maintenancePageData.obs.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (!data) {
          this._streamService.getMaintenancePageData();
        } else {
          this.checkMaintenanceStatus(data);
        }
      });
  }

  checkMaintenanceStatus(result) {

    if (this.router.url === '/maintenance') {
      return;
    }

    const active = result && result.acf && result.acf.maintenance_fields ? result.acf.maintenance_fields.active : false;
    if (active) {
      this.router.navigate(['/maintenance']);
    }

  }

  getTermsOfUse() {
    if (this.sub1 && !this.sub1.closed) {
      return;
    }
    this.sub1 = this._streamService.termsOfUseCMS.obs.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (!data) {
          this._streamService.getTermsOfUseCMS();
        } else {
          this.termsOfUseCMS = data;
        }
      });

    if (this.maintenanceSub) {
      this.maintenanceSub.unsubscribe();
    }
  }

  getPrivacy() {
    if (this.sub2 && !this.sub2.closed) {
      return;
    }
    this.sub2 = this._streamService.privacyCMS.obs.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (!data) {
          this._streamService.getPrivacyCMS();
        } else {
          this.privacyCMS = data;
        }
      });
  }

  onClick(formClass?: string): void {
    if(formClass?.includes('trial-config-popup'))
      this._popupService.toggleTrialFormConfirmation(false);
    $("#toast").modal('hide');
  }

  onClickLeadGenModal(redirectUrl: string, gtmEvent: GTMLeadGenFormProperties): void {
    if (gtmEvent != null) this.gtmService.pushTag(gtmEvent);
    if (redirectUrl) this.window.location.href = redirectUrl;
    $("#leadGen").modal('hide');
  }

  onCloseLeadGenModal(gtmEvent: GTMLeadGenFormProperties): void {
    if (gtmEvent != null) this.gtmService.pushTag(gtmEvent);
  }

  ngOnDestroy() {
    $(document, '.modal')
      .off('show.bs.modal', this.onModalShow)
      .off('shown.bs.modal', this.onModalShown);
  }

  reloadOptimizely() {
    //add class whyblink2-active on body otherwise remove
    if (this.router.url.split('?')[0] === '/whyblink2') {
      $('body').addClass('whyblink2-active');
      $('head').append('<meta name="robots" content="noindex,nofollow">');
    } else {
      $('body').removeClass('whyblink2-active');
      $('meta[content="noindex,nofollow"]').remove();
    }

    const optimizely = window['optimizely'];
    if (!optimizely) {
      console.warn('Optimizely not loaded yet');
      return;
    }

    optimizely.push({ type: 'activate' });

    //add class same as page name.
    const body = $('body');
    const pageName = this.router.url.split('?')[0].split('/')[1];
    $.fn.removeClassLike = function (name) {
      let classes = this.attr('class');
      if (classes) {
        classes = classes.replace(new RegExp('\\b' + name + '\\S*\\s?', 'g'), '').trim();
        classes ? this.attr('class', classes) : this.removeAttr('class');
      }
      return this;
    };
    body.removeClassLike('optipage-*');
    body.addClass('optipage-' + pageName);
  }
}
