import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IButtonGroup } from './input-button-group.types';
import { WcagService } from '@core/services/helpers/wcag.service';

/**
 * Input Button Group Component
 * A group of buttons that serves as radio group
 *
 * @example
 *  <bw-input-button-group
 *    [buttons]="[
 *      { label: 'am', icon: 'ico-blink-morn-sun', value: 'Morning' },
 *      { label: 'noon', icon: 'ico-blink-sun', value: 'Afternoon' },
 *    ]"
 *    [group]="formGroup"
 *    name="formControlName"
 *  ></bw-input-button-group>
*/
@Component({
  selector: 'bw-input-button-group',
  templateUrl: './input-button-group.component.html',
  styleUrls: ['./input-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputButtonGroupComponent {
  @Output() onOptionClick = new EventEmitter();
  @Output() onComponentBlur = new EventEmitter();
  @Input() group: UntypedFormGroup;
  @Input() componentId: string = 'button-choices';
  @Input() name: string;
  @Input() buttons: IButtonGroup[];
  @Input() title: string = 'Best time to Exercise*';
  @Input() errorRequiredMessage: string = 'Please select a best time to exercise';
  selected?: IButtonGroup;
  focusOutDivTimeout: NodeJS.Timeout;
  FOCUS_OUT_DIV_MILLI: number = 100;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private _wcagService: WcagService) {}

  /**
   * Selects the option in the component and in the form group
   *
   * @param {IButtonGroup} button
   */
  selectButton(button: IButtonGroup): void {
    this.selected = button;
    this._wcagService.announceMessageAssertive(`Selected ${button.ariaLabel || button.label}`);

    this.onOptionClick.emit(button);

    if (this.group && this.name) {
      this.group.patchValue({ [this.name]: button.value });
    }
  }

  /**
   * Function that is called on focusout of buttons. Sets error message
   *
   */
  onButtonBlur(): void {
    const self = this;

    if (self.focusOutDivTimeout) {
      clearTimeout(self.focusOutDivTimeout);
      self.focusOutDivTimeout = null;
    }
    self.focusOutDivTimeout = setTimeout(() => {
      const elementWithFocus = document.activeElement;
      if (!document.getElementById('button-choices')?.contains(elementWithFocus)) {
        self.group.get(self.name).markAsDirty();
        self.changeDetectorRef.detectChanges();
        self.onComponentBlur.emit();
      }
    }, self.FOCUS_OUT_DIV_MILLI);
  }
}
