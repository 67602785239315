import { createAction, props } from '@ngrx/store';
import {
  IFoldersRequest,
  FoldersActionTypes,
} from './folders.actions.types';
import { IFolder } from '@models';

/**
 * Load Folders from Yext
 *
 * @example
 *  // Without options
 *  this.store.dispatch(
 *    loadFolders({}),
 *  );
 *
 *  // With options
 *  this.store.dispatch(
 *    loadFolders({
 *      options: { limit: 10 },
 *    }),
 *  );
 */
export const loadFolders = createAction(
  FoldersActionTypes.request,
  props<IFoldersRequest>(),
);
export const loadFoldersSuccess = createAction(
  FoldersActionTypes.requestSuccess,
  props<{ folders: IFolder[] }>(),
);
export const loadFoldersFailure = createAction(
  FoldersActionTypes.requestFailure,
  props<{ error: any }>(),
);
