import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class DoNotShowOnRefreshGuard  {
    subscription: Subscription;

    constructor() { }

    canActivate(): Observable<boolean> | boolean | Promise<boolean> {
        return true;
    }

    canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }

}
