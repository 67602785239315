import {
  Component, ViewEncapsulation, Input,
} from '@angular/core';


@Component({
  selector: 'bw-footer-text',
  templateUrl: './footer-text.component.html',
  styleUrls: ['footer-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterTextComponent {
  @Input() text: string;
}
