import { AsyncValidator, Validators } from '@angular/forms';
import { constants } from '@core/const/constants';
import PhoneValidator from '@shared/validators/phone.validator';

const PATTERNS = constants.PATTERNS;

export const emailRule = (customEmailValidator: AsyncValidator) => ({
  email: [null, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100),
      Validators.pattern(PATTERNS.EMAIL),
      Validators.pattern(PATTERNS.ANTIBOT)
    ],
    [customEmailValidator]
  ]
});

export const firstNameRule = [
  Validators.required,
  Validators.minLength(1),
  Validators.maxLength(100),
  Validators.pattern(PATTERNS.MOSO_COGNITO_ALLOWED_NAME_PATTERN),
];

export const lastNameRule = [
  Validators.required,
  Validators.minLength(1),
  Validators.maxLength(100),
  Validators.pattern(PATTERNS.MOSO_COGNITO_ALLOWED_NAME_PATTERN),
];

export const locationRule = [Validators.required];

export const gymRule = [Validators.required];

export const areaRule = [Validators.required];

export const phoneRule = (required: boolean): any[] => {
  const validators = [
    Validators.minLength(10),
    Validators.maxLength(15),
    Validators.pattern(PATTERNS.PHONE),
    PhoneValidator,
  ];
  if (required) validators.push(Validators.required);
  return validators;
}

export const zipCodeRule = (required: boolean): any[] => {
  const validators = [ Validators.pattern(PATTERNS.ZIP) ];
  if (required) validators.push(Validators.required);
  return validators;
}

export const timeRule = [Validators.required];

export const goalsRule = [Validators.required];

export const termsRule = [Validators.requiredTrue];

export const callMe = [Validators.requiredTrue];
