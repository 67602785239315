import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UntypedFormGroup } from '@angular/forms';
import { IOption, IFolderOption } from './select.types';

/**
 * Select Component
 * General select component
 *
 * @example
 *  // One level example
 *  <bw-select
 *    [group]="formGroup"
 *    name="formControlName"
 *    [options]="[{ value: 'Example' }]"
 *  ></bw-select>
 *
 * *  // Two levels example
 *  <bw-select
 *    [group]="formGroup"
 *    name="formControlName"
 *    [options]="[{
 *      value: 'Example'
 *      children: [{
 *        value: 'Example',
 *      }],
 *     }]""
 *  ></bw-select>
*/
@Component({
  selector: 'bw-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent {
  @ViewChild('panel') panel: MatExpansionPanel;
  @Output() onOptionClick = new EventEmitter<IOption>();
  @Output() onFolderClick = new EventEmitter<IFolderOption>();
  @Input() group: UntypedFormGroup;
  @Input() name: string;
  @Input() value?: IOption;
  @Input() height = '45px';
  @Input() placeholder = 'Select';
  @Input() options: IOption[];
  selected?: IOption;
  isOpened: boolean;

  /**
   * Sets the status of the accordion
   *
   * @param {SimpleChanges} changes
   */
  toggle(status: boolean) {
    this.isOpened = status;
  }

  /**
   * Selects the option in the component and in the form group
   *
   * @param {IOption} option
   */
  select(option: IOption) {
    this.selected = option;
    this.isOpened = false;

    if (this.group) {
      this.group.patchValue({
        [this.name]: option.value,
      });
    }
  }

  /**
   * Option click event that selects the active option
   *
   * @param {IOption} option
   */
  onOptionClickFn(option: IOption) {
    this.select(option);
    this.onOptionClick.emit(option);
    this.panel?.close();
  }

  /**
   * Folder click event that opens more options
   *
   * @param {IOption} option
   */
  onFolderClickFn(option: IFolderOption) {
    this.onFolderClick.emit(option);
  }
}
