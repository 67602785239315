export class CmsPage implements ICmsPage {
    id: number;
    date: Date;
    date_gmt: Date;
    guid: Guid;
    modified: Date;
    modified_gmt: Date;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: Title;
    content: Content;
    excerpt: Excerpt;
    author: number;
    featured_media: number;
    parent: number;
    menu_order: number;
    comment_status: string;
    ping_status: string;
    template: string;
    meta: any[];
    acf: CustomWPpageContent;
    taxonomy?: string;
    name?: string;
    _links: Links;

    constructor() {
    }
}

export interface ICmsPage {
    id: number;
    date: Date;
    date_gmt: Date;
    guid: Guid;
    modified: Date;
    modified_gmt: Date;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: Title;
    content: Content;
    excerpt: Excerpt;
    author: number;
    featured_media: number;
    parent: number;
    menu_order: number;
    comment_status: string;
    ping_status: string;
    template: string;
    meta: any[];
    acf: CustomWPpageContent;
    taxonomy?: string;
    name?: string;
    _links: Links;
}

export interface CustomWPpageContent {
    title?: string;
    type?: string;
    ownership?: string;
    moso_id?: string;
    question_answer?: any[];
    contacts?: { phone?: string, email?: string };
    terms_id: number;
    pdf_link?: string;
    address?: {
        street?: string;
        street2?: string;
        city?: string;
        state?: string;
        zip?: string;
        country?: string;
    };
    address_description?: string;
    geo_location?: { lat?: string, lng?: string };
    promo_banner?: { promo_banner_desktop?: string, promo_banner_tablet?: string, promo_banner_mobile?: string, show_banner?: boolean, sweepstakes?: { active: boolean, modal_title: string, submit_title: string }, banner_url?: string };
    intro?: {
        intro_slide_image?: string;
        intro_slide_content?: {
            welcome_title?: string;
            club_title?: string;
            promo_title?: string;
            price_sticker?: string;
        };
    };
    photos?: LocationPhoto[];
    equipment?: {
        title: string;
        text: string;
    };
    floor_plan?: {
        floor_plan_image?: string;
        floor_plan_info: [{ area_title?: string; area_color?: string; }]
    };
    '360_tour'?: string;
    video_banner?: { thumbnail: string | boolean; video_url: string };
    dron_tour?: { video_banner?: { thumbnail: string | boolean; video_url: string } };
    facility_plans?: Array<any>;
    working_hours?: Array<any>;
    holiday_hours?: boolean | any;
    marketing_block_1?: { type: string, link: string, link_type: string, display_image: any, enabled: true };
    marketing_block_2?: { type: string, link: string, link_type: string, display_image: any, enabled: true };

    street?: string;
    street_2?: string;
    city?: string;
    state?: string;
    zip?: string;
    welcome_text?: string;
    background_image?: any;
    price_tag?: string;
    location_photos?: any;
    map_url?: any;
    phone?: string;
    location_hours?: any;
    top_image_1?: TopBannerImageHome;
    left_image_2?: LeftImageHome;
    right_image_3?: RightImageHome;
    left_image_4?: boolean;
    right_image_5?: boolean;
    headline_block?: HeadlineBlock;
    terms_block?: TermsBlock;
    pt_monthly_block?: PtMonthlyBlock;
    sku_block?: SkuBlock;
    sus_block?: SusBlock;
    email?: string;
    top_header_navigationitems?: TopHeaderNavigationitem[];
    header_menue_items?: HeaderMenueItem[];
    header_mobile_menue_items?: HeaderMobileMenueItem[];
    mobile_sidebar_menue?: MobileSidebarMenue[];
    membership_plans_information?: MembershipPlansInformation[];
    footer_navigation?: FooterNavigation[];
    appointment_time_list?: AppointmentTimeList[];
    fitness_goal_list?: FitnessGoalList[];
    gym_locations_list?: GymLocationsList[];
    states_list?: StatesList[];
    months_list?: MonthsList[];
    official_membership_rules?: OfficialMembershipRule[];
}

export interface LocationPhoto {
    ID?: string;
    id?: string;
    title?: string;
    filename?: string;
    filesize?: string;
    url?: string;
    link?: string;
    alt?: string;
    author?: string;
    description?: string;
    caption?: string;
    name?: string;
    status?: string;
    uploaded_to?: string;
    date?: Date;
    modified?: Date;
    menu_order?: number;
    mime_type?: string;
    type?: string;
    subtype?: string;
    icon?: string;
    width?: number;
    height?: number;
    sizes?: {
        thumbnail?: string;
        'thumbnail-width'?: number;
        'thumbnail-height'?: number;
        medium?: string;
        'medium-width'?: number;
        'medium-height'?: number;
        medium_large: string;
        'medium_large-width'?: number;
        'medium_large-height'?: number;
        large?: string;
        'large-width'?: number;
        'large-height'?: number;
        'twentyseventeen-featured-image'?: string;
        'twentyseventeen-featured-image-width'?: number;
        'twentyseventeen-featured-image-height'?: number;
        'twentyseventeen-thumbnail-avatar'?: string;
        'twentyseventeen-thumbnail-avatar-width'?: number;
        'twentyseventeen-thumbnail-avatar-height'?: number;
    };
}

export interface Guid {
    rendered: string;
}

export interface Title {
    rendered: string;
}

export interface Content {
    rendered: string;
    protected: boolean;
}

export interface Excerpt {
    rendered: string;
    protected: boolean;
}

export interface HeadlineBlock {
    active: boolean;
    header_copy: string;
    body_copy: string;
}

export interface TermsBlock {
    show_headline_block_on_a_page: boolean;
    header_copy: string;
    body_copy: TermsBlockBody[];
}

export interface TermsBlockBody {
    title: string;
}

export interface PtPlansOffered {
    title: string;
    id: string;
    price: string;
    planType?: string;
}

export interface PtMonthlyBlock {
    show_monthly_block_on_a_page: boolean;
    header_copy: string;
    body_copy: string;
    pt_plans_offered: PtPlansOffered[];
}

export interface SinglePlansOffered {
    title: string;
    id: string;
    price: string;
}

export interface SkuBlock {
    show_sku_block_on_a_page: boolean;
    header_copy: string;
    body_copy: string;
    single_plans_offered: SinglePlansOffered[];
}

export interface SusBlock {
    show_sus_block_on_a_page: boolean;
    header_copy: string;
    body_copy: string;
}

export interface TopHeaderNavigationitem {
    title: string;
    link: string;
    icon: string;
}

export interface HeaderMenueItem {
    title: string;
    link: string;
    classname: string;
}

export interface HeaderMobileMenueItem {
    title: string;
    link: string;
}

export interface FooterNavigation {
    title: string;
    link: string;
}

export interface MobileSidebarMenue {
    title: string;
    link: string;
}

export interface MembershipPlansInformation {
    content_1: string;
    content_2: string;
    list_1: List1[];
    list_2: List1[];
}

export interface List1 {
    name: string;
}

export interface AppointmentTimeList {
    name: string;
    value: string;
}

export interface FitnessGoalList {
    name: string;
    value: string;
}

export interface GymLocationsList {
    name: string;
    id: string;
    gymemail: string;
}

export interface StatesList {
    name: string;
    abbreviation: string;
}

export interface MonthsList {
    name: string;
    short: string;
    number: string;
    days: string;
}

export interface OfficialMembershipRule {
    item: string;
}


export interface TopBannerImageHome {
    top_banner_image: string;
    top_banner_image_small_responsive: string;
    top_title_text_copy: string;
    top_banner_button: { button_text_copy: string; button_link_url: string; omniture_tag: string };
}

export interface LeftImageHome {
    left_image: string;
    button_text_copy: string;
    button_link_url: string;
}

export interface RightImageHome {
    right_image: string;
    button_text_copy: string;
    button_link_url: string;
}

export interface Self {
    href: string;
}

export interface Collection {
    href: string;
}

export interface About {
    href: string;
}

export interface Author {
    embeddable: boolean;
    href: string;
}

export interface Reply {
    embeddable: boolean;
    href: string;
}

export interface VersionHistory {
    href: string;
}

export interface WpAttachment {
    href: string;
}

export interface Cury {
    name: string;
    href: string;
    templated: boolean;
}

export interface Links {
    self: Self[];
    collection: Collection[];
    about: About[];
    author: Author[];
    replies: Reply[];
    versionHistory: VersionHistory[];
    wpAttachment: WpAttachment[];
    curies: Cury[];
}

export interface CmsLocation {
    street: string;
    street_2: string;
    city: string;
    state: string;
    zip: string;
    welcome_text: string;
    background_image: any;
}
