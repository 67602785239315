import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { IOption, IFolderOption } from '@shared/components/select/select.types';

/**
 * Select Options Item Component
 * It's the single item of them List from SelectOptionsComponent
 *
*/
@Component({
  selector: 'bw-select-options-item',
  templateUrl: './select-options-item.component.html',
  styleUrls: ['./select-options-item.component.scss']
})
export class SelectOptionsItemComponent implements OnInit {
  @ViewChild('panelItem') panel: MatExpansionPanel;
  @Output() onOptionClick = new EventEmitter<IOption>();
  @Output() onFolderClick = new EventEmitter<IFolderOption>();
  @Input() option: IOption;
  @Input() selected: IOption;
  @Input() expanded: boolean;
  @Input() level = 0;
  isOpened = false;

  /**
   * Lifecycle event OnInit
   */
  ngOnInit(): void {
    if (this.option?.selected) {
      this.onOptionClickFn(this.option);
    }
  }

  /**
   * Option click event that selects the active option
   *
   * @param {IOption} option
   */
  onOptionClickFn(option: IOption) {
    this.onOptionClick.emit(option);
    this.panel?.close();
  }

  /**
   * Folder click event that opens more options
   *
   * @param {IFolderOption} option
   */
  onFolderClickFn(option: IFolderOption) {
    this.onFolderClick.emit(option);
  }

  /**
   * Sets the status of the accordion
   *
   * @param {boolean} status
   */
  toggle(status: boolean) {
    this.isOpened = status;
  }
}
