import { IRequestYextOptions } from './common.types';
import { TGeoSearch } from '@store/reducers/facilities-by-geo.reducer.types';

export enum FacilitiesGeoSearchActionTypes {
  request = '[Facilities] Load Facilities By Geo Search',
  requestSuccess = '[Facilities] Load Facilities By Geo Search Success',
  requestFailure = '[Facilities] Load Facilities By Geo Search Failure',
}


export interface IFacilitiesByGeoSearchRequest extends IRequestYextOptions {
  location: TGeoSearch;
}
