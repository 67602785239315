/**
 * Set an Input variable as required
 *
 * @description
 *  In order to have the variable as required,
 *  it will throw an error if it's undefined
 * @param target
 * @param {string} propertyKey
 */
export function Required() {
  return (
    target?: any,
    propertyKey?: string,
  ) => {
    Object.defineProperty(target, propertyKey, {
      get() {
        throw new Error(`Attribute "${propertyKey}" is required`);
      },
      set(value) {
        Object.defineProperty(target, propertyKey, {
          value,
          writable: true,
          configurable: true,
        });
      },
      configurable: true
    });
  };
}
