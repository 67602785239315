/** PromoBanner Class **/
export class PromoBanner {
    desktop: string;
    tablet: string;
    mobile: string;
    background_color: string;
    promo_banner_url: string;
    show_banner: boolean;

    constructor(data: any) {
        if (data.acf.promo_banner) {
            this.desktop = data.acf.promo_banner.desktop;
            this.tablet = data.acf.promo_banner.tablet;
            this.mobile = data.acf.promo_banner.mobile;
            this.background_color = data.acf.promo_banner.background_color;
            this.show_banner = data.acf.promo_banner.show_banner;
            this.promo_banner_url = data.acf.promo_banner.promo_banner_url;
        }
    }
}

export enum BannerTypes {
  LOCATIONS = 'locations',
  CLUB = 'club',
  HOME = 'home',
  PT_STARTUP = 'pt-startup',
  SWEEPSTAKES = 'sweepstakes',
}
