import { Injectable } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { LoggerService } from '@core/services/log4ts/logger.service';

const APP_TITLE = 'Blink Fitness';
const SEPARATOR = ' | ';
const PAGES_WITH_NO_PREFIX = "Careers";

@Injectable()
export class TitleService {

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private logger: LoggerService) {

    }

    public static ucFirst(string) {
        if (!string) {
            return string;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    init(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            switchMap(route => route.data),
            map((data) => {
                if (data.title) {
                    return data.title;
                } else {
                    return this.router.url.split('/').reduce((acc, frag) => {
                        if (acc && frag) {
                            acc += SEPARATOR;
                        }
                        return this.router.url.split('/').reduce((acc, frag) => {
                            if (acc && frag) {
                                acc += SEPARATOR;
                            }
                            return acc + TitleService.ucFirst(frag);
                        });
                    });
                }
            })
        ).subscribe((pathString) => {
            if (PAGES_WITH_NO_PREFIX.includes(pathString))
                this.titleService.setTitle(pathString);
            else
                this.titleService.setTitle(`${APP_TITLE} ${pathString}`);
        });
    }

    setPageTitle(title: string): void {
        this.titleService.setTitle(`${APP_TITLE} ${title}`);
    }

}
