import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bw-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.fluid]': 'fluid'
  }
})
export class ContainerComponent {
  @Input() fluid: boolean;
}
