import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, inject, DestroyRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { IAreasGlobal } from '@models/CMS/global-cms/areas-global';
import { ILocationsCms } from '@models/CMS/location-cms';
import { IOption, IFolderOption } from '@shared/components/select/select.types';
import { constants } from '@core/const/constants';
import { IFacilitiesStates, IUSAState } from '@models';
import { Store } from '@ngrx/store';
import { AppState } from '@store/store.types';
import { IFacilitiesByRegionState } from '@store/reducers/facilities-by-region.reducer.types';
import { facilitiesByRegion } from '@store/reducers/facilities-by-region.reducer';
import { loadFacilitiesByRegion } from '@store/actions/facilities-by-region.actions';
import { loadFolders } from '@store/actions/folders.actions';
import { IFoldersState } from '@store/reducers/folders.reducer.types';
import { folders } from '@store/reducers/folders.reducer';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Select Component
 * Locations select divided by area
 *
 * @example
 *  <bw-select-locations
 *    [group]="formGroup"
 *    name="formControlName"
 *    (onOptionClick)="eventForOptionClick($event)"
 *  ></bw-select-locations>
*/
@Component({
  selector: 'bw-select-locations',
  templateUrl: './select-locations.component.html',
  styleUrls: ['./select-locations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectLocationsComponent implements OnInit {
  private destroyRef = inject(DestroyRef)
  @Output() onOptionClick = new EventEmitter<IOption>();
  @Output() onFolderClick = new EventEmitter<IFolderOption>();
  @Input() group: UntypedFormGroup;
  @Input() name: string;
  facilitiesByRegion$: Observable<IFacilitiesByRegionState>;
  facilitiesByRegion: IFacilitiesByRegionState;
  folders$: Observable<IFoldersState>;
  folders: IFoldersState;
  locations: ILocationsCms[];
  facilityAreas: IAreasGlobal[];
  options: IOption[];
  selectedFolder: IOption;

  /**
   * @constructor
   * @param {Router} router
   */
  constructor(
    private store: Store<AppState>,
  ) {
    this.facilitiesByRegion$ = this.store.select(facilitiesByRegion);
    this.folders$ = this.store.select(folders);
  }

  /**
   * Lifecycle event ngOnInit
   */
  ngOnInit(): void {
      // Get Facilities by Region
      this.facilitiesByRegion$
      .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onStatesChange.bind(this))
      this.folders$
      .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onFoldersChange.bind(this))
  }

  /**
   * Callback from folders state
   *
   * @param {IFoldersState} folders
   */
  onFoldersChange(folders: IFoldersState): void {
    this.folders = folders;
    if (!folders?.loading && !folders?.data) {
      this.store.dispatch(
        loadFolders({})
      );
    }

    if (folders?.data) {
      this.setOptions();
    }
  }
  /**
   * Callback from facilitiesByRegion state
   *
   * @param {ILocationsCms[]} facilities
   */
  onStatesChange(facilities: IFacilitiesByRegionState): void {
    this.facilitiesByRegion = facilities;

    if (!facilities?.loading && facilities?.data && this.selectedFolder) {
      const region = this.selectedFolder.region.abbreviation;

      this.selectedFolder.children = this.getChildren(facilities.data, region);

      delete this.selectedFolder.loading;

      // Force update options
      this.options = [ ...this.options ];
      this.selectedFolder = undefined;
    }
  }

  /**
   * Creates the options list to be consumed by SelectComponent
   *
   * @example
   *  [{
   *    value: 'Area Name',
   *    children: [
   *      { value: 'Club Name' },
   *    ],
   *  }]
   *
   * @param {ILocationsCms[]} locations
   * @returns IOptionsKeys
   */
  setOptions(): void {
    const getRegion = (name: string) =>
      constants.statesList.find((region: IUSAState) => (
        region.abbreviation === name
      ));

    // Filter Folders by State
    this.options = this.folders?.data
      .filter(folder => !!getRegion(folder.name))
      .map(folder => {
        const region = getRegion(folder.name);

        return {
          value: region?.name,
          region,
          children: this.getChildren(
            this.facilitiesByRegion?.data,
            region.abbreviation,
          ),
          expanded: this.selectedFolder?.region === region,
        };
      });

    this.selectedFolder = undefined;
  }

  /**
   * List of facilities per region
   *
   * @param {IFacilitiesStates} data
   * @param {string} region
   */
  getChildren(data: IFacilitiesStates, region: string) {
    const list = data && data[region] || [];

    return list
      .map(facility => ({
        label: facility.name,
        value: facility.id,
      }));
  }

  /**
   * Option click event from SelectComponent
   *
   * @param {Router} event
   */
  onOptionClickFn(item: IOption): void {
    this.onOptionClick.emit(item);
  }

  /**
   * Folder click event from SelectComponent
   *
   * @param {Router} event
   */
  onFolderClickFn(item: IFolderOption): void {
    this.onFolderClick.emit(item);

    if (item?.option?.children?.length) {
      return;
    }

    const region = item?.option?.region || '';

    this.store.dispatch(
      loadFacilitiesByRegion({
        state: region,
      }),
    );

    this.selectedFolder = item.option;
    this.selectedFolder.loading = true;
  }
}
