import { Injectable, Inject, inject, DestroyRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { unescape } from 'lodash-es';

interface ICanonicalURL {
    non_indexable_url: string;
    canonical_url: string;
}

@Injectable()
export class CanonicalService {
    private destroyRef = inject(DestroyRef);
    linkElement: HTMLLinkElement;

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private doc
    ) { }

    init(settings: ICanonicalURL[]): void {
        this.setCanonicalURL(settings);
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.setCanonicalURL(settings);
        });
    }

    setCanonicalURL(settings: ICanonicalURL[]): void {
        this.linkElement = this.doc.querySelector('[rel="canonical"]');

        const currentURL = window.location.href;
        const [currentUrlWithoutQueryString, queryString] = currentURL.split('?');

        const currentURLSettings = settings.find((url) => {
            return unescape(url.non_indexable_url) === currentURL;
        });

        if (currentURLSettings) {
            this._setCanonicalURL(currentURLSettings.canonical_url);
        } else {
            if (queryString) {
                this._setCanonicalURL(currentUrlWithoutQueryString);
            } else if (this.linkElement) {
                this.linkElement.parentNode.removeChild(this.linkElement);
            }
        }
    }

    private _setCanonicalURL(canonical_url: string): void {
        if (!this.linkElement) {
            this.linkElement = this.doc.createElement('link');
            this.linkElement.setAttribute('rel', 'canonical');
            this.doc.head.appendChild(this.linkElement);
        }
        this.linkElement.setAttribute('href', canonical_url);
    }
}
