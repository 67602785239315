import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IEmergencyBannerCMS } from './emergency-banner.types'

@Component({
  selector: 'bw-emergency-banner',
  templateUrl: './emergency-banner.component.html',
  styleUrls: ['./emergency-banner.component.scss'],
  animations: [
    trigger('closeBanner', [
      state('opened', style({
        height: '*'
      })),
      state('closed', style({
        overflow: 'hidden',
        height: '0px',
        padding: '0px',
      })),
      transition('opened=>closed', animate('200ms'))
    ]),

  ]
})
export class EmergencyBannerComponent {

  // Exibition
  exibitionState = 'opened';
  show = true

  // Data
  @Input() content: IEmergencyBannerCMS

  constructor() { }

  closeBanner() {
    this.exibitionState = 'closed'
  }

}

