import { Injectable } from '@angular/core';
import { Script, scripts, ValidTagName } from './injections';
import { LoggerService } from '@core/services/log4ts/logger.service';

@Injectable({
    providedIn: 'root'
})
export class ScriptInjectorService {

    constructor(private logger: LoggerService) { }

    async injectScripts() {
        scripts.forEach(async script => await this.loadScript(script));
        this.logger.info('-- ScriptInjectorService --');
    }

    private async loadScript(script: Script): Promise<void> {
        try {
            const scriptElement = this.createScriptElement(script);
            script.replacements.forEach( replacement => {
                script.content = script.content.replace(replacement.value, replacement.replaceWith);
            });
    
            if (script.type === 'child') {
                scriptElement.insertAdjacentHTML('beforeend', script.content);
            } else {
                scriptElement.src = script.content;
            }
    
            (document as Record<ValidTagName, any>)[script.parentElement as ValidTagName].appendChild(scriptElement);
        }
        catch(error){
            this.logger.error(script.name + ' script load failed.', error);
        }
    }

    private createScriptElement(script: Script): HTMLScriptElement {
        const scriptElement = document.createElement<HTMLScriptElement | any>(script.tagName);
        scriptElement.type = 'text/javascript';
        scriptElement.async = script.async;
        return scriptElement;
    }
}
