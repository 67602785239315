import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoggerService } from './log4ts/logger.service';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(
    private httpClient: HttpClient, 
    private logger: LoggerService
  ) { }

  getFormerMember(pathEndpoint: string, email: string): Observable<any> {
    const url = `${environment.API_CONF.WEB_MOSO_API}/user/${pathEndpoint}`;
    return this.httpClient.post<any>(url, { email });
  }
}
