import { Links, Title } from './cms-page';
import { Contacts } from '@models/contacts';

export class FacilityShort implements IFacilityShort {
    id: number;
    date: Date;
    slug: string;
    type: string;
    link: string;
    title: Title;
    acf: Acf;
    _links: Links;

    constructor() {
    }
}

export interface IFacilityShort {
    id: number;
    date: Date;
    slug: string;
    type: string;
    link: string;
    title: Title;
    acf: Acf;
    _links: Links;
}


export interface Address {
    street: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
}

export interface GeoLocation {
    lat: string;
    lng: string;
}


export interface Acf {
    address: Address;
    location_description: string;
    geo_location: GeoLocation;
    title: string;
    type: string;
    ownership: string;
    moso_id: string;
    contacts: Contacts;
    agreement_start_day: string;
    order: string;
}
