export class WCAGUtils {
    /**
     * We need to add keyPress events to every HTML elements which have click events and aren't buttons
     * So the users can act on that element with keyboard only
     * @param event KeyboardEvent
     * @param callback any
     * @param params any[]
     */
    public static onKeyPress (event: KeyboardEvent, callback: any, params?: any[]) {
        if (event.code === 'Space' || event.code === 'Enter') {
            callback(...params);
        }
    }
}