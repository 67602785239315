import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { SelectOptionsComponent } from './select-options/select-options.component';
import { SelectOptionsItemComponent } from './select-options-item/select-options-item.component';
import { MaterialModule } from '@material/material.module';

@NgModule({
  declarations: [
    SelectComponent,
    SelectOptionsComponent,
    SelectOptionsItemComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    SelectComponent,
    SelectOptionsComponent,
    SelectOptionsItemComponent,
  ],
})
export class SelectModule { }
