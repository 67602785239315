import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormRules } from './find-locations.validations';
import { environment } from '@environment';
import { ILocationBlockComponentCMSACF } from '@public-pages/pages/home/home.types';
import { constants } from '@core/const/constants';

/**
 * Find Locations Component.
 * Input with autocomplete to Gyms
 */
@Component({
  selector: 'bw-find-locations',
  templateUrl: './find-locations.component.html',
  styleUrls: ['./find-locations.component.scss'],
})

export class FindLocationsComponent implements OnInit, AfterViewInit {
  @Input() layout_content: ILocationBlockComponentCMSACF;
  @Input() placeholder = 'Address or Zip Code';
  @Input() formClass: string;
  @Input() wrapperClass: string;
  @Input() inputClass: string;
  @Input() inputStyle: { [style: string]: string };
  @Input() buttonClass: string;
  @Input() buttonStyle: { [style: string]: string };
  @Input() buttonContent = '';
  @Input() arrowInput: boolean = false;
  @Input() searchImage: boolean = false;
  @Input() accessibilityText: string;
  regionList = constants.statesList;
  window: Window = window; // This is necessary for unity test
  form: UntypedFormGroup;
  @ViewChild('inputRef') inputElement: ElementRef;

  public get input_color() {
    return (this.layout_content && this.layout_content.search_input.input_color !== "") ? this.layout_content.search_input.input_color : '#B3B3B3';
  }

  /**
   * @constructor
   * @param fb
   */
  constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) { }

  /**
   * Lifecycle OnInit
   */
  ngOnInit() {
    this.form = this.fb.group(FormRules);
  }

  ngAfterViewInit() {
    this.inputElement.nativeElement.style.setProperty('--placeholder-color', this.input_color);
    if (this.searchImage)
      this.buttonContent = '<img alt="Search" src="./assets/img/white-search.svg">';
    this.cd.detectChanges();
  }

  /**
   * Function to submit search form data
   */
  onEnterPress(): void {
    this.onSubmitFn();
  }

  /**
   * Form submit event
   */
  onSubmitFn(): void {
    const address = this.form?.controls?.address?.value || '';
    const existingLocation = this.regionList.find(l => l.abbreviation.toUpperCase() == address.toUpperCase()
      || l.name.toUpperCase() == address.toUpperCase());
    if (existingLocation != null) {
      this.window.location.href = existingLocation.href;
    }
    else {
      const param = address ? `?q=${encodeURI(address)}` : '';
      this.window.location.href = `${environment.domainLocations}/search${param}`;
    }
  }
}
