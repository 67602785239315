import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  AgreementData,
  AgreementDataV2,
  CreateOpportunityFull,
  CreateOpportunityPayload,
  CreateTrialRequestData,
  CreateTrialResponseData,
  ICreateStartupRequestData,
  IProcessLocationLeadForm,
  UserService,
} from '@core/services/user.service';
import { IPromoCodeRequest, IValidPrepaidParams, IValidPrepaidResponse, IYextSettings } from '@models';
import { LoggerService } from '@core/services/log4ts/logger.service';
import { CmsService } from '@core/services/cms.service';
import { AdyenPaymentService } from '@core/services/adyen-service/adyen-payment.service';
import { AwsService } from './aws.service';
import { PlanService } from '@core/services/plan/plan.service';
import { FacilitiesService } from '@core/services/facilities.service';
import { BriteverifyService } from '@core/services/briteverify.service';
import { CardService } from '@core/services/card.service';
import 'rxjs/add/operator/toPromise';
import { IIdenityCredentialsPermissions } from './aws.service.types';
import { IEmailStatus } from '@public-pages/pages/checkout/checkout/checkout-form-v2/basic-info-section-v2/basic-info-section-v2.types';
import { IPaymentConfiguration } from '@public-pages/pages/checkout/checkout/checkout-form-v2/checkout-form-v2.types';

@Injectable()
export class FacadeService {
  private _userService: UserService;
  public get userService(): UserService {
    if (!this._userService) {
      this._userService = this.injector.get(UserService);
    }
    return this._userService;
  }

  private _cmsService: CmsService;
  public get cmsService(): CmsService {
    if (!this._cmsService) {
      this._cmsService = this.injector.get(CmsService);
    }
    return this._cmsService;
  }

  private _facilitiesService: FacilitiesService;
  public get facilitiesService(): FacilitiesService {
    if (!this._facilitiesService) {
      this._facilitiesService = this.injector.get(FacilitiesService);
    }
    return this._facilitiesService;
  }

  private _awsService: AwsService;
  public get awsService(): AwsService {
    if (!this._awsService) {
      this._awsService = this.injector.get(AwsService);
    }
    return this._awsService;
  }

  private _planService: PlanService;
  public get planService(): PlanService {
    if (!this._planService) {
      this._planService = this.injector.get(PlanService);
    }
    return this._planService;
  }

  private _cardService: CardService;
  public get cardService(): CardService {
    if (!this._cardService) {
      this._cardService = this.injector.get(CardService);
    }
    return this._cardService;
  }

  private _briteverifyService: BriteverifyService;
  public get briteverifyService(): BriteverifyService {
    if (!this._briteverifyService) {
      this._briteverifyService = this.injector.get(BriteverifyService);
    }
    return this._briteverifyService;
  }

  private _adyenPaymentService: AdyenPaymentService;
  public get adyenPaymentService(): AdyenPaymentService {
    if (!this._adyenPaymentService) {
      this._adyenPaymentService = this.injector.get(AdyenPaymentService);
    }
    return this._adyenPaymentService;
  }

  constructor(
    private injector: Injector,
    private logger: LoggerService,
    private http: HttpClient,
  ) {
  }

  //Adyen Payment Service INIT
  getConfigAdyen(facilityId: string): Promise<IPaymentConfiguration> {
    return this.adyenPaymentService.getConfig(facilityId);
  }

  ////////////////////////// FacilitiesService /////////////////////////////////////////////////
  getFacilityInfoBySlugShort(slug: string): Observable<any> {
    return this.facilitiesService.getFacilityInfoBySlugShort(slug);
  }

  getClubDataBySlugFull(slug: string): Observable<any> {
    return this.facilitiesService.getClubDataBySlugFull(slug);
  }

  getAllFacilitiesGlobal(): Observable<any> {
    return this.facilitiesService.getAllFacilitiesGlobal();
  }

  getAllFacilityAreasGlobal(): Observable<any> {
    return this.facilitiesService.getAllFacilityAreasGlobal();
  }

    getLocationData(slug: string): Observable<any> {
        return this.facilitiesService.getClubDataBySlugFull(slug);
    }

  getBrowserLocation(): Promise<any> {
    return this.facilitiesService.getBrowserLocation();
  }

  ////////////////////////// PlanService /////////////////////////////////////////////////

  getMembershipPlansByMosoId(moso_id: string) {
    return this.planService.getMembershipPlansByFacilityId(moso_id).pipe(
      map((result: any) => {
        if (
          result.result &&
          result.result.Item &&
          result.result.Item.agreementPrices
        ) {
          return result.result.Item.agreementPrices;
        } else {
          return {};
        }
      }),
    );
  }

  /////////// NEW CMS ///////
  getPostBySlugName(slug: string): Observable<any> {
    return this.cmsService.getPostBySlugName(slug);
  }

  getPageBySlugName(slug: string): Observable<any> {
    return this.cmsService.getPageBySlugName(slug).pipe(map((nav) => nav[0]));
  }

  getPageBySlugNameGlobal(slug: string): Observable<any> {
    return this.cmsService
      .getPageBySlugNameGlobal(slug)
      .pipe(map((nav) => nav[0]));
  }

  getDataSetBySlugNameGlobal(slug: string): Observable<any> {
    return this.cmsService
      .getDataSetBySlugNameGlobal(slug)
      .pipe(map((nav) => nav[0]));
  }

  getDataSetBySlugName(slug: string): Observable<IYextSettings> {
    return this.cmsService.getDataSetBySlugName(slug).pipe(map(nav => nav[0]));
  }

  getDatasetByBenefitId(benefit_id: number): Observable<any> {
    return this.cmsService.getDatasetByBenefitId(benefit_id);
  }

  getGlobalCMSDataByTypeAndId(data: {
    type: string;
    id: number;
  }): Observable<any> {
    return this.cmsService.getGlobalCMSDataByTypeAndId(data);
  }

  ///////// NEW CMS ends //////

  /////////////////////// UserService methods start //////////////////////////////////////////////
  /**-- UserService methods start --**/
  createAndFinalizeAgreement(data: AgreementData): Promise<any> {
    return this.userService.createAndFinalizeAgreement(data);
  }

  createAndFinalizeAgreement_v2(data: AgreementDataV2): Promise<any> {
    return this.userService.createAndFinalizeAgreement_v2(data);
  }

  getUserDataMOSO(memberId: string): Promise<any> {
    return this.userService.getUserDataMOSO(memberId);
  }

  getWhoischeckedin(facilityId: string): Promise<any> {
    return this.userService.getWhoischeckedin(facilityId);
  }

  processSweepStakes(sweepstakes_data, facility): Promise<any> {
    return this.userService.processSweepStakes(sweepstakes_data, facility);
  }

  processLocationLeadForm(data: IProcessLocationLeadForm): Promise<any> {
    return this.userService.processLocationLeadForm(data);
  }

  createWebLead(data: CreateOpportunityPayload): Observable<any> {
    return this.userService.createWebLead(data);
  }

  createWebCart(data: CreateOpportunityFull): Promise<any> {
    return this.userService.createWebCart(data);
  }

  createTrial(
    data: CreateTrialRequestData,
  ): Observable<CreateTrialResponseData> {
    return this.userService.createTrial(data);
  }

  createStartupMember(data: ICreateStartupRequestData): Observable<any> {
    return this.userService.createStartupMember(data);
  }

  validatePromotion(data: IPromoCodeRequest): Observable<any> {
    return this.userService.validatePromotion(data);
  }

  applyPromoCode(data: IPromoCodeRequest) {
    return this.userService.applyPromoCode(data).pipe(
      map((response: any) => {
        if (response && response.response) {
          return response.response;
        } else {
          return {};
        }
      })
    );
  }

  createGympassProspect(data): Observable<any> {
    return this.userService.createGympassProspect(data);
  }

  getDecryptedParams(data): Observable<any> {
    return this.userService.getDecryptedParams(data);
  }

  getEmailMemberStatus(data: IEmailStatus): Promise<object> {
    return this.userService.getEmailMemberStatus(data);
  }

  /**-- UserService methods ends --**/

  /////////////////////// AwsService methods start //////////////////////////////////////////////
  /**-- AwsService methods start --**/
  setUnauthIdentityCredentials(): Promise<boolean> {
    return this.awsService.setUnauthIdentityCredentials();
  }

    getIdenityCredentials(): Promise<IIdenityCredentialsPermissions> {
        return this.awsService.getIdenityCredentials();
    }

  sendEmailWithSnS(message: any, snsTopic: string): Promise<any> {
    return this.awsService.sendEmailWithSnS(message, snsTopic);
  }

  checkCaptcha(captchaResponse: string): Observable<any> {
    return this.userService.checkCaptcha(captchaResponse);
  }

  /////////////////////// Cards API //////////////////////////////////////////////
  validatePrepaidCard(params: IValidPrepaidParams): Observable<IValidPrepaidResponse> {
    return this.cardService.validatePrepaidCard(params);
  }
}
