import { UntypedFormControl } from '@angular/forms';

function PhoneValidator(control: UntypedFormControl) {
    const phone = control.value || '';

    if (phone.replace(/[^0-9]/g, '').length > 10) {
        return {
            PhoneValidator: { value: phone },
        };
    }

    return null;
}

export default PhoneValidator;
