import { Pipe, PipeTransform } from '@angular/core';
import { IImageBlockContent } from '@shared/components/two-blocks-layout/two-blocks-layout.types';
@Pipe({
  name: 'defineImageAlt'
})
export class DefineImageAlt implements PipeTransform {
  transform(imageBlock: IImageBlockContent, deviceType: string) {
    switch (deviceType) {
      case 'mobile':
        return imageBlock.mobile_alt_text;
      case 'tablet':
        return imageBlock.tablet_alt_text;
      case 'desktop':
        return imageBlock.desktop_alt_text;
      default:
        break;
    }
  }
}