import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { COLOR_ACCESSIBILITY_ENABLED_COOKIE_NAME } from '@core/const/constants';
import { StreamService } from '@core/services/core/stream.service';
import { CookiesService } from '@core/services/helpers/cookies.service';
import { IAccessibilityConfig, IAccessibilityConfigCMS } from '@models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'blb-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss']
})


export class AccessibilityComponent implements OnDestroy, OnChanges {
  private destroyRef = inject(DestroyRef);
  @ViewChild('accessibilityModal') accessibilityModal: ElementRef;
  @Input() cmsData: IAccessibilityConfig;
  @Input() hasAllComponentsLoaded: boolean;

  @Output() isAccessbilityEnabled = new EventEmitter<boolean>();

  modal: NgbModalRef;

  isColorContrastOn;

  toggleDelayTimeout;
  loadingInterval;
  unchangedComponentCounter: number = 0;
  bodyChildrenCount: number = 0;

  readonly TOGGLE_DELAY_MILLI = 800;
  readonly CHECK_LOADING_INTERVAL_MILLI = 800;
  readonly MAX_UNCHANGED_COUNTER = 5;

  constructor(private _cookieService: CookiesService,
              private modalService: NgbModal,
              private _streamService: StreamService) {
    this.isColorContrastOn = Number(this._cookieService.getCookieByName(COLOR_ACCESSIBILITY_ENABLED_COOKIE_NAME))
      || false;
    
    this.getAccessibilityConfigs();

    this.startCheckingForLoadingDone();

  }

  startCheckingForLoadingDone() {
    if (this.loadingInterval) {
      clearInterval(this.loadingInterval);
    }
    this.loadingInterval = setInterval(() => {
      this.checkForCompleteLoading();
    }, this.CHECK_LOADING_INTERVAL_MILLI);
  }


  checkForCompleteLoading() {
    const bodyElem = document.body;
    if (this.bodyChildrenCount !== bodyElem?.childNodes.length) {
      this.bodyChildrenCount = bodyElem?.childNodes.length;
      if (this.isColorContrastOn && this.cmsData) {
        this.toggleAccessiblityColor({ checked: true });
      }
    } else {
      this.unchangedComponentCounter++;
    }

    if (this.unchangedComponentCounter >= this.MAX_UNCHANGED_COUNTER) {
      clearInterval(this.loadingInterval);
      this.toggleDelay();
    }
  }

  getAccessibilityConfigs() {
    this._streamService.accessibilityConfigCMSData.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onAccessiblityConfigCMSSuccess.bind(this))
  }

  onAccessiblityConfigCMSSuccess(data: IAccessibilityConfigCMS) {
    if (!data) {
      this._streamService.getAccessibilityConfigData();
      return;
    }
    this.cmsData = data.acf;
    this.isAccessbilityEnabled.emit(data.acf.enabled);
    this.toggleDelay();
  }

  openModal() {
    this.modal = this.modalService.open(
      this.accessibilityModal,
      { size: 'md', windowClass: 'accessiblity-modal' },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.hasAllComponentsLoaded?.currentValue === true) {
      if (this.isColorContrastOn && this.cmsData) {
        this.toggleDelay();
      }
    }
  }

  // this is to ensure all components have loaded before we toggle the contrast fix
  toggleDelay() {
    if (this.isColorContrastOn) {
      if (this.toggleDelayTimeout) {
        clearTimeout(this.toggleDelayTimeout);
      }
      this.toggleDelayTimeout = setTimeout(() => {
        this.toggleAccessiblityColor({ checked: true });
      }, this.TOGGLE_DELAY_MILLI)
    }
  }

  toggleAccessiblityColor(event) {
    this.isColorContrastOn = event.checked;
    this._streamService.colorContrastToggle.next(event.checked);
    this._cookieService.setCookie(COLOR_ACCESSIBILITY_ENABLED_COOKIE_NAME, this.isColorContrastOn ? '1' : '0', 1);
    const colorReplacement = this.cmsData.color_replacement;
    let oldColorKey = 'normal';
    let newColorKey = 'accessible';
    if (!this.isColorContrastOn) {
      oldColorKey = 'accessible';
      newColorKey = 'normal';
    }
    const elementsToCheckArr = Array.from(document.querySelectorAll(this.cmsData.included_html_tags) as NodeListOf<HTMLElement>);

    elementsToCheckArr.forEach((element: HTMLElement) => {
      const bgColor = String(document.defaultView.getComputedStyle(element, null).backgroundColor);
      const textColor = String(document.defaultView.getComputedStyle(element, null).color);
      // const borderColor = String(document.defaultView.getComputedStyle(element, null).borderColor);
      const brandColorBgMatch = colorReplacement.find((replacement) => bgColor === replacement[oldColorKey]);
      const brandColorMatch = colorReplacement.find((replacement) => textColor === replacement[oldColorKey]);
      // const brandBorderColorMatch = colorReplacement.find((replacement) => borderColor === replacement[oldColorKey]);
      if (brandColorBgMatch) {
        element.style.backgroundColor = brandColorBgMatch[newColorKey]
      }
      if (brandColorMatch) {
        element.style.color = brandColorMatch[newColorKey]
      }
      // if (brandBorderColorMatch) {
      //   element.style.borderColor = brandColorMatch[newColorKey]
      // }
    });
    const variableReplacement = this.cmsData.variable_replacement;
    variableReplacement.forEach((variable) => {
      const newColor = variable[newColorKey];
      document.documentElement.style.setProperty(variable.variable_name, newColor);
    })
  }

  ngOnDestroy(): void {
    if (this.toggleDelayTimeout) {
      clearTimeout(this.toggleDelayTimeout);
    }
    if (this.loadingInterval) {
      clearInterval(this.loadingInterval);
    }
  }
}
