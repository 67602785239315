import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
/**Custom Created Components **/
import { P404Component } from '@public-pages/pages/other/404/404.component';
import { SimpleLayoutComponent } from '@core/layouts/layout/simple-layout.component';
import { LayoutFirstComponent } from '@core/layouts/layout/layout-first.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { YextRedirectGuard } from '@core/guards/yext-redirect.guard';
import { RedirectGuard } from '@core/guards/redirect.guard';

const routes: Routes = [
  {
    path: 'checkout',
    component: SimpleLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./public-pages/pages/checkout/checkout.module').then(m => m.CheckoutModule),
      data: { title: 'Checkout' },
    }],
  }, {
    path: 'signup',
    canActivate: [AuthGuard],
    component: SimpleLayoutComponent,
    data: { title: 'Gympass Form' },
    loadChildren: () => import('./public-pages/pages/gympass/gympass.module').then(m => m.GympassModule),
  }, {
    path: 'alumnioffer/signup',
    component: SimpleLayoutComponent,
    data: { title: 'Alumni Blue', planName: 'alumni', pathEndpoint: 'validAlumni', urlCheckout: 'alumni-blue' },
    loadChildren: () => import('./public-pages/pages/landing/landing.module').then(m => m.LandingModule),
  }, {
    path: 'maintenance',
    children: [{
      path: '',
      loadChildren: () => import('./public-pages/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    }],
  }, {
    path: 'blinkapp',
    canActivate: [RedirectGuard],
    pathMatch: 'full',
    children: []
  }, {
    path: 'try-blink',
    canActivate: [RedirectGuard],
    pathMatch: 'full',
    children: []
  }, {
    path: 'join',
    canActivate: [RedirectGuard],
    children: []
  }, {
    path: 'join/:location',
    canActivate: [RedirectGuard],
    children: []
  }, {
    path: 'sweepstakes',
    canActivate: [RedirectGuard],
    children: []
  }, {
    path: 'ny/:city',
    redirectTo: 'capacity'
  }, {
    path: 'location',
    canActivate: [RedirectGuard],
    pathMatch: 'full',
    children: []
  }, {
    path: 'termsmobile',
    canActivate: [RedirectGuard],
    pathMatch: 'full',
    children: []
  }, {
    path: 'privacymobile',
    canActivate: [RedirectGuard],
    pathMatch: 'full',
    children: []
  }, {
    path: 'faq',
    component: class FaqComponent { },
    canActivate: [YextRedirectGuard],
  }, {
    path: '',
    component: LayoutFirstComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./public-pages/pages/home/home.module').then(m => m.HomeModule),
        pathMatch: 'full'
      },
      {
        path: 'locations',
        canActivate: [YextRedirectGuard],
        children: []
      },
      {
        path: 'locations/area/:areaName',
        canActivate: [YextRedirectGuard],
        children: []
      },
      {
        path: 'locations/:locationName',
        canActivate: [YextRedirectGuard],
        children: []
      },
      {
        path: 'locations/area/:state/:city',
        canActivate: [YextRedirectGuard],
        canActivateChild: [YextRedirectGuard],
        children: []
      },
      {
        path: 'locations/area/:state/:city/:street',
        canActivate: [YextRedirectGuard],
        canActivateChild: [YextRedirectGuard],
        children: []
      }, {
        path: 'health-and-fitness/:slugName',
        canActivate: [RedirectGuard],
        children: [],
      }, {
        path: 'virtual-fitness',
        canActivate: [RedirectGuard],
        children: []
      }, {
        path: 'capacity',
        canActivate: [YextRedirectGuard],
        canActivateChild: [YextRedirectGuard],
        children: []
      }, {
        path: 'capacity/:slug',
        canActivate: [YextRedirectGuard],
        canActivateChild: [YextRedirectGuard],
        children: []
      }, {
        path: 'personaltraining',
        children: [
          {
            path: '',
            data: { title: 'personal training' },
            loadChildren: () => import('./public-pages/pages/personal-training/personal-training.module').then(m => m.PersonalTrainingModule),
          },
          {
            path: 'startup',
            data: { title: 'Personal Training Startup' },
            loadChildren: () => import('./public-pages/pages/personal-training/startup/startup.module').then(m => m.StartupModule),
          },
        ],
      }, {
        path: 'terms',
        data: { title: 'Terms of use' },
        loadChildren: () => import('./public-pages/pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule),
      }, {
        path: 'privacy',
        data: { title: 'privacy policy' },
        loadChildren: () => import('./public-pages/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
      },
      {
        path: 'accessibility-statement',
        data: { title: 'accessibility statement' },
        loadChildren: () => import('./public-pages/pages/accessibility-statement/accessibility-statement.module').then(m => m.AccessibilityStatementModule),
      },
      {
        path: 'confirmation',
        data: { title: 'Confirmation' },
        loadChildren: () => import('./public-pages/pages/confirmation/confirmation.module').then(m => m.ConfirmationModule),
      }, {
        path: 'friends',
        data: { title: 'Friends' },
        loadChildren: () => import('./public-pages/pages/friends/friends.module').then(m => m.FriendsModule)
      }, {
        path: 'confirmation',
        data: { title: 'Confirmation' },
        loadChildren: () => import('./public-pages/pages/confirmation/confirmation.module').then(m => m.ConfirmationModule)
      }, {
        path: 'careers',
        data: { title: 'Careers' },
        loadChildren: () => import('./public-pages/pages/careers/careers.module').then(m => m.CareersModule)
      }, {
        path: '404',
        component: P404Component,
        data: { title: 'Surprise! We couldn\'t find that page.' },
      }, {
        path: '**',
        redirectTo: '404',
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
