import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { constants } from '@core/const/constants';
import { environment } from '@environment';

@Component({
    selector: 'bw-left-hidden-side-bar',
    templateUrl: './left-hidden-side-bar.component.html',
    styleUrls: ['./left-hidden-side-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LeftHiddenSideBarComponent implements OnInit {
    @Input() footerOnly = false;
    @Input() menusOnly = false;

    public menusHidden: any;
    public footerMenus: any;
    public footerSocialMenus: { logoSrc?: string, iconClasses?: string, href: string, target?: string, accessibilityText: string }[];

    date: Date = new Date();
    mainDomain = environment.domain;

    ngOnInit() {
        this.menusHidden = constants.menusHidden;
        this.footerMenus = constants.footerMenus;
        this.footerSocialMenus = constants.footerSocialMenus;
    }

}
