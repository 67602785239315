import { SafeHtml } from '@angular/platform-browser';

interface ICMSData {
    acf: IAcf;
    date: string;
    date_gmt: string;
    'facility-areas': any[];
    guid: any;
    id: number;
    link: string;
    meta: any[];
    modified: string;
    modified_gmt: string;
    slug: string;
    status: string;
    template: string;
    title: any;
    type: string;
    _links: any;
}

interface IEquipment {
    equipment_footer: SafeHtml;
    equipment_image: string;
    text: string;
    title: string;
}

interface IAcf {
    '360_tour': string;
    address: any;
    address_description: string;
    cta: {
        call_to_action_block: string;
        call_to_action_block_2: string;
        call_to_action_block_active: boolean;
        call_to_action_block_image: boolean;
        call_to_action_type: boolean;
    };
    contacts: any;
    dron_tour: any;
    facility_plans: FacilityPlansItem[];
    floor_plan: any;
    geo_location: any;
    holiday_hours: boolean;
    intro: any;
    label_of_link_mobile: string;
    label_of_link_tablet: string;
    label_of_location_indicator: string;
    emergency_label_active?: boolean;
    emergency_label_title_color?: string;
    marketing_block_1: any;
    marketing_block_2: any;
    marketing_block: ICMSData[];
    moso_id: string;
    order: string;
    ownership: string;
    photos: any[];
    presale_block_active: boolean;
    presale_block_desktop_image: boolean;
    presale_block_mobile_image: boolean;
    promo_banner: any;
    fit2k_banner: any;
    promo_banner_active: boolean;
    fit2k_banner_active: boolean;
    title: string;
    type: string;
    video_banner: any;
    working_hours: any;
    equipment: IEquipment;
    emergency_settings: any;
}

export interface FacilityPlansItem {
    benefits: any;
    foot_note: any;
    is_active: boolean;
    show_details: boolean;
    plan_title: string;
    best_value: boolean;
    background_color_for_the_title_block: string;
    background_color_for_the_price_block: string;
    best_value_main_color: string;
    best_value_secondary_color: string;
    startupFeeDiscount: any;
    plan_price: any;
    tax_transaction: any;
    sale_transaction: any;
    promo_price: any;
    agreement_price: any;
    item_price: any;
    background_color_for_the_see_benefits_block: string;
    join_now: string;
}

export class CMSData implements ICMSData {
    acf: IAcf;
    date: string;
    date_gmt: string;
    'facility-areas': any[];
    guid: any;
    id: number;
    link: string;
    meta: any[];
    modified: string;
    modified_gmt: string;
    slug: string;
    status: string;
    template: string;
    title: any;
    type: string;
    _links: any;

    constructor(data: any) {
        this.acf = data.acf;
        this.date = data.date;
        this.date_gmt = data.date_gmt;
        this['facility-areas'] = data['facility-areas'];
        this.guid = data.guid;
        this.id = data.id;
        this.link = data.link;
        this.meta = data.meta;
        this.modified = data.modified;
        this.modified_gmt = data.modified_gmt;
        this.slug = data.slug;
        this.status = data.status;
        this.template = data.template;
        this.title = data.title;
        this.type = data.type;
        this._links = data._links;
    }
}



