import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoggerService } from '@core/services/log4ts/logger.service';

@Injectable()
export class ToastService {
    public _showToast: BehaviorSubject<any> = new BehaviorSubject({ visible: false, options: null });
    public showToast: Observable<boolean> = this._showToast.asObservable();

    public _showLeadGen: BehaviorSubject<any> = new BehaviorSubject({ visible: false, options: null });
    public showLeadGen: Observable<boolean> = this._showLeadGen.asObservable();

    constructor(private router: Router, public logger: LoggerService) {

    }

    setToastVisible(val: any) {
        this._showToast.next(val);
    }

    setLeadGenVisible(val: any) {
        this._showLeadGen.next(val);
    }
}


























