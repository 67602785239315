import { Component, DestroyRef, inject, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IBasicInfoFormGroup } from '@public-pages/pages/checkout/checkout/checkout-form-v2/basic-info-section-v2/basic-info-section-v2.types';
import { FillFieldsQaBtnService } from '@core/services/fill-fields-qa-btn.service';
import { LoggerService } from '@core/services/log4ts/logger.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IPaymentFormGroup } from '@public-pages/pages/checkout/checkout/checkout-form-v2/payment-section-v2/payment-section-v2.types';
import { finalize } from 'rxjs/operators';
import { IGetGuerEmail } from './fill-fields-qa-btn.types';
import { FakerJsUtils } from '@core/services/helpers/fakerJs.utils';

@Component({
  selector: 'bw-fill-fields-qa-btn',
  templateUrl: './fill-fields-qa-btn.component.html',
  styleUrls: ['./fill-fields-qa-btn.component.scss']
})
export class FillFieldsQaBtnComponent implements OnDestroy{
  @Input() form: FormGroup<IBasicInfoFormGroup | IPaymentFormGroup>;
  @Input() isPersonalInformationSection: boolean;
  
  private _destroyRef = inject(DestroyRef);
  private _fillFieldsQaBtnService = inject(FillFieldsQaBtnService);
  private _loggerService = inject(LoggerService);
  
  private _containerBtnTimeout: NodeJS.Timeout;
  private _FORM_VALUES = {
    streetLine1: '20 W 34th St',
    streetLine2: '2nd Fl',
    city: 'New York City',
    state: 'NY',
    phone: '(888) 888-8888',
    zipCode: '10118',
    dateOfBirthFormatted: '01/01/2000',
    genderId: 3,
    termsAndPrivacy: true,
    card_month: 12,
    card_year: 2026,
    account_type: '1',
    card_number: '4111111111111111',
    security_code: '111',
    routing_number: '021000021',
    account_number: 123456,
    checkbox_terms: true
  }
  isDisabled = false;

  /**
   * Click on the QA button
   */
  onClickBtn(): void {
    this.buttonState(true);
    if (this.isPersonalInformationSection) {
      this.personalInformationFields();
      return;
    }
    this.paymentDetailsFields();
  }

  /**
   * Update the Personal Information fields
   */
  personalInformationFields(): void {
    this.form.controls['email'].setValue('');
    this.form.controls['zipCode'].setValue('1011');

    this.getTestEmail();

    this.form.patchValue({
      firstName: FakerJsUtils.getRandomFirstName(),
      lastName: FakerJsUtils.getRandomLastName(),
      streetLine1: this._FORM_VALUES.streetLine1,
      streetLine2: this._FORM_VALUES.streetLine2,
      city: this._FORM_VALUES.city,
      state: this._FORM_VALUES.state,
      phone: this._FORM_VALUES.phone,
      dateOfBirthFormatted: this._FORM_VALUES.dateOfBirthFormatted,
      genderId: this._FORM_VALUES.genderId,
      termsAndPrivacy: this._FORM_VALUES.termsAndPrivacy
    });
  }

  /**
   * Get an email from Guerillamail API
   */
  getTestEmail(): void {
    this._fillFieldsQaBtnService.getTestEmail().pipe(takeUntilDestroyed(this._destroyRef))
    .pipe(finalize( () => {
      this.form.controls['zipCode'].patchValue(this._FORM_VALUES.zipCode);
      this._containerBtnTimeout = setTimeout(() => {
        this.buttonState(false);
        this._containerBtnTimeout = null;
      }, 1000);
    }))
    .subscribe(
      (value: IGetGuerEmail) => this.form.controls['email'].setValue(value.email_addr),
      (err: Error) => this._loggerService.error(err)
    );
  }

  /**
   * Update the Payment Details fields
   */
  paymentDetailsFields(): void {
    this.form.controls['card_month'].setValue(this._FORM_VALUES.card_month);
    this.form.controls['card_year'].setValue(this._FORM_VALUES.card_year);
    this.form.controls['account_type'].setValue(this._FORM_VALUES.account_type);
    this.form.patchValue({
      card_number: this._FORM_VALUES.card_number,
      name_on_card: FakerJsUtils.getRandomFullName(),
      security_code: this._FORM_VALUES.security_code,
      account_name: FakerJsUtils.getRandomFullName(),
      routing_number: this._FORM_VALUES.routing_number,
      account_number: this._FORM_VALUES.account_number,
      checkbox_terms: this._FORM_VALUES.checkbox_terms
    });
    this.buttonState(false);
  }

  /**
   * Set the state (enabled/disabled) of the QA button
   * @param {boolean} state 
   */
  buttonState(state: boolean): void {
    this.isDisabled = state
  }

  ngOnDestroy(): void {
    clearTimeout(this._containerBtnTimeout);
  }
}
