import {Inject, Injectable} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {environment} from '@environment';
import {LoggerService} from '@core/services/log4ts/logger.service';

@Injectable()
export class LoadingScripts {

    constructor(private logger: LoggerService, @Inject(DOCUMENT) private document: any) {
        this.logger.info('-- LoadingScripts --');
    }

    public loadScript(): void {
        const dynamicScript = environment.omnitureSnippetURL;
        const node = this.document.createElement('script');
        node.src = dynamicScript;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        this.document.getElementsByTagName('head')[0].appendChild(node);
    }

    public loadScriptZendesk(): Promise<{ (component: string, command: string): void; }> {
        const zESettings = {
            webWidget: {
                launcher: {
                    label: {
                        '*': 'Help',
                    },
                },
                helpCenter: {
                    title: {
                        '*': 'Help'
                    },
                },
                contactForm: {
                    title: {
                        '*': 'Help'
                    },
                },
            }
        };
        
        window['zESettings'] = zESettings;
        
        const dynamicScript = environment.zenDeskWidgetURL;
        const node = document.createElement('script');
        node.src = dynamicScript;
        node.id = 'ze-snippet';
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        
        const isScriptLoaded = new Promise<{ (component: string, command: string): void }>((resolve, reject) => {
          node.addEventListener('load', () => resolve(window['zE']));
          node.addEventListener('error', reject);
        });
        // prevents duplicate IDs
        if (document.getElementById(node.id)) node.id = 'ze-snippet-two'
        document.getElementsByTagName('head')[0].appendChild(node);

        isScriptLoaded.then((zendeskWidget?: (component: string, command: string) => void) => {
            if (zendeskWidget) zendeskWidget('webWidget', 'hide');
        }).catch(() => console.error('Could not load zendesk widget'));
        
        return isScriptLoaded;
    }

    public loadScriptYoutube(): void {
        const node = document.createElement('script');

        node.src = "https://www.youtube.com/iframe_api";
        node.id = 'youtube-iframe-api';
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    public deleteScript(id): void {
        if (document.getElementById(id)) {
            document.getElementById(id).remove();
        }
    }
}
