import { Component, Input, ChangeDetectionStrategy, HostListener, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { IPillButtonsCMS } from '@public-pages/pages/home/home.types';
import { IPill } from './pills.types';
import { ScreenAndDeviceService } from '@core/services/helpers/screen-and-device.service';

@Component({
  selector: 'bw-pills',
  templateUrl: './pills.component.html',
  styleUrls: ['./pills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillsComponent implements AfterViewInit {
  @ViewChild('pills') inputElement: ElementRef;
  @Input() layout_content: IPillButtonsCMS;
  @Input() list: IPill[];
  isMobile: boolean = false;

  constructor(private screenAndDeviceService: ScreenAndDeviceService) { }

  ngAfterViewInit() {
    this.setHoverState();
  }

  @HostListener('window:resize', [])
  resize(): void {
    this.setHoverState();
  }

  setHoverState(): void {
    this.isMobile = this.screenAndDeviceService.getScreenType() == "mobile";
    this.inputElement.nativeElement.style.setProperty('--hover-color', (this.isMobile) ?
      this.layout_content.color : this.layout_content.hover_color);
    this.inputElement.nativeElement.style.setProperty('--hover-outline-color', (this.isMobile) ?
      this.layout_content.outline_color : this.layout_content.hover_outline_color);
    this.inputElement.nativeElement.style.setProperty('--hover-background-color', (this.isMobile) ?
      this.layout_content.background_color : this.layout_content.hover_background_color);
  }
}
