import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { get } from 'lodash-es';
import { CmsPage } from '@models/';
import { TermsData } from '@shared/components/terms-content/terms-content.types';
import { Resource } from '@models/stream-resource';
import { PrivacyPolicyData } from '@public-pages/pages/privacy-policy/privacy-policy.types';
import { TermsOfUseData } from '@public-pages/pages/terms-of-use/terms-of-use.types';
import { AccessibilityStatementData } from '@public-pages/pages/accessibility-statement/accessibility-statement.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bw-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss'],
})
export class TermsPageComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  @Input() resource: Resource;
  @Input() pageInfo: PrivacyPolicyData | TermsOfUseData | AccessibilityStatementData;
  data: TermsData[];
  title = '';
  defaultBGColor = '$primary-color';
  defaultTitleColor: '#fff'

  ngOnInit() {
    if (this.resource) {
      this.configureTermsContent();
      this.getDataFromCMS();
    }
  }

  configureTermsContent() {
    this.data = [{
      text: this.title,
      service: this.resource,
    }];
  }

  getDataFromCMS() {
    this.resource.obs
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onResourceChange.bind(this))
  }

  onResourceChange(pageData: CmsPage) {
    this.title = get(pageData, 'title.rendered');
  }

  getNavbarHeight(): number {
    const header = document.getElementById('top-header');
    const space = 10;

    if (header?.offsetHeight) {
      return header.offsetHeight + space;
    }

    return space;
  }
}
