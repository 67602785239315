import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LoggerService } from '@core/services/log4ts/logger.service';
import {environment} from '@environment';
import { Seo } from '@models/content-page';


export const defaultSEOConfig = {
  title: 'Local Fitness Centers | As Low as $15 per Month | Blink Fitness Gym',
  description: 'Visit our super clean and friendly local fitness centers in NY, NJ, MI, Philly, Boston, Chicago, Virginia Beach, and L.A. We offer affordable training programs, and a free 30-minute startup session.',
  image: environment.blinkfitnessSocialUrl,
  url: environment.domain,
  slug: 'BlinkFitness',
  author: 'Blinkfitness.com',
  keywords: 'fitness, gym, blink gym, workout, training, best value, working out, weights, training, strength equipment, cardio equipment, gym near me, cheap gym, gym membership, affordable gym, healthy, fit, affordable gym, clean gym, personal training, boot camp, free trial, new york city',
};

@Injectable()
export class SeoService {

    constructor(
        private logger: LoggerService,
        private meta: Meta,
        private titleService: Title
    ) {}

    generateTags(config?) {
        // default values
        config = {...defaultSEOConfig, ...config};

        this.meta.updateTag({ name: 'keywords', content: config.keywords });
        this.meta.updateTag({ name: 'author', content: config.author });
        this.meta.updateTag({ name: 'description', content: config.description });
        this.titleService.setTitle(config.title);

        // http://ogp.me/
        this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
        this.meta.updateTag({ name: 'twitter:site', content: config.url });
        this.meta.updateTag({ name: 'twitter:title', content: config.title });
        this.meta.updateTag({ name: 'twitter:description', content: config.description });
        this.meta.updateTag({ name: 'twitter:image', content: config.image });

        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:site_name', content: config.author });
        this.meta.updateTag({ property: 'og:title', content: config.title });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:image', content: config.image });
        this.meta.updateTag({ property: 'og:url', content: config.url });
    }

    setMetaTags(tags: MetaTag[] = []){
      tags.forEach((tag: MetaTag) => {
        this.meta.updateTag({property: tag.key, content: tag.value});
      });
    }

    /**
     * Sets the usual tags for SEO Fields from a Content Model SEO tag
     * @param seo_fields 
     */
    setSEOTags(seo_fields:Seo){
    const seo_obj = {
      title: seo_fields.meta_title,
      description: seo_fields.meta_description,
      keywords: seo_fields.meta_keywords
    }
    this.generateTags(seo_obj);
  }
}


interface MetaTag {
  key: string;
  value: string;
}
