import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Logger } from './logger.service';

export const isDebugMode = environment.isDebugMode;

const noop = () => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {

    get info() {
        if (isDebugMode) {
            return console.info.bind(console);
        } else {
            return noop;
        }
    }

    get warn() {
        if (isDebugMode) {
            return console.warn.bind(console);
        } else {
            return noop;
        }
    }

    get error() {
        if (isDebugMode) {
            return console.error.bind(console);
        } else {
            return noop;
        }
    }

    get table() {
        if (isDebugMode) {
            return console.table.bind(console);
        } else {
            return noop;
        }
    }
}
