import { Facility } from './facility';


export class Area {
    title: string;
    facilities: any;
    collapse: boolean;
    isNearest: boolean;

    constructor(data) {
        this.collapse = false;
        this.title = data.name;
        this.facilities = [];
        this.isNearest = false;
        data.facilities.forEach((item: any) => {
            this.facilities.push(new Facility(item));
        });
    }
}
