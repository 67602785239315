import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AccessibilityComponent } from '@shared/components/accessibility/accessibility.component';
import { CookiesService } from '@core/services/helpers/cookies.service';
import { COLOR_ACCESSIBILITY_ENABLED_COOKIE_NAME } from '@core/const/constants';
@Component({
  selector: 'bw-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.scss']
})
export class WebFooterComponent implements OnInit, AfterViewInit {
  @ViewChild(AccessibilityComponent) accessibilityLib: AccessibilityComponent;
  showAccessibility: boolean = true;
  isColorContrastOn: number|boolean;

  constructor(private _cookieService: CookiesService) {
    this.isColorContrastOn = Number(this._cookieService.getCookieByName(COLOR_ACCESSIBILITY_ENABLED_COOKIE_NAME))
      || false;
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.accessibilityLib?.applyTheme(this.isColorContrastOn);
  }

  openAccessibilityModal() {
    this.accessibilityLib.openModal();
  }
}
