import { Component, DestroyRef, Input, ViewEncapsulation, inject } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * @description
 * @author Amit Mahida
 * @export
 */
@Component({
  selector: 'bw-loading-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent {
  private destroyRef = inject(DestroyRef)

  /**
   * @description Default loading spinner template
   * @memberof SpinnerComponent
   */
  _template =
    `<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;

  /**
   * @description Loading text
   * @memberof SpinnerComponent
   */
  _loadingText = '';


  /**
   * @description Defines threhold for not to diplay if time is less than 500ms
   * @memberof SpinnerComponent
   */
  _threshold = 500;

  /**
   * @description Defines timeout to hide after time 5000ms
   * @memberof SpinnerComponent
   */
  _timeout = 0;

  /**
   * @description Defines z-index property of the loading text
   * @memberof SpinnerComponent
   */
  _zIndex = 9999;

  /**
   * @description Sets z-index for input text
   * @memberof SpinnerComponent
   */
  @Input()
  public set zIndex(value: number) {
    this._zIndex = value;
  }

  /**
   * @description returns z-index for input text
   * @readonly
   * @memberof SpinnerComponent
   */
  public get zIndex(): number {
    return this._zIndex;
  }

  /**
   * @description Accepts custom template
   * @memberof SpinnerComponent
   */
  @Input()
  public set template(value: string) {
    this._template = value;
  }


  /**
   * @description Gives the current template
   * @readonly
   * @memberof SpinnerComponent
   */
  public get template(): string {
    return this._template;
  }


  /**
   * @description Accepts loading text string
   * @memberof SpinnerComponent
   */
  @Input()
  public set loadingText(value: string) {
    this._loadingText = value;
  }


  /**
   * @description Gives loading text
   * @readonly
   * @memberof SpinnerComponent
   */
  public get loadingText(): string {
    return this._loadingText;
  }


  /**
   * @description Accepts external threshold
   * @memberof SpinnerComponent
   */
  @Input()
  public set threshold(value: number) {
    this._threshold = value;
  }


  /**
   * @description
   * @readonly
   * @memberof SpinnerComponent
   */
  public get threshold(): number {
    return this._threshold;
  }

  /**
   * @description Accepts external timeout
   * @memberof SpinnerComponent
   */
  @Input()
  public set timeout(value: number) {
    this._timeout = value;
  }


  /**
   * @description
   * @readonly
   * @memberof SpinnerComponent
   */
  public get timeout(): number {
    return this._timeout;
  }

  /**
   * @description Show/hide spinner
   * @memberof SpinnerComponent
   */
  showSpinner = false;

  /**
   * Constructor
   * @param spinnerService Spinner Service
   * @memberof SpinnerComponent
   */
  constructor(
    private spinnerService: SpinnerService
  ) {
    this.createServiceSubscription();
  }

  /**
   * Create service subscription
   * @memberof SpinnerComponent
   */
  createServiceSubscription() {
    let thresholdTimer: any;
    let timeoutTimer: any;

    this.spinnerService.getMessage()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(show => {
      if (show) {
        if (thresholdTimer) {
          return;
        }
        thresholdTimer = setTimeout(function () {
          thresholdTimer = null;
          this.showSpinner = show;
          if (this.timeout !== 0) {
            timeoutTimer = setTimeout(function () {
              timeoutTimer = null;
              this.showSpinner = false;
            }.bind(this), this.timeout);
          }
        }.bind(this), this.threshold);
      } else {
        if (thresholdTimer) {
          clearTimeout(thresholdTimer);
          thresholdTimer = null;
        }
        if (timeoutTimer) {
          clearTimeout(timeoutTimer);
        }
        timeoutTimer = null;
        this.showSpinner = false;
      }
    });
  }
}
