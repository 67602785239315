import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '@core/services/log4ts/logger.service';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {FacadeService} from './facade.service';
import {Resource} from '@models/stream-resource';
import {FacilityShortData} from '@models/facility-short-data';

@Injectable()
export class StreamService {
  public facilitiesFull = new Resource();
  public facilitiesShort = new Resource();
  public locationAreasGlobalCMS = new Resource();
  public locationsPageDataNewCMS = new Resource();
  public homePageDataNewCMS = new Resource();
  public whyBlinkPageData = new Resource();
  public whyBlinkRedesignPageData = new Resource();
  public blinkAppPageData = new Resource();
  public personalTrainingPageData = new Resource();
  public locationsPageData = new Resource();
  public homePageData = new Resource();
  public faqPageData = new Resource();
  public freeTrialData = new Resource();
  public startupPageData = new Resource();
  public startupMemberPageData = new Resource();
  public sweepstakesPageData = new Resource();
  public alumniBluePageData = new Resource();
  public maintenancePageData = new Resource();
  public headerLinks = new Resource();
  public footerLinks = new Resource();
  public tcNotice = new Resource();
  public clubTerms = new Resource();
  public termsOfUseCMS = new Resource();
  public alumniRiderCMS = new Resource();
  public changeLocationModal = new Resource();
  public privacyCMS = new Resource();
  public privacyPolicyData = new Resource();
  public accessibilityStatementCMS = new Resource();
  public accessibilityStatementData = new Resource();
  public termsOfUseData = new Resource();
  public tcGympass = new Resource();
  public currentLocation = new Resource();
  public facilityInfoBySlugShort = new Resource();
  public clubDataBySlugFull = new Resource();
  public globalEmergencySettings = new Resource();
  public emergencySettings = new Resource();
  public clubAnnouncementBanner = new Resource();
  public locationsBanner = new Resource();
  public ptStartupBanner = new Resource();
  public sweepstakesBanner = new Resource();
  public cmsPromoData = new Resource();
  public notFoundPageData = new Resource();
  public getLeadGenModalCMSData = new Resource();
  public checkoutCMSData = new Resource();
  public confirmationPageData = new Resource();
  public careersPageCMSData = new Resource();

  public marginTopBanner = new Resource(); // remove
  public showLocationsPageBannerGlobal = new Resource(true);
  public showAreaPageBannerGlobal = new Resource(true);
  public showClubPageBannerGlobal = new Resource(true);
  public showHomePageBannerGlobal = new Resource(true);
  public showPTStartupPageBannerGlobal = new Resource(true);
  public showSweepstakesPageBannerGlobal = new Resource(true);
  public canonicalSettings = new Resource();
  public footerTextSettings = new Resource();

  public contentPage = new Resource();

  public benefitData = new Resource();
  public _nearestLocationsResponse = new Subject();
  public _nearestLocationsRequest = new Subject();
  public colorContrastToggle = new Subject<boolean>();

  constructor(private httpClient: HttpClient, private logger: LoggerService, private _facadeService: FacadeService) {
  }

  getShowLocationsPageBannerGlobal() {
    return this.showLocationsPageBannerGlobal.getValue();
  }

  getShowClubPageBannerGlobal() {
    return this.showClubPageBannerGlobal.getValue();
  }

  getShowHomePageBannerGlobal() {
    return this.showHomePageBannerGlobal.getValue();
  }

  getShowPTStartupPageBannerGlobal() {
    return this.showPTStartupPageBannerGlobal.getValue();
  }

  getShowSweepstakesPageBannerGlobal() {
    return this.showSweepstakesPageBannerGlobal.getValue();
  }

  getLocationsPageData() {
    this.locationsPageData.request(() => this._facadeService.getPageBySlugName('locations'));
  }

  getMaintenancePageData() {
    this.maintenancePageData.request(() => this._facadeService.getPageBySlugName('maintenance'));
  }

  getHomePageData() {
    this.homePageData.request(() => this._facadeService.getPageBySlugName('home-page'));
  }


  getStartupPageData() {
    this.startupPageData.request(() => this._facadeService.getPageBySlugName('personal-training-startup-page'));
  }

  getStartupMemberPageData() {
    this.startupMemberPageData.request(() => this._facadeService.getPageBySlugName('personal-training-startup-member-page'));
  }
  getSweepstakesMemberPageData() {
    this.sweepstakesPageData.request(() => this._facadeService.getPageBySlugName('sweepstakes'));
  }

  getAlumniBlueMemberPageData() {
    this.alumniBluePageData.request(() => this._facadeService.getPageBySlugName('alumni-blue'));
  }

  getCmsPromoData() {
    this.cmsPromoData.request(() => this._facadeService.getDataSetBySlugName('promo-code-modal'));
  }

  getWhyBlinkRedesignPageData() {
    this.whyBlinkRedesignPageData.request(() => this._facadeService.getPageBySlugName('why-blink-redesign'));
  }

  getBlinkAppPageData() {
    this.blinkAppPageData.request(() => this._facadeService.getPageBySlugName('blink-app'));
  }

  getPersonalTrainingPageData() {
    this.personalTrainingPageData.request(() => this._facadeService.getPageBySlugName('personal-training-page'));
  }

  getTrialFormPageData() {
    this.freeTrialData.request(() => this._facadeService.getDataSetBySlugName('free-trial-modal'));
  }

  getTcNotice() {
    this.tcNotice.request(() => this._facadeService.getDataSetBySlugName('tc-notice'));
  }

  getGlobalEmergencySettings() {
    this.globalEmergencySettings.request(() => this._facadeService.getDataSetBySlugName('global-emergency-settings'));
  }

  updateEmergencySettings(emergencySettingsData: any) {
    this.emergencySettings.update(emergencySettingsData);
  }

  getTermsOfUseCMS() {
    this.termsOfUseCMS.request(() => this._facadeService.getPageBySlugNameGlobal('terms'));
  }

  getAlumniRiderCMS() {
    this.alumniRiderCMS.request(() => this._facadeService.getPageBySlugNameGlobal('alumni-rider'));
  }

  getPrivacyCMS() {
    this.privacyCMS.request(() => this._facadeService.getPageBySlugNameGlobal('privacy-policy'));
  }

  getPrivacyPolicyData() {
    this.privacyPolicyData.request(() => this._facadeService.getPageBySlugName('privacy'));
  }

  getTermsOfUseData() {
    this.termsOfUseData.request(() => this._facadeService.getPageBySlugName('terms'));
  }

  getAccessibilityStatementCMS() {
    this.accessibilityStatementCMS.request(() => this._facadeService.getPageBySlugNameGlobal('accessibility-statement'));
  }

  getAccessibilityStatementData() {
    this.accessibilityStatementData.request(() => this._facadeService.getPageBySlugName('accessibility-statement'));
  }

  getGympassTcCMS() {
    this.tcGympass.request(() => this._facadeService.getPageBySlugNameGlobal('terms-and-conditions-gym-pass'));
  }

  getHeaderLinks() {
    this.headerLinks.request(() => this._facadeService.getDataSetBySlugName('header-links'));
  }

  getChangeLocationModal() {
    this.changeLocationModal.request(() => this._facadeService.getDataSetBySlugName('alumni-blue-change-location-modal'));
  }

  getFooterLinks() {
    this.footerLinks.request(() => this._facadeService.getDataSetBySlugName('footer-links'));
  }

  getCanonicalSettings() {
    this.canonicalSettings.request(() => this._facadeService.getDataSetBySlugName('canonical-url-settings'));
  }

  getFooterCopySettings() {
    this.footerTextSettings.request(() => this._facadeService.getDataSetBySlugName('footer-text-settings'));
  }

  getClubTerms(id: number) {
    if (id) {
      this.clubTerms.request(() => this._facadeService.getGlobalCMSDataByTypeAndId({type: 'club-terms', id}));
    }
  }

  getFacilityInfoBySlugShort(slug: string) {
    this.facilityInfoBySlugShort.request(() => this._facadeService.getFacilityInfoBySlugShort(slug));
  }

  getClubDataBySlugFull(slug: string) {
    this.clubDataBySlugFull.request(() => this._facadeService.getClubDataBySlugFull(slug));
  }

  getDatasetByBenefitId(id: number) {
    this.benefitData.request(() => this._facadeService.getDatasetByBenefitId(id));
  }

  getAllFacilityAreasGlobal() {
    this.locationAreasGlobalCMS.request(() => this._facadeService.getAllFacilityAreasGlobal());
  }

  getAllFacilitiesGlobal() {
    this.facilitiesFull.request(() => {
      return this._facadeService.getAllFacilitiesGlobal().pipe(
        map((data: any[]) => {
          this.facilitiesShort.update(new FacilityShortData(data));
          return data;
        })
      );
    });
  }

  getLocationData(slug: string) {
    this.currentLocation.request(() => {
      return this._facadeService.getLocationData(slug).pipe(
        map((res: any) => {
          const facilityInfoBySlugShort = this.facilityInfoBySlugShort.getValue();
          if (!facilityInfoBySlugShort) {
            return null;
          } else {
            res[0].acf.terms_id = facilityInfoBySlugShort[0].acf.terms_id;
            res[0].acf.moso_id = facilityInfoBySlugShort[0].acf.moso_id;
            res[0].acf.address = facilityInfoBySlugShort[0].acf.address;
            res[0].acf.contacts = facilityInfoBySlugShort[0].acf.contacts;
            res[0].acf.title = facilityInfoBySlugShort[0].acf.title;
            res[0].acf.type = facilityInfoBySlugShort[0].acf.type;
            res[0].acf.agreement_start_day = facilityInfoBySlugShort[0].acf.agreement_start_day;
            return res;
          }
        })
      );
    });
  }

  getPostBySlugName(slug: string) {
    this.contentPage.request(() => this._facadeService.getPostBySlugName(slug));
  }

  getNotFoundPageData() {
    this.notFoundPageData.request(() => this._facadeService.getPageBySlugName('not-found-404-page'));
  }

  getCheckoutPageData() {
    this.checkoutCMSData.request(() => this._facadeService.getPageBySlugName('checkout-page'));
  }

  getConfirmationPageData() {
    this.confirmationPageData.request(() => this._facadeService.getPageBySlugName('confirmation'));
  }

  getCareersPageData() {
    this.careersPageCMSData.request(() => this._facadeService.getPageBySlugName('careers-page'));
  }
}
