export interface CCPARequest {
    firstName: string;
    lastName: string;
    memberId: string;
    homeFacility: string;
    phone: string;
    zip: string;
    email: string;
    address: string;
    isConsumer: boolean;
    isAuthorized: boolean;
    requester?: {
        firstName: string;
        lastName: string;
        phone: string;
        email: string;
    };
    requestType: string[];
    confirmationEmail?: {
        send: boolean;
        body: string;
        title: string;
    };
}

export class CCPACMSData {
    emailBody: string;
    emailTitle: string;
    sendConfirmation: boolean;
    thankYouMessage: string;

    constructor(data: any) {
        this.emailBody = data.acf.email_body ? data.acf.email_body : 'We are writing to inform you that your data privacy request has been received. Thank you.';
        this.emailTitle = data.acf.email_title ? data.acf.email_title : 'Your Blink Fitness data privacy request has been received';
        this.sendConfirmation = data.acf.customer_email_onoff ? data.acf.customer_email_onoff : false;
        this.thankYouMessage = data.acf.thankyoumessage ? data.acf.thankyoumessage : 'Your submission has been received.';
    }
}

export const CCPAData = {
    requests: [
      { id: 'specific', description: 'Specific pieces of personal information that Blink has about me' },
      { id: 'personal', description: 'Categories of personal information Blink has collected about me' },
      { id: 'sources', description: 'Categories of sources from which Blink collects my personal information' },
      { id: 'personalDisclosed', description: 'Categories of personal information that Blink has disclosed about me for a business purpose' },
      { id: 'thirdParty', description: 'Categories of third parties to whom my personal information was disclosed for a business purpose' },
      { id: 'purpose', description: 'The business or commercial purpose for collecting my personal information' },
    ],
    requestTypes:[
        { id: 'copyPersonal', description: 'Request a copy of the personal information Blink has about me' },
        { id: 'deletePersonal', description: 'Request deletion of personal information Blink has collected from me' }
    ]
}
