import { Component, Input } from '@angular/core';
import { PrincingSticker } from './sticker.types';

@Component({
  selector: 'bw-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss']
})
export class StickerComponent {
  @Input() stickerType = 'default';
  @Input() princingSticker: PrincingSticker;

  getAriaLabel(){
    const copies:string[] = [];
    this.princingSticker.stickerContent.forEach(content => {
      copies.push(content.copy)
    })

    return copies.join(' ')
  }
}
