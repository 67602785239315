import { PromoCodeSettings } from '@models/promo-code-settings';

interface IRateCardSettings {
    discount_text: string;
    discount_color: string;
    due_today_color: string;
}

export class RateCardSettings implements IRateCardSettings {
    discount_text: string;
    discount_color: string;
    due_today_color: string;
    promo_settings: any;

    constructor(data: any) {
        this.discount_text = data ? data.acf.discount_line_text ? data.acf.discount_line_text : 'Discount' : 'Discount';
        this.discount_color = data ? data.acf.discount_line_text_color ? data.acf.discount_line_text_color : '#00ad00' : '#00ad00';
        this.due_today_color = data ? data.acf.due_today_line_text_color ? data.acf.due_today_line_text_color : '#00ad00' : '#00ad00';
        this.promo_settings = new PromoCodeSettings(data && data.acf && data.acf.promo_code);
    }

}
