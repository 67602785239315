import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { PopupService } from '@core/services/popup.service';

declare let $: any;



@Component({
    selector: 'bw-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements AfterViewInit, OnDestroy {

    @ViewChild('modalRef') modalRef: ElementRef;
    @Output('onReady') onReady = new EventEmitter<ModalComponent>();
    @Output('onShow') onShow = new EventEmitter<any>();
    @Output('onClose') onClose = new EventEmitter<any>();
    @Input('customClass') customClass = {};
    @Input('correctPosition') correctPosition = true;
    @Input('modalId') modalId = '';

    /**
     * @constructor
     * @param {PopupService} popupService
     */
    constructor(
        private popupService: PopupService
    ) { }

    ngAfterViewInit() {
        this.onReady.emit(this);
        $(this.modalRef.nativeElement).on('hide.bs.modal', () => {
            this.popupService.setPopupStateOpen(false);
            this.onClose.emit();
        });
        $(this.modalRef.nativeElement).on('show.bs.modal', () => {
            this.popupService.setPopupStateOpen(true);
            this.onShow.emit();
        });
    }

    show() {
        $(this.modalRef.nativeElement).modal('show');
    }

    close() {
        $(this.modalRef.nativeElement).modal('hide');
    }

    ngOnDestroy() {
        $(this.modalRef.nativeElement).off('hide.bs.modal', () => {
        });
        $(this.modalRef.nativeElement).off('show.bs.modal', () => {
        });
    }

}
