export enum EAnnouncementBannerLinks {
  SWEEPS = 'sweeps_modal',
  LOCATIONS = 'locations',
  LOCATIONS_AREA = 'locations_area',
  EMERGENCY_MODAL = 'emergency_modal',
  WARNING_MODAL = 'warning_modal',
  EMERGENCY_MODAL_EXTERNAL_URL = 'emergency_modal_external_url',
  PT_STARTUP = 'pt_startup',
}

export interface IAnnouncementBanner {
  banner_image_desktop: string;
  banner_image_mobile: string;
  banner_image_tablet: string;
  show_banner: boolean;
  banner_url: string;
  sweepstakes?: any;
  sweepstakes_active?: boolean;
  modal_title?: string;
  submit_title?: string;
  banner_color?: string;
  banner_active: boolean;
  linked_to: string;
  banner_facility_area?: any[];
  external_url?: string;
}
