import { Component, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { PageContentWrapper } from './accordion-image-layout.types';

@Component({
  selector: 'bw-accordion-image-layout',
  templateUrl: './accordion-image-layout.component.html',
  styleUrls: ['./accordion-image-layout.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.3s ease-out',
              style({ height: 90, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 90, opacity: 1 }),
            animate('0.3s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class AccordionImageLayoutComponent {

  activeAccordion: number;
  openedAccordion: number;
  desktopBreakpoint = 768;

  @Input() content: PageContentWrapper;
  @Input() deviceType: string;

  toggleAccordion(fatherIndex, chidlIndex: number): void {
    this.activeAccordion = fatherIndex;
    this.openedAccordion = chidlIndex === this.openedAccordion ? null : chidlIndex;
  }

}
