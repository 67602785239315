interface IPromoCodeSettings {
    show_promo_code: boolean;
    promo_copy: string;
    blue: boolean;
    gray: boolean;
    green: boolean;
    orange: boolean;
}

export class PromoCodeSettings implements IPromoCodeSettings {
    show_promo_code: boolean;
    promo_copy: string;
    blue: boolean;
    gray: boolean;
    green: boolean;
    orange: boolean;

    constructor(data: any) {
        this.show_promo_code = data ? data.show_promo_code : false ;
        this.promo_copy = data ? data.promo_copy : 'Have a Promo Code';
        this.blue = data ? data.blue  : false;
        this.gray = data ? data.gray  : false;
        this.green = data ? data.green  : false;
        this.orange = data ? data.orange  : false;
    }

}


interface ICmsPromoData{
  promo_code: string;
  success: any;
  terms: string;
  pdf_file: string;
}

export class PromoData implements ICmsPromoData {
  promo_code: string;
  success: any;
  terms: string;
  pdf_file: string;

  constructor(data: any) {
    this.promo_code = data.promo_code;
    this.success = {
      header: data.success.header,
      body: data.success.body
    };
    this.terms = data.terms;
    this.pdf_file= data.pdf_file;
  }
}


export class CmsPromoData{
    promo_code_settings: PromoData[];
    error: any;
    constructor(data) {
        this.promo_code_settings = [];
        data.acf.promo_code_settings.forEach((item: any) => {
            this.promo_code_settings.push(new PromoData(item));
        });
        this.error = data.acf.error;
    }

}

