import { Component, Input, EventEmitter, Output } from '@angular/core';
import { WCAGUtils } from '@core/services/helpers/wcag.utils';

@Component({
  selector: 'bw-promo-banner-item',
  templateUrl: './promo-banner-item.component.html'
})
export class PromoBannerItemComponent {
  @Output() onClick = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() imgClass = '';
  @Input() src = '';
  @Input() alt = '';
  onKeyPress = WCAGUtils.onKeyPress;

  constructor() { }
}
