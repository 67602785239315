import { Component, DestroyRef, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { get } from 'lodash-es';
import { messagesOptions } from '@core/const/massage-options';
import { FacadeService } from '@core/services/core/facade.service';
import { StreamService } from '@core/services/core/stream.service';
import { ToastService } from '@core/services/helpers/toast.service';
import { PopupService } from '@core/services/popup.service';
import { environment } from '@environment';
import { FormRules, termsRule, phoneRule } from './sweepstakes-popup.validators';
import { SpinnerService } from '@shared/components/spinner/spinner.module';
import { BriteVerifyValidator } from '@shared/validators';
import { GTMEvent, TagManagerClickDirectiveHelper } from '@shared/directives/tag-manager.directive';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

declare let $: any;


@Component({
  selector: 'bw-sweepstakes-popup',
  templateUrl: './sweepstakes-popup.component.html',
  styleUrls: ['./sweepstakes-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SweepstakesPopupComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  isRecaptcha = false;
  termsList: any[];
  termsSelected: number;
  modal: any;
  sweepTermsId: number;
  sweepstakesPopup: UntypedFormGroup;
  optionalPhone: boolean;
  template = '';
  formData: any;
  modalStatus = 'form';
  facility: any; // get facility
  isCheckboxVisible: boolean;
  termsAndConditions: any;
  termsOfUse: any;
  privacy: any;
  key = environment.API_CONF.RECAPTCHA_KEY;
  currentFacility: any;

  constructor(
    private fb: UntypedFormBuilder,
    private _facadeService: FacadeService,
    private _popupService: PopupService,
    private _streamService: StreamService,
    private toastService: ToastService,
    private spinnerService: SpinnerService,
    public briteVerifyValidator: BriteVerifyValidator,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    this.modal = $('#sweepstakesPopup');
    this.sweepstakesPopup = this.fb.group(
      FormRules(this.briteVerifyValidator)
    );

    this.sweepstakesPopupSubscribe();
    // Fetch data
    this.getTermsAndConditions();
    this.currentClubSubscribe();

    // Listen to Bootstrap modal events
    this.modal.on('hidden.bs.modal', this.onModalHidden.bind(this));
  }

  getEmailErrorMessage() {
    const emailField = this.sweepstakesPopup.get('email');
    return (emailField.errors && emailField.errors.Briteverify) ?
        'Please enter a valid email address' : 'Please enter your email';
  }

  onModalHidden(): void {
    this._popupService.turnOffSweepstakesPopup();
    this.clearInputs();
  }

  sweepstakesPopupSubscribe(): void {
      this._popupService._sweepstakesPopup.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onSweepstaksPopupChange.bind(this))
  }

  onSweepstaksPopupChange(res: boolean) {
    this.modal.modal(res ? 'show' : 'hide');
    if (res) {
      this.setClubId(this.facility);
    }
  }

  getTermsAndConditions() {
      combineLatest([this._streamService.termsOfUseCMS.obs, this._streamService.privacyCMS.obs])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onTermsAndConditionsChange.bind(this))
  }

  onTermsAndConditionsChange(results: any[]): void {
    this.termsOfUse = results[0];
    this.privacy = results[1];
    this.termsAndConditions = results[3];
  }

  currentClubSubscribe(): void {
      this._streamService.clubDataBySlugFull.obs.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onClubDataBySlugFullChange.bind(this))
  }

  onClubDataBySlugFullChange(res: any): void {
    if (!res) {
      return;
    }

    this.facility = res[0];

    const sweepstakes = get(this, 'facility.acf.promo_banner.sweepstakes');
    if (sweepstakes) {
      const checkValue = (val: string): boolean => !!get(sweepstakes, `acf.${val}`);

      this.isCheckboxVisible = checkValue('show_terms');
      this.optionalPhone = checkValue('show_phone');
      this.isRecaptcha = checkValue('recaptcha');
      this.setValidators();
    }
  }

  setupTerms() {
    this.termsList = [{
      text: 'official rules',
      service: this._streamService.clubTerms,
      retrieve: () => this._streamService.getClubTerms(this.sweepTermsId),
    }, {
      text: 'terms of use',
      service: this._streamService.termsOfUseCMS,
      retrieve: this._streamService.getTermsOfUseCMS,
    }, {
      text: 'privacy policy',
      service: this._streamService.privacyCMS,
      retrieve: this._streamService.getPrivacyCMS,
    }];

    this.getFacilityInfoBySlugShort();
  }

  getFacilityInfoBySlugShort(): void {
    this._streamService.facilityInfoBySlugShort.obs.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onFacilityInfoBySlugShortChange.bind(this))
  }

  onFacilityInfoBySlugShortChange(facility: any[]): void {
    if (!facility || (facility && facility[0] && facility[0].slug !== this.facility.slug)) {
      return this._streamService.getFacilityInfoBySlugShort(this.facility.slug);
    }

    const { sweep_terms_id: sweepTermsId } = facility[0].acf;
    this.sweepTermsId = sweepTermsId;

    this._streamService.clubTerms.obs.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onClubTermsChange.bind(this))
  }

  onClubTermsChange(terms: any): void {
    if (!terms) {
      return this._streamService.getClubTerms(this.sweepTermsId);
    }

    this.termsAndConditions = terms;
  }

  onTermsCheckboxChange(checked: boolean) {
    if (checked) {
      this.onTermsClose();
    }
  }

  onTermsClick(obj: any): void {
    this.termsSelected = (this.termsSelected !== obj.index) ? obj.index : undefined;
  }

  onTermsClose(): void {
    this.termsSelected = undefined;
  }

  clearInputs(): void {
    this.modalStatus = 'form';
    this.sweepstakesPopup.reset();
  }

  setValidators(): void {
    // Setting Terms Validators
    if (this.isCheckboxVisible) {
      this.sweepstakesPopup.addControl(
        'checkbox_terms',
        new UntypedFormControl(false, termsRule)
      );

      if (!this.termsList) {
        this.setupTerms();
      }
    }

    // Setting Phone Validators
    if (this.optionalPhone) {
      this.sweepstakesPopup.addControl(
        'phone',
        new UntypedFormControl('', phoneRule)
      );
    }
  }

  submit(): void {
    this.spinnerService.show();
    this.formData = this.sweepstakesPopup.getRawValue();

    if (this.optionalPhone) {
      const phone = this.formData.phone;
      phone ? (this.formData.phone = phone.replace(/[^0-9]/g, '')) : delete this.formData['phone'];
    }

    if (this.isCheckboxVisible) {
      this.formData.termsOfUse = this.termsOfUse.acf.pdf_link;
      this.formData.privacyPolicy = this.privacy.acf.pdf_link;
      this.formData.termsAndConditions = this.termsAndConditions.acf.pdf_link;
    }

    const facilities = this._streamService.facilitiesFull.getValue();
    const currentFacility = facilities.find((f: any) => f.slug === this.facility.slug);

    this._facadeService.processSweepStakes(this.formData, currentFacility)
      .then(this.onSubmitSuccess.bind(this))
      .catch(this.onSubmitError.bind(this));
  }

  onSubmitSuccess(): void {
    this.resetClubId();
    this.spinnerService.hide();
    this.sweepstakesPopup.reset();
    this.modalStatus = 'thanks';
    this.spinnerService.hide();
  }

  onSubmitError(err: any): void {
    this.spinnerService.hide();
    this._popupService.turnOffSweepstakesPopup();

    if (err.status === 406) {
      if (!err.error) {
        err.error = {};
      }

      this.toastService.setToastVisible(
        messagesOptions.requestSweepstakesError(err.error.message)
      );
    } else {
      this.toastService.setToastVisible(messagesOptions.requestError);
    }

    this.spinnerService.hide();
  }

  setClubId(facility: any): void {
    if (!facility) return;
    const facilities = this._streamService.facilitiesFull.getValue();
    this.currentFacility = facilities && facilities.find((f: any) => f.slug === facility.slug);

    if (!this.currentFacility) return;
    this.sentGTMEvent("Open", this.currentFacility.acf.moso_id);
  }

  resetClubId(): void {
    if (this.currentFacility) {
      this.sentGTMEvent("Completed", this.currentFacility.acf.moso_id);
      this.currentFacility = null;
    } 
  }

  resolved(captchaResponse: string) {
    this._facadeService.checkCaptcha(captchaResponse).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onCheckCaptchaChange.bind(this))
  }

  onCheckCaptchaChange(res: any) {
    if (res.success) {
      this.submit();
    }
  }

  ngOnDestroy(): void {
    this.clearInputs();
    this.onTermsClose();
  }

  sentGTMEvent(clickType: string, account: string): void{
    const pageData = TagManagerClickDirectiveHelper.getBodyPageData();
    if(pageData != null){
        this.gtmService.pushTag({
            ...TagManagerClickDirectiveHelper.getDefaultEventData(GTMEvent.Click, pageData),
            "clickType": `Sweepstakes - ${clickType}`,
            "accoun": account
        });
    }
  }
}

