export interface IAccessibilityConfigCMS {
  acf: IAccessibilityConfig;
}

export interface IAccessibilityConfig {
  enabled: boolean;
  included_html_tags: string;
  color_replacement: IColorReplacement[];
  variable_replacement: IVariableReplacement[];
}

export interface IColorReplacement {
  normal: string;
  accessible: string;
}

export interface IVariableReplacement {
  variable_name: string;
  normal: string;
  accessible: string;
}