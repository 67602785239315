import { get } from 'lodash-es';

export class Facility {
    title: string;
    address: string;
    type: string;
    label_of_link_mobile: string;
    label_of_link_tablet: string;
    label_of_location_indicator: string;
    order: number;
    source: any;
    emergency_label_active: boolean;
    emergency_label_title_color: string;

    constructor(data) {
        this.source = data;
        this.order = data.acf.order || 999999;
        this.title = data.acf.title;
        this.type = data.acf.type;
        this.label_of_link_tablet = get(data, 'acf.labels.label_of_link_tablet');
        this.label_of_link_mobile = get(data, 'acf.labels.label_of_link_mobile');
        this.emergency_label_active = get(data, 'acf.emergency_label.acf.emergency_label_active');
        this.emergency_label_title_color = get(data, 'acf.emergency_label.acf.emergency_label_title_color');
        this.label_of_location_indicator = this.getLabel(data, this.emergency_label_active);
        // there will be no phone number displayed For Sweeps/Presale/Preview locations
        this.address = `
            <p>${data.acf.address.street}</p>
            <p>${data.acf.address.city}, ${data.acf.address.state} ${data.acf.address.zip}</p>
            ${data.acf.contacts.phone ? `<p>${data.acf.contacts.phone}</p>` : ''}
        `;
    }

    getLabel(data: any, emergency_label_active: boolean) {
        return emergency_label_active ?
            get(data, 'acf.emergency_label.acf.emergency_label_title') :
            get(data, 'acf.labels.label_of_location_indicator');
    }
}
