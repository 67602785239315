import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessibilityComponent } from '@shared/components/accessibility/accessibility.component';

@Component({
  selector: 'bw-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.scss']
})
export class WebFooterComponent implements OnInit {
  @ViewChild(AccessibilityComponent) accessibilityLib: AccessibilityComponent;
  showAccessibility: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  openAccessibilityModal() {
    this.accessibilityLib.openModal();
  }

  setAccessibilityToggle(isOn: boolean) {
    this.showAccessibility = isOn;
  }
}
