import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { IRedirection, getLocalRedirections } from './redirections';
import { environment } from '@environment';
import { getRedirectionPath, objectToQueryString } from '@core/helpers';
import { PrerenderService } from '@core/services/core/prerender.service';

/**
 * Permanent Redirect Guard
 */
@Injectable()
export class RedirectGuard  {
  /**
   * Constructor
   * @param {PrerenderService} prerenderService
   * @param {Router} route
   */
  constructor(private prerenderService: PrerenderService, private route: Router) {}
  /**
   * Redirects to local page with query
   * @param {ActivatedRouteSnapshot} route
   * @param {IRedirection} redirection
   */
  localRedirect(route: ActivatedRouteSnapshot, redirection: IRedirection): void {
    const { queryParams } = route;
    const queryString = objectToQueryString(queryParams);
    const prefix = redirection.href.includes('?') ? '&' : '?';
    const params = queryString?.length ? `${prefix}${queryString}` : '';
    this.route.navigateByUrl(`${redirection.href}${params}`, { state: { redirected: true }});
  }

  /**
   * Checks if the route can be accessed,
   * if not it redirects to the proper url
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   *  Wether the route can be activated or not
   */
  checkActivateLocalRedirect(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.prerenderService.remove301Header();
    const urls = getLocalRedirections();
    const path = state.url.split('?')[0];
    const redirection = getRedirectionPath(path, urls);
    if (!redirection) {
      return true;
    }
    const redirectedUrl = window.location.href.replace(path, redirection.href);
    this.prerenderService.add301Header(redirectedUrl);
    this.localRedirect(route, redirection);
    return false;
  }


  /**
   * 
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   *  Redirects health and fitness pages to blog version
   */
  redirectToHealthAndFitness(state: RouterStateSnapshot): boolean {
    const urlSplit = state.url.split('/');
    const articleSlug = urlSplit[urlSplit.length - 1];
    if (!articleSlug) {
      // redirect to homepage if no slug
      this.route.navigateByUrl(`/`, { state: { redirected: true }});
      return true;
    }
    window.location.href = `${environment.domainBlog}/${articleSlug}?redirected=1`;
    return false;
  }

  /**
   * 
   * @returns {boolean}
   *  Redirects virtual fitness pages to blog version
   */
  redirectToVirtualFitness(): boolean {
    this.prerenderService.remove301Header();
    const href = `${environment.domainBlog}/${environment.virtualFitnessBlogRedirectionSlug}?redirected=1`;
    this.prerenderService.add301Header();
    window.location.href = href;
    return false;
  }

  /**
   * Checks path and calls method based on path
   * @param {ActivatedRouteSnapshot} route 
   * @param {RouterStateSnapshot} state 
   * @returns {boolean}
   */
  getCheckOrRedirect(route, state) {
    const rootPath = route.routeConfig.path?.split('/')[0];
    const path = rootPath || '';
    const routeToRedirectCheck = {
      'health-and-fitness': () => { this.redirectToHealthAndFitness(state) },
      'virtual-fitness': () => { this.redirectToVirtualFitness() },
      'default': () => { this.checkActivateLocalRedirect(route, state) }
    }
    return routeToRedirectCheck[path]() || routeToRedirectCheck['default']();
  }

  /**
   * CanActivate - The logic to activate or not a route
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   *  Wether the route can be activated or not
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.getCheckOrRedirect(route, state);
  }

  /**
   * CanActivateChild - The logic to activate or not a child route
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {boolean}
   *  Wether the child route can be activated or not
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkActivateLocalRedirect(route, state);
  }
}
