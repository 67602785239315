import { CMSData } from '.';

interface IAnnouncementBanner {
    banner_image_desktop: string;
    banner_image_mobile: string;
    banner_image_tablet: string;
    show_banner: boolean;
    banner_url: string;
    sweepstakes: any;
    sweepstakes_active: boolean;
    modal_title: string;
    submit_title: string;
    banner_color: string;
}

export class AnnouncementBanner implements IAnnouncementBanner {
    banner_image_desktop: string;
    banner_image_mobile: string;
    banner_image_tablet: string;
    show_banner: boolean;
    banner_url: string;
    linked_to: string;
    sweepstakes: any;
    sweepstakes_active: boolean;
    modal_title: string;
    submit_title: string;
    promo_banner_url: string;
    banner_color: string;
    banner_active: boolean;


    constructor(data: CMSData) {
        const banner = (data &&
            data.acf &&
            data.acf.promo_banner &&
            data.acf.promo_banner.announcement_banner &&
            data.acf.promo_banner.announcement_banner.acf &&
            data.acf.promo_banner.announcement_banner.acf.announcement_banner) ?
            data.acf.promo_banner.announcement_banner.acf.announcement_banner : {};
        this.banner_image_desktop = banner.banner_image_desktop;
        this.banner_image_mobile = banner.banner_image_mobile;
        this.banner_image_tablet = banner.banner_image_tablet;
        this.banner_color = banner.banner_color;
        this.show_banner = banner.show_banner;
        this.banner_url = data.acf.promo_banner.banner_url;
        this.linked_to = data.acf.promo_banner.linked_to;
        this.sweepstakes = data.acf.promo_banner.sweepstakes;
        this.sweepstakes_active = data.acf.promo_banner.sweepstakes.active;
        this.modal_title = data.acf.promo_banner.sweepstakes.modal_title;
        this.submit_title = data.acf.promo_banner.sweepstakes.submit_title;
        this.promo_banner_url = data.acf.promo_banner.promo_banner_url;
        this.banner_active = data.acf.promo_banner_active;
    }
}
