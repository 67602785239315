import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from './log4ts/logger.service';
import { environment } from '@environment';
import { ToastService } from './helpers/toast.service';
import { Observable } from 'rxjs';

@Injectable()
export class FacilitiesService {

    constructor(private httpClient: HttpClient, private logger: LoggerService, private toastService: ToastService) { }

    getClubDataBySlugFull(slug: string): Observable<any> {
        const url = `${environment.API_CONF.CMS_API}/facilities/slug/${slug}`;
        return this.httpClient.get<any>(url);
    }

    getAllFacilitiesGlobal(): Observable<any> {
        const url = `${environment.API_CONF.CMS_API}/global/facilities?per_page=200`;
        return this.httpClient.get<any>(url);
    }

    getAllFacilityAreasGlobal(): Observable<any> {
        const url = `${environment.API_CONF.CMS_API}/global/facility-areas?per_page=200`;
        return this.httpClient.get<any>(url);
    }

    getAllFacilitiesInAreaGlobal(id: string): Observable<any> {
        const url = `${environment.API_CONF.CMS_API}/global/facilities/area/${id}`;
        return this.httpClient.get<any>(url);
    }

    getFacilityInfoByIdShort(id: string): Observable<any> {
        const url = `${environment.API_CONF.CMS_API}/global/facilities/${id}`;
        return this.httpClient.get<any>(url);
    }

    getFacilityInfoBySlugShort(slug: string): Observable<any> {
        const url = `${environment.API_CONF.CMS_API}/global/facilities/slug/${slug}`;
        return this.httpClient.get<any>(url);
    }

    getBrowserLocation(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (navigator?.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        resolve(position);
                    }, (error: GeolocationPositionError) => {
                        reject(error.message);
                    }
                );
            } else {
                reject('Geolocation is not supported by this browser.');
            }
        });
    }

}

export interface FacilityMarker {
    facilityId: string;
    lat: number;
    lng: number;
    title: string;
    icon: string;
    draggable: boolean;
    street: string;
    city: string;
    state: string;
    postalcode: string;
    email: string;
    phone: string;
    website: string;
    type: string;
    slug: string;
}


























