// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  display: inherit;
}
.modal-header h3 {
  padding-top: 24px;
  padding-bottom: 30px;
  font-size: 38px;
  line-height: 44px;
}

.btn-long {
  padding: 12px 100px;
  margin: 20px 60px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndhcm5pbmctbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtBQUNGO0FBQ0U7RUFDRSxpQkFBQTtFQUNBLG9CQUFBO0VBQ0EsZUFBQTtFQUNBLGlCQUFBO0FBQ0o7O0FBR0E7RUFDRSxtQkFBQTtFQUNBLGlCQUFBO0FBQUYiLCJmaWxlIjoid2FybmluZy1tb2RhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tb2RhbC1oZWFkZXIge1xuICBkaXNwbGF5OiBpbmhlcml0O1xuXG4gIGgzIHtcbiAgICBwYWRkaW5nLXRvcDogMjRweDtcbiAgICBwYWRkaW5nLWJvdHRvbTogMzBweDtcbiAgICBmb250LXNpemU6IDM4cHg7XG4gICAgbGluZS1oZWlnaHQ6IDQ0cHg7XG4gIH1cbn1cblxuLmJ0bi1sb25nIHtcbiAgcGFkZGluZzogMTJweCAxMDBweDtcbiAgbWFyZ2luOiAyMHB4IDYwcHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/warning-modal/warning-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAGA;EACE,mBAAA;EACA,iBAAA;AAAF;AACA,4pBAA4pB","sourcesContent":[".modal-header {\n  display: inherit;\n\n  h3 {\n    padding-top: 24px;\n    padding-bottom: 30px;\n    font-size: 38px;\n    line-height: 44px;\n  }\n}\n\n.btn-long {\n  padding: 12px 100px;\n  margin: 20px 60px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
