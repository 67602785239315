import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from '@shared/modals/modal/modal.component';
import { PopupService } from '@core/services/popup.service';
import { EAnnouncementBannerLinks, IAnnouncementBanner } from './announcement-banner.types';
import { isFullUrl } from '@core/helpers';
import { WCAGUtils } from '../../../services/helpers/wcag.utils';

declare let $: any;

@Component({
    selector: 'bw-announcement-banner',
    templateUrl: './announcement-banner.component.html',
    styleUrls: ['./announcement-banner.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AnnouncementBannerComponent implements AfterViewInit {
    sweepstakerModalRef: ModalComponent;
    _hide = false;
    @Input('announcementBanner') announcementBanner: IAnnouncementBanner;
    @Output('onModalProcessing') onModalProcessing = new EventEmitter();
    @Output('onModalStopProcessing') onModalStopProcessing = new EventEmitter();
    @Output('onFormSuccess') onFormSuccess = new EventEmitter();
    @Output('closeBanner') closeBanner = new EventEmitter();
    @ViewChild('banner') banner: ElementRef;
    onKeyPress = WCAGUtils.onKeyPress;

    constructor(
        public popupService: PopupService,
        private router: Router,
    ) {}

    onClick() {
        const linked_to = this.announcementBanner.linked_to;
        switch (linked_to) {
            case EAnnouncementBannerLinks.SWEEPS:
                this.popupService.toggleSweepstakesPopup();
                break;
            case EAnnouncementBannerLinks.LOCATIONS:
            case EAnnouncementBannerLinks.EMERGENCY_MODAL_EXTERNAL_URL:
            case EAnnouncementBannerLinks.LOCATIONS_AREA:
            case EAnnouncementBannerLinks.PT_STARTUP:
                this.redirect(this.announcementBanner.banner_url);
                break;
            case EAnnouncementBannerLinks.EMERGENCY_MODAL:
                this.popupService.toggleEmergencyModal();
                break;
            case EAnnouncementBannerLinks.WARNING_MODAL:
                this.popupService.toggleWarningModal();
                break;
            default:
                this.scrollToPlans();
                this.closeBanner.emit();
        }
    }

    redirect(url: string) {
      if (!url) {
        return;
      }

      if (isFullUrl(url)) {
        window.open(url, '_blank');
      } else {
        this.router.navigate([url]);
      }
    }

    scrollToPlans() {
        this.announcementBanner.show_banner = false;

        $('html, body').animate({
            scrollTop: $('.club-plans').offset().top - 180
        }, 1000);
    }

    hideProcessing() {
        this.onModalStopProcessing.emit();
    }

    showProcessing() {
        this.onModalProcessing.emit();
    }

    formSuccess() {
        this.onFormSuccess.emit();
    }

    hide(ev) {
        this.closeBanner.emit();
        this._hide = true;
        ev.stopPropagation();
    }

    ngAfterViewInit() {

    }
}
