import { Directive, ElementRef, HostListener, Input } from '@angular/core';


@Directive({
    selector: '[bwSetLimit]'
})
export class SetLimitDirective {
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
    @Input('bwSetLimit') data: { type: string, limit: number };

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        if (this.data) {
            const current: string = this.el.nativeElement.value;
            if ((current.length) === this.data.limit) {
                event.preventDefault();
            }
        } else {
            return;
        }
    }
}
