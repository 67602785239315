import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[ibRegExp]'
})
export class RegExpDirective implements AfterViewInit {
    // Allow Backspace, tab, end, home and to copy, cut and paste
    @Input() allowedSpecialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Meta', 'Control', 'v', 'c', 'x'];
    @Input() regexString: string = `[a-zA-Z',.-\s]`;

    private regex: RegExp;

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.regex = new RegExp(this.regexString, 'g');
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.allowedSpecialKeys.indexOf(event.key) !== -1) {
            return;
        }
        if (!String(event.key).match(this.regex)) {
            event.preventDefault();
        }
    }
}
