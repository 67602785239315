import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FacadeService } from '@core/services/core/facade.service';


@Injectable()
export class AuthGuard  {
    allowRedirection = false;
    sub: Subscription;

    constructor(public router: Router, private facadeService: FacadeService) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean | Promise<boolean> {
        if (route.url[0].path === 'signup') {
            if (this.allowRedirection) {
                return true;
            }
            if (route.queryParams && route.queryParams.email && route.queryParams.firstname && route.queryParams.lastname) {
                this.getDecryptedParams(route.queryParams);
            } else {
                this.router.navigate(['/404']);
                return false;
            }
        } else {
            return true;
        }
    }

    getDecryptedParams({ ...params }) {
        const req = {
            firstName: decodeURIComponent(encodeURIComponent(params.firstname)),
            lastName: decodeURIComponent(encodeURIComponent(params.lastname)),
            email: decodeURIComponent(encodeURIComponent(params.email))
        };
       this.sub = this.facadeService.getDecryptedParams(req).subscribe((result) => {
           this.sub.unsubscribe();
            if (result.success) {
                const route = this.router.config.find(r => r.path === 'signup');
                route.data = { email: result.data.email, firstName: result.data.firstName, lastName: result.data.lastName };
                this.allowRedirection = true;
                this.router.navigate(['/signup']);
            } else {
                this.router.navigate(['/404']);
            }
        }, ()=>{
            this.router.navigate(['/404']);
        })
    }
}
