export interface IHeaderLinksACF {
  acf: IHeaderLinks;
}
export interface IHeaderLinks {
  franchising: IHeaderLinksButton;
  free_trial: IHeaderLinksButton;
  free_trial_active: boolean;
  free_trial_settings_home_page: boolean;
  free_trial_settings_locations_page: boolean;
  free_trial_settings_master_switch: boolean;
  free_trial_settings_not_opened_clubs: boolean;
  free_trial_settings_open_clubs: boolean;
  free_trial_settings_pt_page: boolean;
  free_trial_settings_whyblink_page: boolean;
  items: IHeaderLinksItem[];
  join_now: IHeaderLinksButton;
  member_login: IHeaderLinksButton;
  theme_default: {
    buttons: IHeaderLinksRightSide;
    navigation: IHeaderLinksThemes;
  };
  theme_pages: IHeaderLinksThemePage[];
}

export interface IHeaderLinksButton {
  label: string;
  link?: string;
  omniture_tag?: string;
}

export interface IHeaderLinksThemes {
  on_scroll: IHeaderLinksOnScroll;
  on_top: IHeaderLinksOnScroll;
}

export interface IHeaderLinksRightSide {
  free_trial: IHeaderLinksThemes;
  join_now: IHeaderLinksThemes;
}

export interface IHeaderLinksOnScroll {
  background?: string;
  border?: string;
  color?: string;
}

export interface IHeaderLinksItem {
  link: IHeaderLinksItemLink;
  title: string;
}

export interface IHeaderLinksItemLink {
  omniture_tag?: string;
  target?: string | unknown[];
  type: string;
  url: string;
}

export interface IHeaderLinksThemePage {
  page: {
    name: string;
    slug: string;
  };
  theme: {
    buttons: IHeaderLinksRightSide;
    navigation: IHeaderLinksThemes;
  };
}
