import { createAction, props } from '@ngrx/store';
import {
  FacilitiesActionTypes,
  FacilityIdActionTypes,
  IFacilitiesRequest,
  IFacilityByIdRequest,
} from './facilities.actions.types';
import { IFacility } from '@models';

/* Get All Facilities */
/**
 * Load all facilities from Yext
 *
 * @example
 *  // Without options
 *  this.store.dispatch(
 *    loadFacilities({}),
 *   );
 *
 *  // With options
 *  this.store.dispatch(
 *    loadFacilities({ options: { fields: ['mainPhone'] } }),
 *   );
 */
export const loadFacilities = createAction(
  FacilitiesActionTypes.request,
  props<IFacilitiesRequest>(),
);
export const loadFacilitiesSuccess = createAction(
  FacilitiesActionTypes.requestSuccess,
  props<{IFacilitiesAction}>(),
);
export const loadFacilitiesFailure = createAction(
  FacilitiesActionTypes.requestFailure,
  props<{ error: any }>(),
);

/* Get Facility By ID */
/**
 * Load a facility by its `id` from Yext
 *
 * @example
 *  // Without options
 *  this.store.dispatch(
 *    loadFacilityById({ id: 699 }),
 *  );
 *
 *  // With options
 *  this.store.dispatch(
 *    loadFacilityById({ id: 699, options: { fields: ['mainPhone'] } }),
 *  );
 */
export const loadFacilityById = createAction(
  FacilityIdActionTypes.request,
  props<IFacilityByIdRequest>(),
);
export const loadFacilityByIdSuccess = createAction(
  FacilityIdActionTypes.requestSuccess,
  props<{ facility: { [clubID: string]: IFacility } }>(),
);
export const loadFacilityByIdFailure = createAction(
  FacilityIdActionTypes.requestFailure,
  props<{ error: any }>(),
);
