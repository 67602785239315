import { Component, DestroyRef, HostListener, Inject, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { Router } from '@angular/router';
import { constants } from '@core/const/constants';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environment';
import { PopupService } from '@core/services/popup.service';
import { isFullUrl } from '@core/helpers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'bw-header',
    templateUrl: './web-header.component.html',
    encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public options: any;
    public menuItems: any;
    public isOnTop = true;
    @Input('userPanelVisible') userPanelVisible = true;
    @Input() showMenu = false;
    @Input() checkoutMode = true;
    redirectDomain: string = environment.domain;
    trialFormVisible = false;
    sidebarVisible: boolean;
    myClub: any = null;
    visibleRightSidebar: boolean = false;
    currentPage: string;

    constructor(@Inject(DOCUMENT) private document: Document, private _popupService: PopupService, private router: Router) {
    }

    ngOnInit() {
        this.options = constants.topHeaderNavigationItems;
        this.menuItems = constants.menusHidden;
        //if (this.router.url.includes('/checkout/checkout/linden')) {
        this.trialFormVisible = true;
        //}

        this.subscribeOnSidebar()
    }
    subscribeOnSidebar() {
        const { _sidebar } = this._popupService;
        _sidebar.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onSidebarChange.bind(this))
    }

    onSidebarChange(res: boolean) {
        this.sidebarVisible = res;
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.isOnTop = number < 100;
    }

    showTrialForm() {
        this._popupService.toggleTrialForm();
    }

    sidebarToggle() {
        this._popupService.sidebarToggle();
    }

    onClickJoinNow(navigationData) {
        const { link = '', omniture_tag = '' } = navigationData.join_now || {};

        if (isFullUrl(link)) {
            window.location.href = link;
        } else {
            this.router.navigate([link], {
                queryParams: { icmp: omniture_tag },
            });
        }
    }

}


