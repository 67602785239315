export interface GTMPage {
    item: string;
    section: string;
    type: string;
}

export const GTM_PAGES: Record<string, GTMPage> = {
    'home': {
      item: 'Home',
      section: 'Homepage',
      type: 'Home page',
    },
    'personal-training': {
      item: 'Personal Training',
      section: 'PT',
      type: 'PT Page',
    },
    'virtual-fitness': {
      item: 'Virtual Fitness',
      section: 'VF',
      type: 'Virtual Fitness Page',
    },
    'pt-startup': {
      item: 'Landing Page',
      section: 'PT StartUp Session',
      type: 'PT SUS',
    },
  };