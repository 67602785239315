import { Component, OnInit, OnDestroy, ViewEncapsulation, inject, DestroyRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupService } from '@core/services/popup.service';
import { StreamService } from '@core/services/core/stream.service';
import { SpinnerService } from '@shared/components/spinner/spinner.service';
import { TrialFormPageData, IErrorMessage, IFormSuccessData, ITrialFormPageDataService } from './trial.types';
import { IFormData } from '@shared/components/form-base/form-base.types';
import { ToastService } from '@core/services/helpers/toast.service';
import { errorToast, confirmationToast } from './trial.toasts';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/store.types';
import { loadFolders } from 'src/app/store/actions/folders.actions';
import { IFacility } from '@models';
import { FacadeService } from '@core/services/core/facade.service';
import { CreateTrialResponseData, CreateTrialRequestData } from '@core/services/user.service';
import { IModal, ITermsList } from '@shared/components/terms-checkbox/terms-checkbox.types';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GTMEvent, TagManagerClickDirectiveHelper } from '@shared/directives/tag-manager.directive';
import { WcagService } from '@core/services/helpers/wcag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

declare let $: any;

/**
 * Free Trial Modal Component
 */
@Component({
  selector: 'bw-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrialComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  firstTime = true;
  subs: Subscription[] = [];
  template: string;
  trialFormData: TrialFormPageData;
  termsSelected: number;
  termsList: ITermsList[];
  submittedData: IFormData;
  createTrialData: CreateTrialRequestData;
  submittedLocation: IFacility;
  termsOfUSe: string;
  privacyPolicy: string;
  createTrialSubs$: Subscription;
  resetForm: boolean = false;
  phoneRequired: boolean = false;
  phoneNumber: string;
  optSMS: boolean = false;

  formId: string = 'trialForm';
  ariaHideTimeout: NodeJS.Timeout;
  ARIA_HIDE_TIMEOUT_MILLI: number = 700;

  public get phonePlaceholder() {
    return "Mobile Phone"  + ((this.phoneRequired) ? "" : " (Optional)");
  }

  // Variable for Jquery object - Bootstrap modal
  modal: any;

  /**
   * @constructor
   * @param {ToastService} toastService
   * @param {PopupService} _popupService
   * @param {SpinnerService} spinnerService
   * @param {StreamService} _streamService
   * @param store
   * @param {FacadeService} _facadeService
   * @param {GoogleTagManagerService} gtmService
   */
  constructor(
    private toastService: ToastService,
    private _popupService: PopupService,
    private spinnerService: SpinnerService,
    private _streamService: StreamService,
    private store: Store<AppState>,
    private _facadeService: FacadeService,
    private gtmService: GoogleTagManagerService,
    private _wcagService: WcagService
  ) { }

  /**
   * Lifecycle OnInit
   */
  ngOnInit() {
    this.modal = $(`#${this.formId}`);
    this.template = '<img class="sp" src="./assets/img/loader.svg" alt="Loading..." />';
    this.subs.push(
      this._popupService._trialForm.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.toggleTrialModal.bind(this)),
      this._streamService.freeTrialData.obs.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.getTrialFormPageDataChange.bind(this))
    );
    // Get data immediately
    if (this.firstTime) {
      this.firstTime = false;
      this._streamService.getTrialFormPageData();
    }

    // Listen to Bootstrap modal events
    this.modal
      .on('hidden.bs.modal', this.onModalHidden.bind(this))
      .on('shown.bs.modal', this.onModalShown.bind(this));

    this.store.dispatch(loadFolders({}));
  }

  /**
   * Trial Modal show callback
   * @param {boolean} show
   */
  toggleTrialModal(show: boolean) {
    this.modal.modal(show ? 'show' : 'hide');
  }

  /**
   * Trial Form data callback
   * @param {ITrialFormPageDataService} data
   */
  getTrialFormPageDataChange(data: ITrialFormPageDataService): void {
    this.trialFormData = data?.acf;
    if (this.ariaHideTimeout) {
      clearTimeout(this.ariaHideTimeout);
      this.ariaHideTimeout = null;
    }
    this.ariaHideTimeout = setTimeout(() => {
      this._wcagService.ariaHideElements('.mat-checkbox-background > svg');
    }, this.ARIA_HIDE_TIMEOUT_MILLI);
  }

  /**
   * Get title from CMS data
   * @param {TrialFormPageData} trialFormData
   * @param {string} name
   */
  getModalTitle(trialFormData: TrialFormPageData, name: string): string {
    return trialFormData?.confirmation_modal_title?.text?.replace('[name]', name);
  }

  /**
   * On Form submit event
   * @param {IFormData} data
   */
  onFormSubmit(data: IFormData): void {
    this.spinnerService.show();
    this._wcagService.announceFormStatus(this._wcagService.DEFAULT_MESSAGE_TYPES.LOADING, this.formId);

    this.submittedData = {
      checkboxTerms: data.checkboxTerms,
      email: data.email,
      firstName: data.firstName,
      gym: data.gym,
      lastName: data.lastName,
      location: data.location,
      optedSMS: data.optedSMS
    };

    this.submittedLocation = data.location;
    this.termsOfUSe = data.termsOfUse;
    this.privacyPolicy = data.privacyPolicy;

    this.createTrialData = this.getCreateTrialData(data);
    this.createTrialSubs$ = this._facadeService
      .createTrial(this.createTrialData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: this.onCreateTrialChange.bind(this),
        error: this.onCreateTrialError.bind(this),
        complete: this.onCreateTrialComplete.bind(this),
      });
  }

  /**
   * Maps the free trial body request
   * @returns {CreateTrialRequestData}
   *  The body request to create a free trial
   */
  getCreateTrialData(dataSubmitted: IFormData): CreateTrialRequestData {
    const location = this.submittedLocation;
    const data: CreateTrialRequestData = {
      ...this.submittedData,
      facilityId: location.id,
      facilityCity: location.address.city,
      facilityZip: location.address.postalCode,
      facilityState: location.address.region,
      facilityAddress: location.address.line1,
      facilityName: location.name,
      facilityPhone: location.phone,
      facilityCMSSlug: location.slug,
      termsOfUse: this.termsOfUSe,
      privacyPolicy: this.privacyPolicy,
    };

    if (this.trialFormData.phone_number_active || dataSubmitted.phone) 
      data.phoneNumber = dataSubmitted.phone;

    if (this.trialFormData.terms_and_conditions_active) data.checkboxTerms = dataSubmitted.checkboxTerms;

    return data;
  }

  /**
   * On Create Trial Form request success response
   * @param {CreateTrialResponseData} res
   */
   onCreateTrialChange(res: CreateTrialResponseData): void {
    const location = this.submittedLocation;
    this.triggerCreateTrialAnalytics(res?.data?.MoSoRoleId, this.createTrialData);
    const data: IFormSuccessData = {formData: this.submittedData, location};
    this.onFormSuccess(data);
  }

  onCreateTrialError(err: IErrorMessage): void {
    if (!err.error.message || err.error.message.includes('500')) {
      err.error.message = 'Please try again later';
    }
    err.error.accessibilityAnnouncement = err.error.message;
    this._wcagService.announceFormStatus(this._wcagService.DEFAULT_MESSAGE_TYPES.ERROR, this.formId, true, err.error.accessibilityAnnouncement);
    this.onFormError(err);
  }

  /**
   * On Create Trial Form request complete response
   */
  onCreateTrialComplete(): void {
    this.onFormComplete();
    this.submittedData = null;
    this.submittedLocation = null;
    this.createTrialData = null;
  }

  /**
   * Trigger analytics when trial form is complete
   * @param {string} MoSoRoleId
   * @param {CreateTrialRequestData} data
   */
  triggerCreateTrialAnalytics(MoSoRoleId: string, data: CreateTrialRequestData): void {
    const pageData = TagManagerClickDirectiveHelper.getBodyPageData();
    if (pageData != null) {
      this.gtmService.pushTag({
        ...TagManagerClickDirectiveHelper.getDefaultEventData(GTMEvent.Click, pageData),
        "clickType": "Free Trial Confirm",
        "state": data.facilityState,
        "firstName": data.firstName, 
        "lastName": data.lastName, 
        "email": data.email, 
        "clubId": data.facilityId,
        "memberId": MoSoRoleId,
        "phone": TagManagerClickDirectiveHelper.getGTMPhoneNumber(data.phoneNumber),
        "gym": data.gym,
        "optedSMS": data.optedSMS ? 'true' : 'false'
      });
    }
  }

  /**
   * On Form submit Success event
   * @param {IFormSuccessData} data
   */
  onFormSuccess(data: IFormSuccessData): void {
    const { location, formData } = data;
    const title = this.trialFormData?.confirmation_modal_title?.text?.replace('[name]', "");
    this.spinnerService.hide();
    
    this.toastService.setToastVisible(
      confirmationToast({
        title: `<p class='title-first-line' style="--color: ${this.trialFormData?.confirmation_modal_title?.first_line_color}">${title}</p><p class='title-second-line' style="--color: ${this.trialFormData?.confirmation_modal_title?.second_line_color}">${formData?.firstName}!</p>`,
        titleColor: '',
        subtitle: this.trialFormData?.confirmation_modal_subtitle,
        accessibilityAnnouncement: this._wcagService.DEFAULT_ANNOUNCEMENTS.SUCCESS,
        body: this.trialFormData?.confirmation_modal_body,
        workingHours: location?.hours || {},
        location,
        workingHoursTitleColor: this.trialFormData?.confirmation_modal_subtitle.color,
        workingHoursParagraphColor: this.trialFormData?.confirmation_modal_body.color,
        confirmationButtonColor: this.trialFormData?.confirmation_modal_button.color,
        confirmationButtonBackground: this.trialFormData?.confirmation_modal_button.background_color,
        modalButtonTitle: this.trialFormData?.confirmation_modal_button?.text
      })
    );
    this._popupService.toggleTrialFormConfirmation(true);
    this.resetFormState(true);
  }

  /**
   * On Form submit Error event
   * @param {IErrorMessage} err
   */
  onFormError(err: IErrorMessage): void {
    this.toastService.setToastVisible(
      errorToast(err)
    );
    this._popupService.toggleTrialFormConfirmation(true);
  }

  /**
   * * On Form submit Complete event
   */
  onFormComplete(): void {
    this._popupService.turnOffTrialForm();
    this.spinnerService.hide();
  }

  /**
   * Modal hidden event callback
   */
  onModalHidden(): void {
    this.resetFormState(true);
    this._wcagService.removeAriaOwnOfAnnouncer(this.formId);
    this._wcagService.removeStatusDiv(this.formId);
    this._popupService.turnOffTrialForm();
  }

  /**
   * Modal shown event callback
   */
  onModalShown(): void {
    this._wcagService.setAriaOwnOfAnnouncer(this.formId);
    this.resetFormState(false);
  }

  onTermsClick(modalData: IModal) {
    this.termsSelected = (this.termsSelected !== modalData.ind) ? modalData.ind : undefined;
    this.termsList = modalData.termsList;
    const links = document.querySelectorAll(".terms-link");

    const activeClass = "active";
    links.forEach((element: HTMLElement) => {
      if (this.termsSelected == undefined || this.termsSelected.toString() != element.dataset.index)
        element.classList.remove(activeClass);
      else 
        element.classList.add(activeClass);
    });
  }

  /**
   * Terms Modal close callback
   */
  onTermsClose(): void {
    this.termsSelected = undefined;
  }

  resetFormState(val: boolean): void {
    this.resetForm = val;
  }

  ngOnDestroy(): void {
    this.onTermsClose();
    this._wcagService.removeStatusDiv(this.formId);
  }

  smsOptChange(checked: boolean) {
    this.phoneRequired = checked;
  }
}
