import {Component, DestroyRef, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, inject} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {debounceTime, filter} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PopupService} from '@core/services/popup.service';
import {getRouteSlug, isFullUrl} from '@core/helpers';
import {StreamService} from '@core/services/core/stream.service';
import {UrlParams} from '@core/services/helpers/urlSerializer';
import {BannerTypes, PromoBanner} from './layout-first.models';
import {SpinnerService} from '@shared/components/spinner/spinner.service';
import {FacadeService} from '@core/services/core/facade.service';
import {IFormConfigLeadGenModal, LeadGenModalCMS} from '@shared/modals/form-modal/form-modal.interface';
import {GTMPage, GTM_PAGES} from '@core/const/GTMPages';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {ExitIntentPopupService} from '@core/services/exit-intent-popup.service';
import { AccessibilityComponent } from '@shared/components/accessibility/accessibility.component';

@Component({
  selector: 'bw-layout-first',
  templateUrl: './layout-first.component.html',
  styleUrls: ['layout-first.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutFirstComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  @ViewChild('layoutFirstContent') layoutFirstContent: ElementRef;
  @ViewChild('sidebar', { read: ElementRef }) sidebarElement: ElementRef;
  @ViewChild(AccessibilityComponent) accessibilityLib: AccessibilityComponent;
  @Input() footerOnly = false;
  @Input() menusOnly = false;
  sidebarVisible: boolean;
  scrollingAffordanceTextBlockVisible: boolean;
  scrollingAffordanceVisible: boolean;
  isScrolling = false;
  isOnTop = true;
  promoBanner: any;
  announcementBanner: any;
  emergencySettings: any;
  hideBanner = false;
  locationsBanner: any;
  ptStartupBanner: any;
  sweepstakesBanner: any;
  showLocationsPageBanner = false;
  showClubPageBanner = false;
  showHomePageBanner = false;
  showPTStartupPageBanner = false;
  showSweepstakesPageBanner = false;
  currentPage: string;
  scrollPositionSubject = new Subject<number>();
  scrollPosition = 0;
  isMobile: boolean;
  isTablet: boolean;
  footerText: string;
  myClub: any = null;
  visibleRightSidebar = false;
  formConfigLeadGenModal: IFormConfigLeadGenModal;
  showLeadGenModal: boolean;
  leadGenModalCMSData: LeadGenModalCMS;
  showAccessibility: boolean;
  private mobileBreakpoint = 767;
  private tabletBreakpoint = 1024;

  get hasBanner(): boolean {
    return this.showLocationsPageBanner
      || this.showHomePageBanner
      || this.showClubPageBanner
      || this.showPTStartupPageBanner
      || this.showSweepstakesPageBanner;
  }

  getPageProperty(prop: keyof GTMPage): string {
    if (!this.currentPage) return "";    
    const page = GTM_PAGES[this.currentPage as string];
    return page ? page[prop] : '';
  }

  get pageItem(): string {
    return this.getPageProperty('item');
  }

  get pageSection(): string {
    return this.getPageProperty('section');
  }

  get pageType(): string {
    return this.getPageProperty('type');
  }

  constructor(
    private _popupService: PopupService,
    private router: Router,
    private _streamService: StreamService,
    protected elementRef: ElementRef,
    private url: LocationStrategy,
    private spinnerService: SpinnerService,
    private _facadeService: FacadeService,
    private exitIntentPopupService: ExitIntentPopupService
  ) {
    this.getCurrentPage();
    this.checkIfMobile();
    this.checkIfTablet();
  }

  ngOnInit() {
    this.subscribeOnBanners();
    this.subscribeOnScrollPoition();
    this.subscribeOnSidebar();
    this._streamService.clubDataBySlugFull.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onClubDataBySlugChange.bind(this))
    this.getEmergencySettings();
    this.openModal();
  }

  onClubDataBySlugChange(data: any): void {
    if (data) {
      this.myClub = data[0];
    }
  }

  onClickJoinNow(navigationData) {
    const {link = '', omniture_tag = ''} = navigationData.join_now || {};

    if (isFullUrl(link)) {
      window.location.href = link;
    } else {
      this.router.navigate([link], {
        queryParams: {icmp: omniture_tag},
      });
    }
  }

  onSidebarRightChange(res: boolean): void {
    this.visibleRightSidebar = res;
  }

  sidebarToggle() {
    this._popupService.sidebarToggle();
  }


  getFooterCopy() {
    this._streamService.footerTextSettings.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onGetFooterCopySettings.bind(this));
  }

  onGetFooterCopySettings(data: any) {
    if (!data) {
      this._streamService.getFooterCopySettings();
    } else {
      const footerTextSettings = data?.acf?.footer_copy_settings;
      if (footerTextSettings) {
        const currentPageFooterCopy = this.currentPage === 'virtual-fitness' ? 'blink-app' : this.currentPage;
        const data = footerTextSettings.find(({pages}) => pages.find((page) => page.includes(currentPageFooterCopy)));
        this.footerText = data?.settings?.acf?.footer_copy_dataset;
      }
    }
  }

  @HostListener('window:resize', [])
  resize(): void {
    this.closeSidebar();
    this.checkIfMobile();
    this.checkIfTablet();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset = window.scrollY;
    const isOnTop = scrollOffset <= 0;

    if (isOnTop !== this.isOnTop) {
      this.isOnTop = isOnTop;
      this.accessibilityLib.toggleDelay();
    }

    this.scrollPositionSubject.next(scrollOffset);
  }

  onSidebarChange(res: boolean) {
    this.sidebarVisible = res;
  }

  onScrollingAffordanceTextBlockChange(res: boolean) {
    this.scrollingAffordanceTextBlockVisible = res;
  }

  onScrollingAffordanceChange(res: boolean) {
    this.scrollingAffordanceVisible = res;
  }

  checkIfMobile() {
    const isMobile = window.innerWidth <= this.mobileBreakpoint;

    if (isMobile !== this.isMobile) {
      this.isMobile = isMobile;
    }
  }

  checkIfTablet() {
    const isTablet = window.innerWidth > this.mobileBreakpoint && window.innerWidth <= this.tabletBreakpoint;

    if (isTablet !== this.isTablet) {
      this.isTablet = isTablet;
    }
  }

  getCurrentPage() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.onNavigationEnd.bind(this))
  }

  onNavigationEnd(): void {
    const leadGenModalForm: IFormConfigLeadGenModal = this.setLeadGenModalForm();
    const path = this.url.path().split('?')[0];
    this.currentPage = getRouteSlug(path);

    this._facadeService.getPageBySlugNameGlobal('lead-gen-modal')
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((cmsData) => {
      if (cmsData && cmsData.acf.status) {
        this.exitIntentPopupService.initConfiguration(cmsData.acf, this.currentPage, leadGenModalForm);
        this.leadGenModalCMSData = cmsData.acf;
        this.showLeadGenModal = this.exitIntentPopupService.showLeadGenModal;
        this.formConfigLeadGenModal = this.exitIntentPopupService.formConfigLeadGenModal;
      }
    });

    this.showLocationsPageBanner = this.doesPageHaveBanner(BannerTypes.LOCATIONS);
    this.showClubPageBanner = this.doesPageHaveBanner(BannerTypes.CLUB);
    this.showHomePageBanner = this.doesPageHaveBanner(BannerTypes.HOME);
    this.showPTStartupPageBanner = this.doesPageHaveBanner(BannerTypes.PT_STARTUP);
    this.showSweepstakesPageBanner = this.doesPageHaveBanner(BannerTypes.SWEEPSTAKES);
    this.getFooterCopy();
    this.closeSidebar();
  }

  setLeadGenModalForm(): IFormConfigLeadGenModal {
    return {
      showFirstName: true,
      showLastName: true,
      resetForm: false,
      showEmail: true,
      showTerms: true,
      termsRequired: true,
      checkboxTermsId: 'lead-gen-checkbox-terms',
      submit_button_active: {
        color: '#FFFFFF',
        background_color: '#FF5100',
      },
      submit_button_disabled: {
        color: 'rgba(0, 0, 0, 0.38)',
        background_color: '#E5E5E5',
      }
    }
  }

  closeSidebar() {
    if (this.sidebarVisible) {
      // close sidebar on resize
      this._popupService.sidebarToggle();
    }
  }

  doesPageHaveBanner(page?: BannerTypes): boolean {
    switch (page) {
      case BannerTypes.LOCATIONS:
        return (
          (this.currentPage === 'area' || this.currentPage === 'locations') &&
          this.locationsBanner?.banner_image_desktop &&
          this._streamService.getShowLocationsPageBannerGlobal()
        );
      case BannerTypes.CLUB:
        return (
          this.currentPage === 'club' &&
          this.announcementBanner?.banner_image_desktop &&
          this._streamService.getShowClubPageBannerGlobal()
        );
      case BannerTypes.HOME:
        return (
          this.currentPage === 'home' &&
          this.promoBanner?.show_banner &&
          this._streamService.getShowHomePageBannerGlobal()
        );
      case BannerTypes.PT_STARTUP:
        return (
          this.currentPage === 'pt-startup' &&
          this.ptStartupBanner?.show_banner &&
          this._streamService.getShowPTStartupPageBannerGlobal()
        );
      case BannerTypes.SWEEPSTAKES:
        return (
          this.currentPage === 'sweepstakes' &&
          this.sweepstakesBanner?.show_banner &&
          this._streamService.getShowSweepstakesPageBannerGlobal()
        );
    }
  }

  subscribeOnSidebar() {
    const {
      _sidebar,
      _sidebarRight,
      _scrollingAffordance,
      _scrollingAffordanceTextBlock
    } = this._popupService;
    _sidebar.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onSidebarChange.bind(this))
    _sidebarRight.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onSidebarRightChange.bind(this))
    _scrollingAffordanceTextBlock.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      this.onScrollingAffordanceTextBlockChange.bind(this)
    )
    _scrollingAffordance.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      this.onScrollingAffordanceChange.bind(this)
    )
  }

  subscribeOnBanners() {
    const {
      locationsBanner,
      clubAnnouncementBanner,
      homePageData,
      ptStartupBanner,
      sweepstakesBanner,
    } = this._streamService;
      sweepstakesBanner.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onSweepstakesPageData.bind(this))
      ptStartupBanner.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onPTStartupPageData.bind(this))
      locationsBanner.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onLocationsBannerChange.bind(this))
      clubAnnouncementBanner.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
        this.onClubAnnouncementBannerChange.bind(this)
      )
      homePageData.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onHomePageData.bind(this))
  }

  onLocationsBannerChange(data: any) {
    if (!data) {
      return;
    }

    this.locationsBanner = data;
    this.showLocationsPageBanner = this.doesPageHaveBanner(BannerTypes.LOCATIONS);
    this.showClubPageBanner = false;
    this.showHomePageBanner = false;
    this.showPTStartupPageBanner = false;
    this.showSweepstakesPageBanner = false;
  }

  onClubAnnouncementBannerChange(data: any) {
    this.announcementBanner = data || false;
    this.showClubPageBanner = this.doesPageHaveBanner(BannerTypes.CLUB);
    this.showLocationsPageBanner = false;
    this.showHomePageBanner = false;
    this.showPTStartupPageBanner = false;
    this.showSweepstakesPageBanner = false;
  }

  onHomePageData(data: any) {
    if (!data) {
      return;
    }

    this.promoBanner = new PromoBanner(data);
    this.showHomePageBanner = this.doesPageHaveBanner(BannerTypes.HOME);
    this.showClubPageBanner = false;
    this.showLocationsPageBanner = false;
    this.showPTStartupPageBanner = false;
    this.showSweepstakesPageBanner = false;
  }

  onPTStartupPageData(data: any) {
    if (!data) {
      return;
    }

    this.ptStartupBanner = data;
    this.showPTStartupPageBanner = this.doesPageHaveBanner(BannerTypes.PT_STARTUP);
    this.showClubPageBanner = false;
    this.showLocationsPageBanner = false;
    this.showHomePageBanner = false;
    this.showSweepstakesPageBanner = false;
  }

  onSweepstakesPageData(data: any) {
    if (!data) {
      return;
    }

    this.sweepstakesBanner = data;
    this.showSweepstakesPageBanner = this.doesPageHaveBanner(BannerTypes.SWEEPSTAKES);
    this.showPTStartupPageBanner = false;
    this.showClubPageBanner = false;
    this.showLocationsPageBanner = false;
    this.showHomePageBanner = false;
  }

  onCloseBanner() {
    switch (this.currentPage) {
      case 'locations':
        this._streamService.showLocationsPageBannerGlobal.update(false);
        this.showLocationsPageBanner = this.doesPageHaveBanner(BannerTypes.LOCATIONS);
        this._streamService.showLocationsPageBannerGlobal.update(true);
        break;
      case 'club':
        this._streamService.showClubPageBannerGlobal.update(false);
        this.showClubPageBanner = this.doesPageHaveBanner(BannerTypes.CLUB);
        this._streamService.showClubPageBannerGlobal.update(true);
        break;
      case 'home':
        this._streamService.showHomePageBannerGlobal.update(false);
        this.showHomePageBanner = this.doesPageHaveBanner(BannerTypes.HOME);
        this._streamService.showHomePageBannerGlobal.update(true);
        break;
      case 'pt-startup':
        this._streamService.showPTStartupPageBannerGlobal.update(false);
        this.showPTStartupPageBanner = this.doesPageHaveBanner(BannerTypes.PT_STARTUP);
        this._streamService.showPTStartupPageBannerGlobal.update(true);
        break;
      case 'sweepstakes':
        this._streamService.showSweepstakesPageBannerGlobal.update(false);
        this.showSweepstakesPageBanner = this.doesPageHaveBanner(BannerTypes.SWEEPSTAKES);
        this._streamService.showSweepstakesPageBannerGlobal.update(true);
        break;
    }
  }

  subscribeOnScrollPoition() {
      this.scrollPositionSubject
        .asObservable()
        .pipe(debounceTime(100))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onScrollPositionChange.bind(this));
  }

  onScrollPositionChange(scrollPosition: number) {
    // Limit is calculated with layout height minus sidebar height
    const getHeight = (obj: any = {}) =>
      obj.nativeElement && obj.nativeElement.offsetHeight;
    const sidebarHeight = getHeight(this.sidebarElement) || 600;
    const containerHeight = getHeight(this.layoutFirstContent) || 600;

    this.scrollPosition = Math.max(
      0,
      Math.min(scrollPosition, containerHeight - sidebarHeight)
    );
  }

  getEmergencySettings() {
    this._streamService.emergencySettings.obs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: any) => {
      this.emergencySettings = data;
    })
  }

  showTrialForm() {
    this._popupService.toggleTrialForm();
  }

  showEmergencyModal() {
    this._popupService.toggleEmergencyModal();
  }

  /**
   * open free-trial or, sweep modal through url param ?modal=free-trial or, &modal=sweeps
   */
  openModal(): void {
    const urlParams = new UrlParams(); // Create object to check query param
    const checkModal = urlParams.getQuery('modal');

    if (checkModal === 'free-trial') {
      this.showTrialForm();
    }

    if (urlParams.getQuery('weather-warning')) {
      this.showEmergencyModal();
    }
  }

  formSuccess() {
    this.hideBanner = true;
  }

  showProcessing() {
    this.spinnerService.show();
  }

  hideProcessing() {
    this.spinnerService.hide();
  }

  openAccessibilityModal() {
    this.accessibilityLib.openModal();
  }

  setAccessibilityToggle(isOn: boolean) {
    this.showAccessibility = isOn;
  }

  ngOnDestroy(): void {
    this.exitIntentPopupService.popupClearTimeOut();
  }
}
