import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGetGuerEmail } from '@shared/components/fill-fields-qa-btn/fill-fields-qa-btn.types';

@Injectable({
    providedIn: 'root'
})
export class FillFieldsQaBtnService {
    private _httpClient = inject(HttpClient)
    private _apiTestMail = 'https://api.guerrillamail.com/ajax.php';

    getTestEmail(): Observable<IGetGuerEmail> {
        return this._httpClient.get<IGetGuerEmail>(`${this._apiTestMail}?f=get_email_address`);
    }
}
