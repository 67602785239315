import { Component, Input } from '@angular/core';
import { IImageTextColumn } from './image-text-column.types'

@Component({
  selector: 'bw-image-text-column',
  templateUrl: './image-text-column.component.html',
  styleUrls: ['./image-text-column.component.scss']
})
export class ImageTextColumnComponent {

  @Input() content: IImageTextColumn;

}