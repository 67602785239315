import { createReducer, on } from '@ngrx/store';
import * as Actions from '@store/actions/terms-and-conditions.actions';
import {
  ITermsAndConditionsState,
  ITermsAndConditionsStateData,
} from './terms-and-conditions.reducer.types';
import { AppState } from '@store/store.types';
import { request, requestError } from './common';
import { ITermsAndConditionsAction } from '@store/actions/terms-and-conditions.actions.types';

export const initialState: ITermsAndConditionsState = {
  loading: false,
};

export const termsAndConditionsReducer = createReducer(
  initialState,
  on(Actions.loadTermsAndConditions, request),
  on(Actions.loadTermsAndConditionsSuccess, (
    state: ITermsAndConditionsState,
    { data }: ITermsAndConditionsAction,
  ): ITermsAndConditionsState => ({
    ...state,
    loading: false,
    error: null,
    data: {
      ...state.data,
      ...data,
    },
  })),
  on(Actions.loadTermsAndConditionsFailure, requestError),
);

/**
 * Gets termsAndConditions reducer
 * @param {AppState} state
 * @returns {ITermsAndConditionsState}
 *  The termsAndConditions state
 */
export const termsAndConditions = (state: AppState): ITermsAndConditionsState =>
  state.termsAndConditions;

/**
 * Gets data from termsAndConditions reducer
 * @param {AppState} state
 * @returns {IFacilities}
 *  The termsAndConditions state data
 */
export const termsAndConditionsData = (state: AppState): ITermsAndConditionsStateData =>
  state.termsAndConditions?.data;
