import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { AwsService } from './core/aws.service';
import { LoggerService } from './log4ts/logger.service';
import { MosoUser, IPromoCodeRequest } from '@models';
import { environment } from '@environment';
import { IFormData } from '@shared/components/form-base/form-base.types';
import { IEmailStatus } from '@public-pages/pages/checkout/checkout/checkout-form-v2/basic-info-section-v2/basic-info-section-v2.types';

@Injectable()
export class UserService {

  constructor(private _awsService: AwsService, private logger: LoggerService, private httpClient: HttpClient) { }

  /**
   * Get Moso User Data by member ID
   * @param memberId
   */
  getUserDataMOSO(memberId: string): Promise<any> {
    return this.httpClient.get<any>(`${environment.API_CONF.MOSO_API}/status/${memberId}`).toPromise();
  }

  /**
   * Create And Finalize Agreement
   * @param data
   */
  createAndFinalizeAgreement(data: AgreementData): Promise<any> {
    return this.httpClient.post<MosoUser>(environment.API_CONF.WEB_MOSO_API + '/purchaseMemberShip', data).toPromise();
  }

  /**
   * Create And Finalize Agreement V2
   * @param data
   */
  createAndFinalizeAgreement_v2(data: AgreementDataV2): Promise<any> {
    return this.httpClient.post<MosoUser>(environment.API_CONF.WEB_MOSO_API + '/purchaseMemberShipV2', data).toPromise();
  }

  /**
   * processSweepStakes
   * @param sweepstakes_data, facility
   * @param facility
   */
  processSweepStakes(sweepstakes_data, facility): Promise<any> {
    const data = {
      email: sweepstakes_data.email,
      firstName: sweepstakes_data.firstName,
      lastName: sweepstakes_data.lastName,
      phoneNumber: sweepstakes_data.phone,
      termsAndConditions: sweepstakes_data.termsAndConditions,
      termsOfUse: sweepstakes_data.termsOfUse,
      privacyPolicy: sweepstakes_data.privacyPolicy,
      BusinessUnitCode: facility.acf.moso_id,
      facilityState: facility.acf.address.state,
      facilityName: facility.acf.title
    };
    return this.httpClient.post(environment.API_CONF.WEB_MOSO_API + '/createSweepstakes', data).toPromise();
  }

  processLocationLeadForm(form_data: IProcessLocationLeadForm): Promise<any> {
    const data: CreateOpportunityPayload = {
      email: form_data.email,
      firstName: form_data.first_name,
      lastName: form_data.last_name,
      BusinessUnitCode: form_data.facility?.facilityId,
      facilityState: form_data.facility?.state,
      facilityName: form_data.facility?.title,
      zipCode: form_data.zipCode,
      phoneNumber: form_data.phoneNumber,
      termsOfUse: form_data.termsOfUse,
      privacyPolicy: form_data.privacyPolicy,
    };
    return this.createOpportunity(data, 'webClubInquiry').toPromise();
  }

  createWebLead(form_data: CreateOpportunityPayload): Observable<any> {
    return this.createOpportunity(form_data, 'webLead');
  }

  createWebCart(form_data: CreateOpportunityFull): Promise<any> {
    const data: CreateOpportunityPayload = {
      email: form_data.email,
      firstName: form_data.firstName,
      lastName: form_data.lastName,
      BusinessUnitCode: form_data.facilityId,
      facilityState: form_data.facilityState,
      termsOfUse: form_data.termsOfUse,
      privacyPolicy: form_data.privacyPolicy
    };
    return this.createOpportunity(data, 'webCart').pipe(retry(2)).toPromise();
  }

  createOpportunity(form_data: CreateOpportunityPayload, leadType: string): Observable<any> {
    return this.httpClient.post(environment.API_CONF.LEADS_API + `/createOpportunity?leadType=${leadType}`, form_data);
  }

  createTrial(body: CreateTrialRequestData): Observable<any> {
    return this.httpClient.post(environment.API_CONF.WEB_MOSO_API + '/createTrial', body);
  }

  createStartupMember(body: ICreateStartupRequestData): Observable<any> {
    return this.httpClient.post(`${environment.API_CONF.WEB_MOSO_API}/member/id/pt_request`, body);
  }

  getWhoischeckedin(facilityId: string): Promise<any> {
    let params = new HttpParams();
    params = params.append('facilityId', facilityId);
    return this.httpClient.get(`${environment.API_CONF.WEB_MOSO_API}/activities/whoischeckedin`, { params: params }).toPromise();
  }

  /** Validate Product Catalog promo code */
  validatePromotion(data: IPromoCodeRequest): Observable<any> {
    return this.httpClient.get(`${environment.API_CONF.VALIDATE_PROMOCODE}/active/${data.promoCode}/${data.facilityId}/${data.planName}`);
  }

  /** Apply promo code feature */
  applyPromoCode(body: IPromoCodeRequest): Observable<any> {
    return this.httpClient.post(environment.API_CONF.WEB_MOSO_API + '/promo', body);
  }

  createGympassProspect(body): Observable<any> {
    return this.httpClient.post(environment.API_CONF.WEB_MOSO_API + '/purchaseGympass', body);
  }

  getDecryptedParams(body): Observable<any> {
    return this.httpClient.post(environment.API_CONF.WEB_MOSO_API + '/gympass/decrypt', body);
  }

  checkCaptcha(captchaResponse: string) {
    const body = { response: captchaResponse };
    return this.httpClient.post(environment.API_CONF.WEB_MOSO_API + '/recaptcha', body);
  }

  /**
   * Get member status by email
   * @param {string} body The Email
   */
  getEmailMemberStatus(body: IEmailStatus): Promise<object> {
    return this.httpClient.post(`${environment.API_CONF.WEB_MOSO_API}/member/status`, body).toPromise();
  }
}

export interface AgreementData {
  firstName: string;
  lastName: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  emailAddress: string;
  emailAddressConfirm: string;
  birthDate: string;
  genderId: number;
  alternateBillingStreet1: string;
  alternateBillingStreet2: string;
  alternatebillingCity: string;
  alternateBillingState: string;
  alternateBillingZip: string;
  creditCardName: string;
  creditCardNumber: string;
  creditCardExpirationDate: string;
  cVV: string;
  iAgree: boolean;
  planID: string;
  facilityId: string;
  facilityName: string;
  facilityAddress: string;
  facilityCity: string;
  facilityState: string;
  facilityZip: string;
  facility_type: string;
  termsAndConditions: string;
  termsOfUse: string;
  privacyPolicy: string;
  promoName: string;
  promoMosoName: string;
  startDate?: string | number | Date;
  captchaResponse?: string;
}

export interface AgreementDataV2 extends IACHFileds {
  firstName: string;
  lastName: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  emailAddress: string;
  emailAddressConfirm: string;
  birthDate: string;
  genderId: number;
  alternateBillingStreet1: string;
  alternateBillingStreet2: string;
  alternatebillingCity: string;
  alternateBillingState: string;
  alternateBillingZip: string;
  creditCardName: string;
  creditCardNumber: string;
  creditCardExpirationDate: string;
  cVV: string;
  iAgree: boolean;
  planID: string;
  facilityId: string;
  facilityName: string;
  facilityAddress: string;
  facilityCity: string;
  facilityState: string;
  facilityZip: string;
  facility_type: string;
  termsAndConditions: string;
  termsOfUse: string;
  privacyPolicy: string;
  promoName: string;
  startDate?: string | number | Date;
  captchaResponse?: string;
  elapsedSeconds?: number;
}

export interface IACHFileds {
  accountHolderName?: string;
  accountType?: string;
  routingNumber?: string;
  accountNumber?: string;
}

export interface CreateTrialResponseData {
  barcodeId: string;
  data: {
    MoSoRoleId: string;
    opportunityData: {
      newTrialAgreementId: number;
      newTrialOpportunityId: number;
    };
  };
  message: string;
}

export interface CreateTrialRequestData extends IFormData {
  facilityId: string;
  facilityCity: string;
  facilityZip: string;
  facilityState: string;
  facilityAddress: string;
  facilityName: string;
  facilityPhone: string;
  facilityCMSSlug: string;
  phoneNumber?: string;
}

export interface ICreateStartupRequestData {
  facilityId: string;
  facilityEmail: string;
  facilityName: string;
  firstName: string;
  lastName: string;
  phone: string;
  memberEmail: string;
  apptTime: string;
  goals: string;
  termsOfUse: string;
  privacyPolicy: string;
  checkboxTerms?: boolean;
  checkboxCallMe?: boolean;
  region?: string;
}

export interface CreateOpportunityFull {
  billingCity: string;
  billingSame: boolean;
  billingState: string;
  billingStreetLine1: string;
  billingStreetLine2: string;
  billingZipCode: string;
  city: string;
  confirmEmail?: string;
  dateOfBirthFormatted: string;
  email: string;
  facilityId: string;
  facilityState: string;
  firstName: string;
  genderId: number;
  lastName: string;
  phone: string;
  state: string;
  streetLine1: string;
  streetLine2: string;
  zipCode: string;
  termsOfUse?: string;
  privacyPolicy?: string;
}

export interface CreateOpportunityPayload {
  email: string;
  firstName: string;
  lastName: string;
  BusinessUnitCode?: string;
  facilityState?: string;
  termsOfUse?: string;
  privacyPolicy?: string;
  phoneNumber?: string;
  facilityName?: string;
  zipCode?: string;
}

export interface IProcessLocationLeadForm {
  email: string;
  first_name: string;
  last_name: string;
  facility?: {
    facilityId: string;
    state: string;
    title: string;
  };
  zipCode: string;
  phoneNumber?: string;
  termsOfUse?: string;
  privacyPolicy?: string;
}
