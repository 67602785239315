import { Component, Input } from '@angular/core';
import { IImageBlockContent } from '@shared/components/two-blocks-layout/two-blocks-layout.types';

@Component({
  selector: 'image-by-device',
  templateUrl: './image-by-device.component.html',
  styleUrls: ['./image-by-device.component.scss'],
})

export class ImageByDeviceComponent {
  @Input() content: IImageBlockContent;
  @Input() deviceType: string;
  @Input() containerClass: string;
  @Input() imageClass: string;
  @Input() ariaHideImage: boolean = false;
}
