import { createAction, props } from '@ngrx/store';
import {
  IFacilitiesByGeoSearchRequest,
  FacilitiesGeoSearchActionTypes,
} from './facilities-by-geo.actions.types';
import { TGeoSearch } from '@store/reducers/facilities-by-geo.reducer.types';
import { IFacility } from '@models';

/* Get Facilities By Geo Search */
/**
 * Load a facilities by its `location` from Yext
 *
 * @example
 *  // Without options
 *  this.store.dispatch(
 *    loadFacilitiesByGeoSearch({ location: 'NoHo, Manhattan, Nova Iorque, NY, EUA' }),
 *  );
 *
 *  // With options
 *  this.store.dispatch(
 *    loadFacilitiesByGeoSearch({
 *      location: 'NoHo, Manhattan, Nova Iorque, NY, EUA',
 *      options: { fields: ['mainPhone'] },
 *    }),
 *  );
 */
export const loadFacilitiesByGeoSearch = createAction(
  FacilitiesGeoSearchActionTypes.request,
  props<IFacilitiesByGeoSearchRequest>(),
);
export const loadFacilitiesByGeoSearchSuccess = createAction(
  FacilitiesGeoSearchActionTypes.requestSuccess,
  props<{ IFacilitiesAction }>(),
);
export const loadFacilitiesByGeoSearchFailure = createAction(
  FacilitiesGeoSearchActionTypes.requestFailure,
  props<{ search: TGeoSearch, facilities: IFacility[], error: any }>(),
);
