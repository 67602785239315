import { Directive, HostListener, Output, EventEmitter } from '@angular/core';


@Directive({
    selector: '[layoutHostListener]',
})
export class LayoutHostListenerDirective {
    @Output() resetTimer = new EventEmitter();

    @HostListener('document:keyup', ['$event'])
    @HostListener('document:onmousemove', ['$event'])
    @HostListener('document:click', ['$event'])
    @HostListener('document:wheel', ['$event'])
    onResetTimer() {
        this.resetTimer.emit();
    }

}
