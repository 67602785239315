import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from '@environment';
import browserDetect from 'browser-detect';

const browser = browserDetect();

if (browser.name === 'edge' || browser.name === 'ie') {
    document.write(`<h3 style="max-width: 80%; display: block; margin: 40px auto">“We are having issues displaying the Blink Fitness website in your current web browser. Please use another browser such as Chrome, Firefox, or Safari to access our website. We can also help you by visiting one of our Blink fitness gym locations.”</h3>`);
} else {
    if (environment.production) {
        enableProdMode();

        /**
         * Disable console.log on production
         */
        window.console.log = () => {};
    }

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
}

