import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IOption, IFolderOption } from '@shared/components/select/select.types';

/**
 * Select Options Component
 * It's a list of options that can have children with same behavior
 *
*/
@Component({
  selector: 'bw-select-options',
  templateUrl: './select-options.component.html',
  styleUrls: ['./select-options.component.scss']
})
export class SelectOptionsComponent {
  @Output() onOptionClick = new EventEmitter<IOption>();
  @Output() onFolderClick = new EventEmitter<IFolderOption>();
  @Input() selected: IOption;
  @Input() options: IOption[];
  @Input() level = 0;

  /**
   * Option click event that selects the active option
   *
   * @param {IOption} option
   */
  onOptionClickFn(option: IOption): void {
    this.onOptionClick.emit(option);
  }

  /**
   * Folderption click event that opens more options
   *
   * @param {IFolderOption} option
   */
  onFolderClickFn(option: IFolderOption): void {
    this.onFolderClick.emit(option);
  }
}
