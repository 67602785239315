import {Component, OnInit, ViewEncapsulation, Input, inject, DestroyRef} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {PopupService} from '@core/services/popup.service';
declare let $: any;

@Component({
    selector: 'bw-emergency-modal',
    templateUrl: './emergency-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./emergency-modal.component.scss'],
})
export class EmergencyModalComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    @Input() emergencySettings: any;
    constructor(private _popupService: PopupService) {}

    ngOnInit() {
        this._popupService._emergencyModal
        .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
          (res: boolean) => $('.emergency-modal').modal(res ? 'show' : 'hide')
        );

        $('.emergency-modal').on('hidden.bs.modal', () => {
            this._popupService.turnOffEmergencyModal();
        });
    }
}
