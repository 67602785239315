import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Shared Module import **/
import { SharedModule } from '@shared/shared.module';

/** Custom Created Layouts **/
import { SimpleLayoutComponent } from './layout/simple-layout.component';
import { LayoutFirstComponent } from './layout/layout-first.component';

/** Custom Created Navigations Components **/
import { LeftHiddenSideBarComponent } from './navigation/left-hidden-side-bar.component';
import { WebsiteFooterTermsBannerComponent } from './navigation/footer/website-footer-terms-banner.component';
import { HeaderComponent } from './navigation/header/web-header.component';
import { WebFooterComponent } from './navigation/web-footer/web-footer.component';

import { PromoBannerComponent } from './layout/promo-banner/promo-banner.component';
import { AnnouncementBannerComponent } from './layout/announcement-banner/announcement-banner.component';
import { PromoBannerItemComponent } from './layout/promo-banner/promo-banner-item/promo-banner-item.component';
import { FooterTextComponent } from './navigation/footer-text/footer-text.component';

/** Blink Navigation Components **/
import { environment } from '@environment';
import { BlinkNavigationComponentsAngularModule } from '@blinkfitness/navigation-components';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        BlinkNavigationComponentsAngularModule.forRoot({
            production: environment.production,
            API_CONF: {
                CMS_API: environment.API_CONF.CMS_API,
                X_API_KEY: environment.API_CONF.X_API_KEY,
            },
            blinkWebsiteDomain: environment.domain,
            iblinkDomain: environment.iblinkDomain,
        }),
    ],
    declarations: [
        SimpleLayoutComponent,
        LayoutFirstComponent,
        HeaderComponent,
        LeftHiddenSideBarComponent,
        WebsiteFooterTermsBannerComponent,
        PromoBannerComponent,
        AnnouncementBannerComponent,
        PromoBannerItemComponent,
        FooterTextComponent,
        WebFooterComponent
    ],
    exports: [
        HeaderComponent,
        LeftHiddenSideBarComponent,
        WebsiteFooterTermsBannerComponent,
        WebFooterComponent
    ]
})
export class LayoutModule {
}
