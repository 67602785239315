import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[bwLazyLoad]'
})
/**
 * This directive is for <img>.
 * It checks whether the <img> is in viewport when page loads
 * If not in viewport, it lazy loads the image and reduces LCP time
 */
export class LazyLoadImageDirective implements OnInit {
    constructor(private el: ElementRef) {}
    ngOnInit(): void {
        if (!this.isElementInViewport(this.el)) {
            this.el.nativeElement.setAttribute('loading','lazy');
        }
    }

    isElementInViewport(el: ElementRef): boolean {
        const rect = el.nativeElement.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}
