import { AbstractControl } from '@angular/forms';
import { FacadeService } from '@core/services/core/facade.service';

export class EmailValidation {

    constructor(public facadeService: FacadeService) {}

    static MatchEmail(AC: AbstractControl) {
        const email = AC.get('email').value;
        const confirmEmail = AC.get('confirmEmail').value;

        if (email !== confirmEmail) {
            AC.get('confirmEmail').setErrors({ MatchEmail: true });
        } else {
            return null;
        }
    }
}
