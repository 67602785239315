import { createReducer, on } from '@ngrx/store';
import { sortBy, mergeWith } from 'lodash-es';
import { IFacility, IFacilitiesStates, IFacilities } from '@models';
import * as Actions from '@store/actions/facilities-by-region.actions';
import * as ActionsAll from '@store/actions/facilities.actions';
import { IFacilitiesByRegionState } from './facilities-by-region.reducer.types';
import { IFacilitiesByRegionAction } from '@store/actions/facilities-by-region.actions.types';
import { AppState } from '@store/store.types';
import { request, requestError } from './common';
import { IFacilitiesAction } from '@store/actions/facilities.actions.types';

/**
 * Initial state for the reducer.
 * That's the value when page loads.
 */
export const initialState: IFacilitiesByRegionState = {
  loading: false,
};

/**
 * It gets two arrays with objects
 * and merge the arrays also merging child objects
 * @param {IFacility[]} arr1
 * @param {IFacility[]} arr2
 * @returns {IFacility[]}
 *  A merge of the two params
 */
const mergeCustomizer = (
  arr1: IFacility[] = [],
  arr2: IFacility[] = [],
): IFacility[] => {
  const arrays = sortBy([...arr1, ...arr2], 'name');
  const mergedArrays = arrays
    .reduce(
      (acc, curr) =>
        acc.set(curr?.id, Object.assign(acc.get(curr?.id) || {}, curr)),
      new Map(),
    )
    .values();

  return [...mergedArrays];
};

/**
 * Split facilities by Region
 * @param {IFacilitiesStates} state
 * @param {IFacilities} facilities
 */
function mergeFacilityIntoRegion(
  state: IFacilitiesStates,
  facilities: IFacility[],
): IFacilitiesStates {
  const regions = {};

  for (const key in facilities) {
    if (facilities[key]) {
      const facility = facilities[key];
      const region = regions[facility?.address?.region || ''] || [];

      regions[facility?.address?.region || ''] = [...region, facility];
    }
  }

  return { ...state, ...regions };
}

/**
 * It merges new values with current state
 * @param {IFacilitiesByRegionState} state
 * @param {IFacilitiesAction} action
 * @returns {IFacilitiesByRegionState}
 *  The facilitiesByRegion state
 */
const mergeAllFacilities = (
  state: IFacilitiesByRegionState,
  { facilities }: IFacilitiesAction,
): IFacilitiesByRegionState => ({
  ...state,
  loading: false,
  error: null,
  data: mergeFacilityIntoRegion(state.data, facilities),
});

/**
 * It merges new values with current state
 * @param {IFacilitiesByRegionState} state
 * @param {IFacilitiesByRegionAction} action
 * @returns {IFacilitiesByRegionState}
 *  The facilitiesByRegion state
 */
const mergeFacilities = (
  state: IFacilitiesByRegionState,
  { facilities }: IFacilitiesByRegionAction,
): IFacilitiesByRegionState => ({
  ...state,
  loading: false,
  error: null,
  data: mergeWith({ ...state.data }, facilities, mergeCustomizer),
});

/**
 * Facilities By State Reducer
 */
export const facilitiesByRegionReducer = createReducer(
  initialState,
  /* Facilities */
  on(ActionsAll.loadFacilities, request),
  on(ActionsAll.loadFacilitiesSuccess, mergeAllFacilities),
  on(ActionsAll.loadFacilitiesFailure, requestError),
  /* Facilities By Region */
  on(Actions.loadFacilitiesByRegion, request),
  on(Actions.loadFacilitiesByRegionSuccess, mergeFacilities),
  on(Actions.loadFacilitiesByRegionFailure, requestError),
  /* Facilities By City */
  on(Actions.loadFacilitiesByCity, request),
  on(Actions.loadFacilitiesByCitySuccess, mergeFacilities),
  on(Actions.loadFacilitiesByCityFailure, requestError),
);

/**
 * Gets facilitiesByRegion reducer
 * @param {AppState} state
 * @returns {IFacilitiesByRegionState}
 *  The facilitiesByRegion state
 */
export const facilitiesByRegion = (state: AppState): IFacilitiesByRegionState =>
  state.facilitiesByRegion;

/**
 * Gets data from facilitiesByRegion reducer
 * @param {AppState} state
 * @returns {IFacilitiesStates}
 *  The facilitiesByRegion state data
 */
export const facilitiesByRegionData = (state: AppState): IFacilitiesStates =>
  state.facilitiesByRegion?.data;
