import redirectionJson from '../../../locations-redirects.json';
export interface IRedirection {
    path: string;
    href: string;
}

const locationsPaths: IRedirection[] = redirectionJson?.locationsRedirections || [];

export function getRedirections(): IRedirection[] {
  const redirections: IRedirection[] = [
    {
      path: '/faq',
      href: 'https://blinkfitness.zendesk.com/hc/en-us'
    },
    {
      path: '/locations',
      href: '/search'
    },
    {
      path: '/locations/',
      href: '/search'
    },
    {
      path: '/locations/locations',
      href: '/search'
    },
    {
      path: '/capacity',
      href: '/'
    },
  ];
  locationsPaths.forEach(item => {
    redirections.push({
      href: item.href,
      path: `/locations${item.path}`,
    });
    redirections.push({
      href: item.href,
      path: `/capacity${item.path}`,
    });
  });

  return redirections;
}

export function getLocalRedirections(): IRedirection[] {
  const redirections: IRedirection[] = [
    {
      path: '/blinkapp',
      href: '/virtual-fitness'
    },
    {
      path: '/try-blink',
      href: '/'
    },
    {
      path: '/join',
      href: '/locations'
    },
    {
      path: '/sweepstakes',
      href: '/'
    },
    {
      path: '/ny/bronx/3000-jerome-avenue',
      href: '/capacity'
    },
    {
      path: '/location',
      href: '/locations'
    },
    {
      path: '/termsmobile',
      href: '/terms'
    },
    {
      path: '/privacymobile',
      href: '/privacy'
    },
    {
      path: '/try-blink',
      href: '/'
    },
    
  ]

  return redirections;
}
