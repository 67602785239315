export const goalsList = [{
    label: 'Lose Weight',
    value: 'Lose Weight',
  },
  {
    label: 'Improve Strength',
    value: 'Improve Strength',
  },
  {
    label: 'Boost Confidence',
    value: 'Boost Confidence'
  },
  {
    label: 'Stay Active',
    value: 'Stay Active',
    selected: true,
  },
  {
    label: 'Upcoming Event',
    value: 'Upcoming Event'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const timeList = [{
    label: 'am',
    ariaLabel: 'AM',
    icon: 'ico-blink-morn-sun',
    value: 'Morning'
  },
  {
    label: 'noon',
    icon: 'ico-blink-sun',
    value: 'Afternoon'
  },
  {
    label: 'pm',
    ariaLabel: 'PM',
    icon: 'ico-blink-moon',
    value: 'Evening'
  },
  {
    label: 'anytime',
    value: 'Anytime'
  },
];
