import { IPromotionDetail } from "./plans";

/**
 * Plan class
 */
export class Plan {
  constructor(
    public name?: string,
    public agreementId?: number,
    public price?: number,
    public agreementPrice?: number,
    public color?: {
      primary: string;
      secondary: string;
    },
    public startupFee?: number,
    public taxes?: number,
    public discount?: number,
    public titles?: {
      accessType: string;
      markdownFooterContent?: {
        html: string
      }
    },
    public fundingSource?: string,
    public promotion?: IPromotionDetail,
    public startDate?: string,
    public unitPrice?: string,
    public bundleId?: string,
    public itemName?: string,
    public itemQuantity?: number
  ) {}

  /**
   * Get total charged amount value
   * @returns
   */
  getTotal(): number {
    return this.agreementPrice + this.discount + this.startupFee + this.taxes;
  }
}
