import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { replace } from 'lodash-es';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const replaceUrl = url.split('?', 1)[0];
        url = replace(url, replaceUrl, replaceUrl.toLowerCase());
        return super.parse(url);
    }
}


/**
 *
 * check if url has required params
 */

export class UrlParams extends DefaultUrlSerializer {
    getQuery(q: string): any {
        return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [null, null])[1];
    }
}
