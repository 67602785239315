import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ICarouselNavigation, ITwoBlocksComponentCarouselCMSACF } from "./two-blocks-layout.types";
import { Subscription } from 'rxjs';
import { ILocationSearchBar } from '@public-pages/pages/personal-training/personal-training.types';

declare let window: Window;

@Component({
  selector: 'bw-two-blocks-layout',
  templateUrl: './two-blocks-layout.component.html',
  styleUrls: ['./two-blocks-layout.component.scss'],
  animations: [
    trigger('leftToRightAnimation', [
      state('started', style({
        transform: 'translateX(-10px)',
        opacity: 0
      })),
      state('finished', style({
        transform: 'translateX(0px)',
        opacity: 1
      })),
      transition('started=>finished', animate('200ms')),
    ]),
    trigger('rightToLeftAnimation', [
      state('started', style({
        transform: 'translateX(10px) ',
        opacity: 0
      })),
      state('finished', style({
        transform: 'translateX(0px)',
        opacity: 1
      })),
      transition('started=>finished', animate('200ms')),
    ]),
    trigger('textFade', [
      state('started', style({
        opacity: '0',
      })),
      state('finished', style({
        opacity: '1',
      })),
      transition('started=>finished', animate('500ms')),
    ]),
  ]
})

export class TwoBlocksComponent {

  @Input() isCarousel = false;
  @Input() carouselNavigation: ICarouselNavigation;
  @Input() content: ITwoBlocksComponentCarouselCMSACF;
  @ViewChild('blockWrapper') blockWrapper: ElementRef;
  @Input() deviceType: string;
  @Input() locationSearchBar: ILocationSearchBar;

  @Output('carouselAction') carouselAction = new EventEmitter();
  subs: Subscription[] = [];
  prerenderMeta: HTMLLinkElement;
  window: Window = window;
  leftToRight = "finished";
  rightToLeft = "finished";
  textState = "finished";
  componentMode: string;
  buttonMin = 1;
  buttonMax = 10;
  tabletBreakpoint = 768;
  mobileBreakpoint = 425;
  copyCharactersLimit = 100;

  constructor() { }

  public get left() {
    if (this.content) return this.content.left_block_content;
  }

  public get right() {
    if (this.content) return this.content.right_block_content;
  }

  public get nav() {
    if (this.content) return this.carouselNavigation.styling.carousel_navigation;
  }

  public get dots() {
    if (this.content) return this.carouselNavigation.styling.carousel_dots;
  }

  public get placeholder() {
    return this.locationSearchBar?.placeholder_accessibility_text ?? "Search by City, Zip or State";
  }

  onCarouselClick(action: 'prev' | 'next') {
    this.carouselAction.emit(action);
  }

  swipeCarousel(action: 'prev' | 'next', event) {
    event.preventDefault();
    this.textState = "started";
    setTimeout(() => { this.textState = "finished" }, 1);

    if (action === 'next') {
      this.leftToRight = "started";
      setTimeout(() => { this.leftToRight = "finished" }, 1);
    } else {
      this.rightToLeft = "started";
      setTimeout(() => { this.rightToLeft = "finished" }, 1);
    }

    this.carouselAction.emit(action);
  }

  counter(i: number) { return new Array(i); }

  setButtonLimit(copy: string, hasFrame: boolean) {
    const isMobile = window.innerWidth <= this.mobileBreakpoint;
    const isCopyLarge = copy.length >= this.copyCharactersLimit;

    return isCopyLarge && hasFrame && isMobile ? this.buttonMin : this.buttonMax;
  }

  getTitleEmphasisAriaLabel(data){
    return (data?.title.title ?? '') +' '+ (data?.title.title_emphasis ? data?.title.title_emphasis_copy : '')
  }

}
