import { IFacility } from '@models';
import { IBaseAction, IRequestYextOptions } from './common.types';
import { TGeoSearch } from '@store/reducers/facilities-by-geo.reducer.types';

export enum FacilitiesActionTypes {
  request = '[Facilities] Load Facilities',
  requestSuccess = '[Facilities] Load Facilities Success',
  requestFailure = '[Facilities] Load Facilities Failure',
}
export enum FacilityIdActionTypes {
  request = '[Facilities] Load Facility By ID',
  requestSuccess = '[Facilities] Load Facility By ID Success',
  requestFailure = '[Facilities] Load Facility By ID Failure',
}

export interface IFacilitiesAction extends IBaseAction {
  facilities?: IFacility[];
  facility?: IFacility;
  search?: TGeoSearch;
}

export type IFacilitiesRequest = IRequestYextOptions;
export interface IFacilityByIdRequest extends IRequestYextOptions {
  id: string;
}
