// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `bw-youtube-player,
youtube-player {
  display: block;
}

youtube-player {
  background: black;
}
youtube-player iframe {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInlvdXR1YmUtcGxheWVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOztFQUVFLGNBQUE7QUFDRjs7QUFFQTtFQUNFLGlCQUFBO0FBQ0Y7QUFDRTtFQUNFLFdBQUE7RUFDQSxZQUFBO0FBQ0oiLCJmaWxlIjoieW91dHViZS1wbGF5ZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJidy15b3V0dWJlLXBsYXllcixcbnlvdXR1YmUtcGxheWVyIHtcbiAgZGlzcGxheTogYmxvY2s7XG59XG5cbnlvdXR1YmUtcGxheWVyIHtcbiAgYmFja2dyb3VuZDogYmxhY2s7XG5cbiAgaWZyYW1lIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/youtube-player/youtube-player.component.scss"],"names":[],"mappings":"AAAA;;EAEE,cAAA;AACF;;AAEA;EACE,iBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AACA,wiBAAwiB","sourcesContent":["bw-youtube-player,\nyoutube-player {\n  display: block;\n}\n\nyoutube-player {\n  background: black;\n\n  iframe {\n    width: 100%;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
