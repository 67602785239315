import {
  ValidationErrors,
  AsyncValidator,
  AbstractControl,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import { from, Observable, ObservableInput, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BriteverifyService } from '@core/services/briteverify.service';
import { IBriteVerify } from '@shared/interfaces/briteverify.types';

/**
 * Brite Verify Validator
 */
@Injectable({ providedIn: 'root' })
export class BriteVerifyValidator implements AsyncValidator {
  /**
   * @constructor
   * @param {BriteverifyService} _briteVerify
   */
  constructor(private _briteVerify: BriteverifyService) {}

  /**
   * Validates field with Brite Verify service
   *
   * @param {FormControl} control
   * @returns {Observable<{ Briteverify: true }>}
   *  An observable with Brite Verify error
   */
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    let value = control.value;
    let data: ObservableInput<any>;
    
    // Validate email
    /* eslint-disable-next-line */
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      data = this._briteVerify.validateEmailBriteVerify(value);
      return this.mapData(data);
    }
    
    // Validate phone
    value = control.value.replace(/[^0-9]/g, ''); // format value for phone validation
    data =  this._briteVerify.validatePhoneBriteVerify(value);
    return this.mapData(data);
  }

  /**
   * Map data getting from service
   * @param {ObservableInput} data 
   * @returns {Observable<{ Briteverify: true }>} Brite Verify result
   */
  mapData(data: ObservableInput<any>): Observable<ValidationErrors | null> {
    return from(data).pipe(
      map((res: IBriteVerify) => (!res?.valid ? { Briteverify: true } : null)),
      catchError(() => of(null))
    );
  }
}
