import { get } from 'lodash-es';
import { ConfirmationToast, IErrorMessage } from '@shared/modals/free-trial/trial.types';

/**
 * The modal options to the confirmation toast,
 * it will be consumed by toastService
 * @param {ConfirmationToast} toastOptions
 */
export const confirmationToast = ({
  title,
  titleColor,
  body,
  footer,
  buttonText,
  buttonLink,
  gtmEventOnConfirm,
  gtmEventOnClose,
  buttonAccessibilityText,
  accessibilityAnnouncement
}: ConfirmationToast) => ({
  visible: true,
  options: {
    modalHtmlTitle: title,
    confirmationModalTitleColor: titleColor,
    modalMessage: `<p class="free-trial-body" style="color:${body?.color}">${body?.text}</p>`,
    isHideButton: false,
    modalButtonTitle: buttonText, 
    modalButtonAccessibilityText: buttonAccessibilityText, 
    modalButtonRedirectUrl: buttonLink,
    modalClass: 'form-modal-popup',
    modalFooter: `<p class="free-trial-body" style="color:${footer?.color}">${footer?.text}</p>`,
    gtmEventOnConfirm: gtmEventOnConfirm, 
    gtmEventOnClose: gtmEventOnClose,
    accessibilityAnnouncement: accessibilityAnnouncement
  }
});

/**
 * The modal options to the error toast,
 * it will be consumed by toastService
 * @param {IErrorMessage | string} error
 */
export const errorToast = (error: IErrorMessage | string) => ({
  visible: true,
  options: {
    modalMessage: get(error, 'error.message', error),
    modalButtonTitle: 'OK',
    modalClass: 'payment-pop-up pay-required-pop',
    modalButtonClass: 'btn btn-orange text-uppercase BlinkFitness_Balanced pull-right',
    accessibilityAnnouncement: get(error, 'error.accessibilityAnnouncement', 'Form submission failed')
  }
});
