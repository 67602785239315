/**
 * MOSO User Data Model (BlinkUserMoso)
 * */
export class MosoUser implements IMosoUser {
    ReferredByRoleId?: number;
    Cards: Cards[];
    EnforceRequiredFields: boolean;
    Addresses: Addresses[];
    PhoneNumbers: PhoneNumbers[];
    EmailAddresses: EmailAddresses[];
    Characteristics: Characteristics[];
    MembershipAgreements: MembershipAgreements[];
    RoleState: string;
    Status: string;
    Balance: number;
    CreditLimit: number;
    DefaultClientAccountId: number;
    ClientAccountIds: number[];
    PartyId: number;
    Location: Location;
    ProfilePicImageBase64: string;
    ProfilePicUrl: string;
    RoleId: string;
    Name: string;

    constructor() {
    }
}

export interface IMosoUser {
    ReferredByRoleId?: number;
    Cards: Cards[];
    EnforceRequiredFields: boolean;
    Addresses: Addresses[];
    PhoneNumbers: PhoneNumbers[];
    EmailAddresses: EmailAddresses[];
    Characteristics: Characteristics[];
    MembershipAgreements: MembershipAgreements[];
    RoleState: string;
    Status: string;
    Balance: number;
    CreditLimit: number;
    DefaultClientAccountId: number;
    ClientAccountIds: number[];
    PartyId: number;
    Location: Location;
    ProfilePicImageBase64: string;
    ProfilePicUrl: string;
    RoleId: string;
    Name: string;
}


/**
 * Cards Data Model (Cards)
 * */
export interface Cards {
    CardId: string;
    Id: number;
    Status: string;
}

/**
 * Addresses Data Model (Addresses)
 * */
export interface Addresses {
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    StateCode: string;
    PostalCode: string;
    CountryCode: string;
    Type: string;
    TypeId: string;
    Purposes: Purposes[];
}

/**
 * PhoneNumbers Data Model (PhoneNumbers)
 * */
export interface PhoneNumbers {
    PhoneNumber: string;
    CountryCode: string;
    AdditionalInformation?: string;
    TextMessageOk?: boolean;
    Type?: string;
    TypeId: number;
    Purposes?: Purposes[];
}

/**
 * EmailAddresses Data Model (EmailAddresses)
 * */
export interface EmailAddresses {
    EmailAddress: string;
    OptOut: boolean;
    Invalid: boolean;
    Type: string;
    TypeId: number;
    Purposes: Purposes[];
}


/**
 * Purposes Data Model (Purposes)
 * */
export interface Purposes {
    Name: string;
    ContactPurposeTypeId: number;
    Status: boolean;
}

/**
 * Purposes Data Model (Purposes)
 * */
export interface Characteristics {
    CharacteristicTypeId: number;
    CharacteristicTypeName: string;
    Value: string;
    CharacteristicValueId: number;
}

/**
 * MembershipAgreements Data Model (MembershipAgreements)
 * */
export interface MembershipAgreements {
    MemberAgreementId: number;
    AgreementId: number;
    AgreementName: string;
    MemberAgreementStatus: string;
    EditableStartDate: Date;
    ObligationDate: Date;
    CancellationDate?: Date;
    Location: Location;
}

export interface Location {
    Code: string;
    Name: string;
}










