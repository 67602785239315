import { get } from 'lodash-es';
import { ConfirmationToast, IErrorMessage } from './trial.types';

/**
 * The modal options to the confirmation toast,
 * it will be consumed by toastService
 * @param {ConfirmationToast} toastOptions
 */
export const confirmationToast = ({
  location,
  title,
  subtitle,
  body,
  workingHours,
  accessibilityAnnouncement,
  workingHoursTitleColor,
  workingHoursParagraphColor,
  confirmationButtonColor,
  confirmationButtonBackground,
  modalButtonTitle
}: ConfirmationToast) => ({
  visible: true,
  accessibilityAnnouncement: accessibilityAnnouncement,
  options: {
    modalClass: "trial-modal-success-form",
    modalHtmlTitle: title,
    modalButtonTitle,
    workingHours: workingHours,
    workingHoursTitleColor,
    workingHoursParagraphColor,
    confirmationButtonColor,
    confirmationButtonBackground,
    modalMessage: `<p class="free-trial-subtitle" style="--color: ${subtitle?.color}">${subtitle?.text}</p>
    <p class="free-trial-body" style="--color: ${body?.color}">${body?.text}</p>
    <p class="free-trial-subtitle" style="--color: ${subtitle?.color}">Redeem at:</p>
    <p class="free-trial-address" style="--color: ${body?.color}">${location?.name}</p>
    <p class="free-trial-address" style="--color: ${body?.color}">${location?.address?.line1}</p>
    <p class="free-trial-address" style="--color: ${body?.color}">${location?.address?.city}, ${location?.address?.region} ${location?.address?.postalCode}</p>
    <p class="free-trial-address" style="--color: ${body?.color}">${location?.phone}</p>`,
  }
});

/**
 * The modal options to the error toast,
 * it will be consumed by toastService
 * @param {IErrorMessage | string} error
 */
export const errorToast = (error: IErrorMessage | string) => ({
  visible: true,
  options: {
    modalMessage: get(error, 'error.message', error),
    modalButtonTitle: 'OK',
    modalClass: 'payment-pop-up pay-required-pop trial-config-popup',
    modalButtonClass: 'btn btn-orange text-uppercase BlinkFitness_Balanced pull-right',
    accessibilityAnnouncement:'Form submission failed'
  }
});
