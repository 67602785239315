import { Component, DestroyRef, ElementRef, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { CookiesService } from '@core/services/helpers/cookies.service';
import { StreamService } from '@core/services/core/stream.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

declare const $: any;

@Component({
  selector: 'bw-footer-terms-banner',
  templateUrl: './website-footer-terms-banner.component.html',
  styleUrls: ['./website-footer-terms-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WebsiteFooterTermsBannerComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private sub1: Subscription;
  termsStatus: string;
  pageData: any;

  constructor(
      private _cookiesService: CookiesService,
      private _streamService: StreamService,
      private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
      this.getTcNotice();
  }

  getTcNotice() {
      if (this.sub1 && !this.sub1.closed) { return; }
      this.sub1 = this._streamService.tcNotice.obs.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((res: any) => {
              if (!res) {
                  this._streamService.getTcNotice();
              } else {
                  this.setShowBannerCookie(new Date(res.acf.banner_expiration_date));
                  this.pageData = res;
                  setTimeout(() => {
                      if (this.elementRef.nativeElement.getElementsByClassName('terms_link').length > 0) {
                          this.elementRef.nativeElement.getElementsByClassName('terms_link')[0].addEventListener('click', (event) => {
                              event.preventDefault();
                              $('#terms-popup').modal('show');
                          });
                          this.elementRef.nativeElement.getElementsByClassName('privacy_link')[0].addEventListener('click', (event) => {
                              event.preventDefault();
                              $('#policy-popup').modal('show');
                          });
                      }
                  }, 500);
              }
          });
  }

  /**
   * Logic to define cookie value and expiration date
   * @param {Date} expDate The expiration date for the banner and cookie
   */
  setShowBannerCookie(expDate: Date) {
    this.termsStatus =
        new Date() >= expDate
            ? 'hide'
            : this._cookiesService.getCookieByName('terms_notification') || 'show';
    
    this._cookiesService.setCookie('terms_notification', this.termsStatus, expDate);
  }

  hideTermsNotificationPanel(): void {
      const expDate = new Date(this.pageData.acf.banner_expiration_date);
      this.termsStatus = 'hide';
      this._cookiesService.setCookie('terms_notification', this.termsStatus, expDate);
  }
}
