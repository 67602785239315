import { ITermsAndConditionsStateData } from '@store/reducers/terms-and-conditions.reducer.types';
import { IBaseAction } from './common.types';

export enum TermsAndConditionsActionTypes {
  request = '[Terms And Conditions] Load',
  requestSuccess = '[Terms And Conditions] Load Success',
  requestFailure = '[Terms And Conditions] Load Failure',
}

export interface ITermsAndConditionsAction extends IBaseAction {
  data: ITermsAndConditionsStateData;
}

export interface ITermsAndConditionsRequest {
  id: string;
  slug: string;
}
