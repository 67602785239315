import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { facilitiesReducer } from './facilities-reducer';
import { facilitiesByRegionReducer } from './facilities-by-region.reducer';
import { facilitiesByGeoReducer } from './facilities-by-geo.reducer';
import { foldersReducer } from './folders.reducer';
import { termsAndConditionsReducer } from './terms-and-conditions.reducer';
import { environment } from '@environment';
import { AppState } from '@store/store.types';

export const reducers: ActionReducerMap<AppState> = {
  facilities: facilitiesReducer,
  facilitiesByRegion: facilitiesByRegionReducer,
  facilitiesByGeo: facilitiesByGeoReducer,
  folders: foldersReducer,
  termsAndConditions: termsAndConditionsReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
