export interface IPromoCodeDetails {
  discountAmount: number;
  discountMonth: number;
  discountType: string;
  promotionMosoName: string;
  promotionType: string;
  startupFee?: number;
  startupFeeTax?: number;
}

export class PromoCodeDetails implements IPromoCodeDetails {
  discountAmount: number;
  discountMonth: number;
  discountType: string;
  promotionMosoName: string;
  promotionType: string;
  startupFee: number;
  startupFeeTax: number;

  constructor(data: any) {
    this.discountAmount = data.discountAmount;
    this.discountMonth = data.discountMonth;
    this.discountType = data.discountType;
    this.promotionMosoName = data.promotionMosoName;
    this.promotionType = data.promotionType;
    this.startupFee = data?.startupFee;
    this.startupFeeTax = data?.startupFeeTax;
  }
}

export interface IPromoCodeRequest {
  facilityId?: string;
  fundingSource?: string;
  planId?: string;
  planName?: string;
  promoCode?: string;
}
