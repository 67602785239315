import { Component, Input } from '@angular/core';
import { IAlternatedRowsCMSACF, IMainTitle, IPageGeneral, IRows, IRowContent } from './alternated-rows-layout.types';

@Component({
  selector: 'bw-alternated-rows-layout',
  templateUrl: './alternated-rows-layout.component.html',
  styleUrls: ['./alternated-rows-layout.component.scss']
})
export class AlternatedRowsLayoutComponent {

  @Input() content: IAlternatedRowsCMSACF;
  @Input() section: string = 'home';
  @Input() deviceType: string;

  // Mobile view
  desktopBreakpoint: number = 768;

  constructor() {
  }

  public get title(): IMainTitle {
    return this.content ? this.content.main_title : null
  }

  public get general(): IPageGeneral {
    return this.content ? this.content.general : null
  }

  public get rows(): IRows {
    return this.content ? this.content.rows : null
  }

  backgroundColor(row: IRowContent, prop: string): string {
    return this.isDesktop() ? 'transparent' : row[prop];
  }

  generalBGColor(general: any): string {
    return this.isDesktop() ? general.background_color : general.background_color_mobile;
  }

  isDesktop(): boolean {
    return window.innerWidth >= this.desktopBreakpoint;
  }
}
