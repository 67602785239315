import { environment } from '@environment';

export type ValidTagName = 'head' | 'body';

export enum ScriptNames {
    FRIENDBUY = 'friendbuy'
}

export interface Script {
    name: ScriptNames;
    content: string;
    replacements: Array<{ value: string; replaceWith: string; }>;
    tagName: string;
    parentElement: ValidTagName;
    type: string;
    async: boolean;
}

export const scripts: Array<Script> = [
    {
        name: ScriptNames.FRIENDBUY,
        content: `
            window["friendbuyAPI"] = friendbuyAPI = window['friendbuyAPI'] || [];
            friendbuyAPI.merchantId = '{merchantCode}';
            friendbuyAPI.push(['merchant', friendbuyAPI.merchantId]);
        
            (function (f, r, n, d, b, u, y) {
                while ((u = n.shift())) {
                    (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
                    b.async = 1;
                    b.src = u;
                    y.parentNode.insertBefore(b, y);
                }
            })(document, 'script', [
                'https://static.fbot.me/friendbuy.js',
                'https://campaign.fbot.me/' + friendbuyAPI.merchantId + '/campaigns.js',
            ]);
        `,
        replacements: [
            { value: '{merchantCode}', replaceWith: environment.FRIENDBUY_MERCHANT_ID }
        ],
        tagName: 'script',
        parentElement: 'head',
        type: 'child',
        async: false
    },
]
