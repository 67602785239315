import { Component, Input } from '@angular/core';

@Component({
  selector: 'bw-responsive-image',
  templateUrl: './responsive-image.component.html',
  styleUrls: ['./responsive-image.component.scss']
})
export class ResponsiveImageComponent {
  @Input() desktop = '';
  @Input() tablet = '';
  @Input() mobile = '';
}
