import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { IYextSettings } from '@models';

@Injectable()
export class CmsService {

    constructor(private httpClient: HttpClient) {

    }

    getPostBySlugName(slug: string): Observable<any> {
        if (!slug) { return; }
        return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/posts/slug/${slug}`);
    }

    getPageBySlugName(slug: string): Observable<any> {
        if (!slug) { return; }
        return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/pages/slug/${slug}`);
    }

    getDataSetBySlugName(slug: string): Observable<IYextSettings> {
        if (!slug) { return; }
        return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/data-sets/slug/${slug}`);
    }

    getPageBySlugNameGlobal(slug: string): Observable<any> {
        if (!slug) { return; }
        return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/global/pages/slug/${slug}`);
    }

    getDataSetBySlugNameGlobal(slug: string): Observable<any> {
      if (!slug) { return; }
      return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/global/data-sets/slug/${slug}`);
    }

    getDatasetByBenefitId(id: number): Observable<any> {
        return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/data-sets/${id}`);
    }

    getGlobalCMSDataByTypeAndId(req: { type: string, id: number }): Observable<any> {
        if (!req || !req.id) { return; }
        return this.httpClient.get<any>(`${environment.API_CONF.CMS_API}/global/${req.type}/${req.id}`);
    }

  /**
   * Get Term of use, Privacy policy, PT Terms and Conditions, and others
   * from CMS by its slug.
   * The slug is found on Yext club data;
   *
   * @param {string} slug
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getTermsBySlug(slug: string): Observable<any> {
    if (!slug) {
      return;
    }

    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/club-terms`,
      { params: { slug } },
    );
  }

  /**
   * Get Privacy policy
   * from CMS by its slug.
   * The slug is found on Yext club data;
   *
   * @param {string} slug
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getPrivacy(slug: string): Observable<any> {
    if (!slug) {
      return;
    }

    return this.httpClient.get(
      `${environment.API_CONF.CMS_API}/global/club-terms`,
      { params: { slug } },
    );
  }
}



























