import { Component, Input } from '@angular/core';
import { IPromiseFeaturesCMSACF } from '@shared/components/promise-features/promise-features.types'

@Component({
  selector: 'bw-promise-features',
  templateUrl: './promise-features.component.html',
  styleUrls: ['./promise-features.component.scss']
})
export class PromiseFeaturesComponent {

  @Input() content: IPromiseFeaturesCMSACF;

}
