/**
 * Contains the funding source type
 */
export class FundingSource {
  isACH: boolean;
  isCC: boolean;

  constructor(data: string) {
    this.isACH = data === 'ACH';
    this.isCC = data === 'CC';
  }
}
