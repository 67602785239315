import { UntypedFormControl } from '@angular/forms';

function creditCardPattern(control: UntypedFormControl) {
  const number = control.value;
  let re = new RegExp('^4');

  if (number?.match(re) !== null) {
    return null;
  }
  if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
    return null;
  }
  // AMEX
  re = new RegExp('^3[47]');
  if (number?.match(re) !== null) {
    return null;
  }
  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number?.match(re) !== null) {
    return null;
  }

  return {
    cardValidator: { number: number },
  };
}

export default creditCardPattern;