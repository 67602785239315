import { Injectable } from '@angular/core';
import { LoggerService } from '@core/services/log4ts/logger.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class CookiesService {

    /**
     * CookiesService
     * @param logger
     * @param cookieService
     */
    constructor(private logger: LoggerService, private cookieService: CookieService) {

    }

    /**
     * Set Cookie by providing name, etc...
     * @param name
     * @param value
     * @param expires
     * @param path
     * @param domain
     * @param secure
     */
    setCookie(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean): void {
        this.cookieService.set(name, value, expires, path, domain, secure);
    }

    /**
     * Get Cookie By Name
     * @param name
     */
    getCookieByName(name: string): string {
        return this.cookieService.get(name);
    }

    /**
     * Get All Cookie
     */
    getAllCookie(): {[key: string]: string} {
        return this.cookieService.getAll();
    }

    /**
     * Delete Cookie By providing Name
     * @param name
     * @param path
     * @param domain
     */
    deleteCookieByName(name: string, path = '/', domain = '.blinkfitness.com'): void {
        return this.cookieService.delete(name, path, domain);
    }

    /**
     * Delete All Cookie
     */
    deletedeleteAll(): void {
        return this.cookieService.deleteAll();
    }

}
