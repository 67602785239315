/**
 * General id
 * */
export class PopupOptions implements IPopupOptions {
    //buttonTitle?: string;
    //buttonColor?: string;
    modalTitle?: string;
    modalMessage?: string | any;
    modalButtonTitle?: string;
    modalClass?: string;
    //modalButtonColor?: string;
    //closeModalOnClickButton?: string | any;
    constructor() {
    }
}

export interface IPopupOptions {
    buttonTitle?: string;
    buttonColor?: string;
    modalTitle?: string;
    modalTitleClass?: string;
    modalMessage?: string | any;
    modalButtonTitle?: string;
    modalButtonColor?: string;
    closeModalOnClickButton?: string | any;
}
