import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';

/**
 * Briteverify Service
 */
@Injectable()
export class BriteverifyService {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient) { }

  validateEmailBriteVerify(value: string): Observable<any> {
    return this.validateBriteVerify('email', value);
  }
  
  validatePhoneBriteVerify(value: string): Observable<any> {
    return this.validateBriteVerify('phone', value);
  }

  /**
   * Requests Brite Verify verification
   * @param {string} key
   * @param {string} value
   * @returns {Observable<any>}
   */
  validateBriteVerify(key: string, value: string): Observable<any> {
    const param = {};
    param[key] = value;
    return this.httpClient.post(`${environment.API_CONF.WEB_MOSO_API}/verify`,param);
  }
}
