import { Injectable } from '@angular/core';
import { IHoursDay, IHours, IOpenInterval } from '@models';
import { capitalize } from 'lodash-es';

/**
 * Working Hours Service
 */
@Injectable()
export class WorkingHoursService {
  private WEEK_DAYS_COUNT = 7;
  private workingHoursObject: IHours;

  /**
   * Sets the hour
   * @param {IHours} data
   *  Hours from Yext
   * @param {boolean} merge
   *  Should be merge the days if it has equal hours
   *
   * @returns {IHoursDay[]}
   *  A mapped list of club available hours
   *
   * @example
   *  this.workingHoursService.setHours(hoursObj, true) -->
   *    Mon - Fri       06:00 - 21:00
   *    Sat - Sun       07:00 - 16:00
   *
   *  this.workingHoursService.setHours(hoursObj, false) -->
   *    Mon             06:00 - 21:00
   *    Tue             06:00 - 21:00
   *    Wed             06:00 - 21:00
   *    Thu             06:00 - 21:00
   *    Fri             06:00 - 21:00
   *    Sat             07:00 - 16:00
   *    Sun             07:00 - 16:00
   */
  getHoursList(data: IHours, merge: boolean = true): IHoursDay[] {
    if (!data) {
      console.error('Error. Please check the working hours');
      return [];
    }

    this.workingHoursObject = data;

    return this.mergeDates(merge);
  }

  /**
   * Maps the Week Days and merge them if necessary
   * @param {boolean} merge
   * @param {number} _startIndex
   * @param {number} _nextIndex
   * @param {IHoursDay[]} arr
   */
  private mergeDates(
    merge: boolean,
    _startIndex = 0,
    _nextIndex = 1,
    arr: IHoursDay[] = [],
  ): IHoursDay[] {
    let startIndex = _startIndex;
    let nextIndex = _nextIndex;
    const startDate = this.getDate(startIndex);
    const startName = this.getName(startIndex);
    const nextDate = this.getDate(nextIndex);
    const nextName = this.getName(nextIndex);

    // if current and previous dates are equal:
    if (this.isEquivalent(startDate, nextDate) && merge) {
      arr = this.cleanUpPreviousResults(capitalize(startName), arr);
      arr.push({
        ...startDate,
        name: this.concatNames(startName, nextName),
      });
    } else {
      // if current and previous dates are NOT equal:
      // if this is the first iteration:
      if (!arr?.length) {
        arr.push({
          ...startDate,
          name: capitalize(startName),
        });
      }

      arr.push({
        ...nextDate,
        name: capitalize(nextName),
      });

      startIndex = nextIndex;
    }

    nextIndex += 1;

    if (nextIndex < this.WEEK_DAYS_COUNT) {
      // run the next iteration
      return this.mergeDates(merge, startIndex, nextIndex, arr);
    }

    return arr;
  }

  /**
   * Clear equal values from array
   * @param {number} startName
   * @param {IHoursDay[]} _arr
   */
  private cleanUpPreviousResults(startName: string, _arr: IHoursDay[]): IHoursDay[] {
    const arr = _arr.slice();
    const index = arr.findIndex(item => item.name.includes(startName));

    if (index !== -1) {
      arr.splice(index, 1);
    }

    return arr;
  }

  /**
   * Concat week days names
   * @param {string} name1
   * @param {string} name2
   */
  private concatNames(_name1: string, _name2: string): string {
    const name1 = this.removeDash(_name1, 'first');
    const name2 = this.removeDash(_name2, 'last');

    return `${capitalize(name1)} - ${capitalize(name2)}`;
  }

  /**
   * Remove Dash from string
   * @param {string} name
   * @param {'first' | 'last'} action
   * @example
   *  this.workingHoursService.removeDash('mon-tue', 'first')
   *    ---> 'mon'
   *  this.workingHoursService.removeDash('mon-tue', 'last')
   *    ---> 'tue'
   */
  private removeDash(name: string, action: 'first' | 'last'): string {
    const dashIndex = name.indexOf('-');

    if (dashIndex === -1) {
      return name;
    }

    if (action === 'first') {
      return name.substring(0, dashIndex);
    }

    return name.substring(dashIndex, 0);
  }

  /**
   * Get date from weekday object
   * @param {numner} index
   * @param {IHours} object
   */
  private getDate(index: number, object: IHours = this.workingHoursObject): IHoursDay {
    return object[Object.keys(object)[index]];
  }

  /**
   * Get weekday name
   * @param {numner} index
   * @param {IHours} object
   */
  private getName(index: number, object: IHours = this.workingHoursObject): string {
    return Object.keys(object)[index]?.substring(0, 3) || '';
  }

  /**
   * Check for the equivalency for the two dates
   * @param {IHoursDay} a
   * @param {IHoursDay} b
   */
  private isEquivalent(a: IHoursDay, b: IHoursDay): boolean {
    const mapping = (interval: IOpenInterval) => `${interval.start} - ${interval.end}`;
    const aDates = a.openIntervals?.map(mapping);
    const bDates = b.openIntervals?.map(mapping);

    if (aDates?.length !== bDates?.length) {
      return false;
    }

    return !aDates.some((interval, index) => interval !== bDates[index]);
  }

  /**
   * Convert time string from 12h to 24h
   * @param {string} time12h
   */
  convertTime12to24(time12h: string): string {
    if (!time12h) {
      return '';
    }
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm') {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return `${hours}:${minutes}`;
  }
}
