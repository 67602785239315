declare const $;

export const messagesOptions = {
    requestError: {
        visible: true,
        options: {
            modalTitle: '',
            modalMessage: 'Looks like there was an error. Take a deep breath and try again?',
            modalButtonTitle: 'OK',
            modalClass: 'error-pop-up',
            modalButtonClass: 'btn btn-orange text-uppercase BlinkFitness_Balanced pull-right'
        }
    },
    corporateModalSuccess: {
        visible: true,
        options: {
            modalClass: "corporate-modal-success-form",
            modalTitle: 'Thanks!',
            modalButtonTitle: 'Got it',
            modalMessage: `We'll be in touch`,
        }
    },
    corporateModalError: {
        visible: true,
        options: {
            modalClass: "corporate-modal-success-form",
            modalTitle: 'oops!',
            modalButtonTitle: 'OK',
            modalMessage: 'Something went wrong. Please try Later.',
        }
    },
    requestSweepstakesError: (msg) => ({
        visible: true,
        options: {
            modalTitle: '',
            modalMessage: msg ? msg : 'Hold on! It looks like you are already entered to win a membership at this Blink. You will be notified if you are the winner!',
            modalButtonTitle: 'OK',
            modalClass: 'error-pop-up',
            modalButtonClass: 'btn btn-orange text-uppercase BlinkFitness_Balanced pull-right'
        }
    }),
    marketingBlockVideo: (video) => ({
        visible: true,
        options: {
            modalTitle: '',
            modalMessage: `
                <vg-player>
                    <video style="width:100%;max-height:500px;" vgMedia="media" #media id="singleVideo" preload="auto" controls>
                        <source src="${video}" type="video/mp4">
                    </video>
                </vg-player>
            `,
            modalButtonTitle: 'OK',
            onHide: () => {
                $('.modal-body video').get(0).pause();
            },
            modalClass: 'marketing-block-video',
            modalButtonClass: 'btn btn-orange text-uppercase BlinkFitness_Balanced pull-right'
        }
    }),
};