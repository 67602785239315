import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoggerService } from './log4ts/logger.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { retry } from 'rxjs/operators';

@Injectable()
export class PopupService {
    public _showCreditCardPopUp: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showCreditCardPopUp: Observable<boolean> = this._showCreditCardPopUp.asObservable();

    public _leadGen: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public leadGen: Observable<boolean> = this._leadGen.asObservable();

    public _trialForm: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public trialForm: Observable<boolean> = this._trialForm.asObservable();

    public _trialFormConfirmation: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public trialFormConfirmation: Observable<boolean> = this._trialFormConfirmation.asObservable();

    public _trialButtonClubPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public trialButtonClubPage: Observable<boolean> = this._trialButtonClubPage.asObservable();

    public _trialButtonWhyBlinkPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public trialButtonWhyBlinkPage: Observable<boolean> = this._trialButtonWhyBlinkPage.asObservable();

    public _warningModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public warningModal: Observable<boolean> = this._warningModal.asObservable();

    public _emergencyModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public emergencyModal: Observable<boolean> = this._emergencyModal.asObservable();

    public _sweepstakesPopup: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public sweepstakesPopup: Observable<boolean> = this._sweepstakesPopup.asObservable();

    public _locationLeadPopup: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public locationLeadPopup: Observable<boolean> = this._locationLeadPopup.asObservable();

    public _corporateModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public corporateModal: Observable<boolean> = this._corporateModal.asObservable();

    public _contactForm: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public contactForm: Observable<boolean> = this._contactForm.asObservable();

    public _sidebar: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public sidebar: Observable<boolean> = this._sidebar.asObservable();

    public _sidebarRight: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public sidebarRight: Observable<boolean> = this._sidebarRight.asObservable();

    public _scrollingAffordanceTextBlock: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public scrollingAffordanceTextBlock: Observable<boolean> = this._scrollingAffordanceTextBlock.asObservable();

    public _scrollingAffordance: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public scrollingAffordance: Observable<boolean> = this._scrollingAffordance.asObservable();

    public _dialogTriggerDismissal: BehaviorSubject<any> = new BehaviorSubject({ active: false });
    public dialogTriggerDismissal: Observable<any> = this._sidebar.asObservable();

    private popupStateOpen: boolean

    constructor(private router: Router, private logger: LoggerService, private httpClient: HttpClient) {

    }

    setPopupVisible(val: any) {
        if ((<any>this.router.url) === '/iblink/billing') {
            this._showCreditCardPopUp.next(val);
        } else {
            this.router.navigate(['/iblink/billing']);
            this._showCreditCardPopUp.next(val);
        }
    }

    isTrialFormOpened(): boolean {
        return this._trialForm.getValue() || this._trialFormConfirmation.getValue();
    }

    toggleTrialForm(): void {
        const val = this._trialForm.getValue();
        this._trialForm.next(!val);
    }

    toggleLeadGen(): void {
        const val = this._leadGen.getValue();
        this._leadGen.next(!val);
    }

    toggleTrialButtonClubPage(val: boolean): void {
        this._trialButtonClubPage.next(val);
    }

    toggleTrialButtonWhyBlinkPage(val: boolean): void {
        this._trialButtonWhyBlinkPage.next(val);
    }

    toggleTrialFormConfirmation(val: boolean): void {
        this._trialFormConfirmation.next(val);
    }

    turnOffTrialForm(): void {
        this._trialForm.next(false);
    }

    turnOffLeadGen(): void {
        this._leadGen.next(false);
    }

    toggleWarningModal(): void {
        const val = this._warningModal.getValue();
        this._warningModal.next(!val);
    }

    turnOffWarningModal(): void {
        this._warningModal.next(false);
    }

    toggleEmergencyModal(): void {
        const val = this._emergencyModal.getValue();
        this._emergencyModal.next(!val);
    }

    turnOffEmergencyModal(): void {
        this._emergencyModal.next(false);
    }

    toggleSweepstakesPopup(): void {
        const val = this._sweepstakesPopup.getValue();
        this._sweepstakesPopup.next(!val);
    }

    turnOffSweepstakesPopup(): void {
        this._sweepstakesPopup.next(false);
    }

    toggleLocationLeadPopup(): void {
        const val = this._locationLeadPopup.getValue();
        this._locationLeadPopup.next(!val);
    }

    turnOffLocationLeadPopup(): void {
        this._locationLeadPopup.next(false);
    }

    toggleCorporateModal(): void {
        const val = this._corporateModal.getValue();
        this._corporateModal.next(!val);
    }

    turnOffCorporateModal(): void {
        this._corporateModal.next(false);
    }

    toggleContactForm(): void {
        const val = this._contactForm.getValue();
        this._contactForm.next(!val);
    }

    sidebarToggle(): void {
        const val = this._sidebar.getValue();
        this._sidebar.next(!val);
    }

    sidebarRightToggle(): void {
        const val = this._sidebarRight.getValue();
        this._sidebarRight.next(!val);
    }

    scrollingAffordanceToggle(val: boolean): void {
        this._scrollingAffordance.next(val);
    }

    scrollingAffordanceTextBlockToggle(val: boolean): void {
        this._scrollingAffordanceTextBlock.next(val);
    }

    showDialogTriggerDismissal(club: any, locationsData: any) {
        this._dialogTriggerDismissal.next({
            active: true,
            formVisible: true,
            facilityId: club[0].id,
            facilityState: club[0].acf.address.state,
            facilityName: club[0].title.rendered,
            dialog_trigger_dismissal_subtitle: locationsData.dialog_trigger_dismissal_subtitle,
            dialog_trigger_dismissal_thank_you_text: locationsData.dialog_trigger_dismissal_thank_you_text,
            dialog_trigger_dismissal_title: locationsData.dialog_trigger_dismissal_title,
            type: club[0].acf.type
        });
    }

    closeDialogTriggerDismissal() {
        this._dialogTriggerDismissal.next({
            active: false
        });
    }

    submitDialogTriggerDismissal(data: any) {
        const options = this._dialogTriggerDismissal.getValue();
        const body = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            BusinessUnitCode: options.facilityId,
            facilityState: options.facilityState,
            facilityName: options.facilityName
        };
        const url = environment.API_CONF.LEADS_API + '/createOpportunity?leadType=webSweepstakes';
        return this.httpClient.post(url, body).pipe(
            retry(1)
        );
    }

    showDialogTriggerDismissalThankYou() {
        const oldData = this._dialogTriggerDismissal.getValue();
        const newData = { ...oldData, active: true, formVisible: false };
        this._dialogTriggerDismissal.next(newData);
    }

    setPopupStateOpen(state: boolean): void {
        this.popupStateOpen = state;
    }

    isPopupOpen(): boolean {
        return this.popupStateOpen;
    }
}
