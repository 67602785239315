import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { AriaLabelTranslatorService } from '@core/services/helpers/aria-label-translator.service';
import { WorkingHoursService } from '@core/services/working-hours.service';
import { IHours, IHoursDay } from '@models';

@Component({
  selector: 'bw-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingHoursComponent implements OnChanges {
  @Input() title: string;
  @Input() workingHours: IHours;
  @Input() merge = false;
  @Input() format: 'HH' | 'LT' = 'LT';
  @Input() titleColor: string;
  @Input() paragraphColor: string;
  hours: IHoursDay[];

  /**
   * @constructor
   * @param {WorkingHoursService} workingHoursService
   */
  constructor(
    private workingHoursService: WorkingHoursService,
    public ariaLabelTranslatorService: AriaLabelTranslatorService
  ) {}

  /**
   * Lifecycle OnChanges
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.workingHours?.currentValue) {
      this.setWorkingHours(changes.workingHours.currentValue);
    }
  }

  /**
   * Sets the list of hours
   * @param {IHours} workingHours
   */
  setWorkingHours(workingHours: IHours) {
    this.hours = this.workingHoursService.getHoursList(workingHours);
  }
}
