import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StreamService } from '@core/services/core/stream.service';
import { isFullUrl } from '@core/helpers';
import { environment } from '@environment';

@Component({
    selector: 'bw-promo-banner',
    templateUrl: './promo-banner.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PromoBannerComponent implements OnInit {

    @Input('promoBanner') promoBanner: any;
    @Output('closeBanner') closeBanner = new EventEmitter();
    sizes = ['desktop', 'tablet', 'mobile'];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private _streamService: StreamService,
    ) {}

    ngOnInit() {
    }

    redirect() {
        const url = this.promoBanner && this.promoBanner.promo_banner_url ?
            this.promoBanner.promo_banner_url : environment.domainLocations;

            if (isFullUrl(url)) {
            this.document.location.href = url;
        } else {
            this.router.navigate([url], { queryParams: { icmp: 'hp_banner_join_now' } });
        }
    }

    onCloseBanner() {
        this.closeBanner.emit();
    }
}


