import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, retry} from 'rxjs/operators';
import {Plan, IFacility, IPriceFacility} from '@models';
import {environment} from '@environment';

/**
 * Plans Service
 */
@Injectable()
export class PlanService {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Checks if it's a valid plan
   * @param {string} planName
   */
  hasPlan(planName: string): boolean {
    return ['green', 'gray', 'blue', 'orange', 'alumni-blue'].indexOf(planName?.toLowerCase()) > -1;
  }

  /**
   * Request plans on CMS
   * @param {string} moso_id
   */
  getMembershipPlansByFacilityId(moso_id: string): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.API_CONF.PRICING_API}/getPriceByFacility?BusinessUnitCode=${moso_id}`);
  }

  /**
   * Maps plans fetched from CMS
   * @param {string} _planName
   * @param {IFacility} facility
   */
  mapPlans(_planName: string, facility: IFacility) {
    return (result: IPriceFacility) => {
      const agreementPrices = result?.result?.Item?.agreementPrices;

      if (!agreementPrices) {
        return;
      }
      if (_planName === 'alumni-blue') {
        _planName = 'Alumni Blue';
      }

      const planName = _planName.toLowerCase();
      const planNameCapitalized = capAllLetters(planName);
      const plan = agreementPrices[planNameCapitalized];

      const blueNoCommit = facility?.planOptions?.includes('BLUE_NON-COMMIT');
      const plans = {
        green: {
          name: 'Green',
          colors: {primary: '#00A676', secondary: '#008f00'},
          accessType: 'All-Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[0],
        },
        gray: {
          name: 'Gray',
          colors: {primary: '#435362', secondary: '#363d42'},
          accessType: 'Single-Club Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[2],
        },
        /**
         * pricingMarkdownDisclaimers[1] is for plan Blue.
         * pricingMarkdownDisclaimers[4] is for plan Blue No Commitment.
         */
        blue: {
          name: 'Blue',
          colors: {primary: '#095CD3', secondary: '#0455c2'},
          accessType: 'Select-Access',
          markdownFooter:  (!blueNoCommit) ? facility?.pricingMarkdownDisclaimers[1] : facility?.pricingMarkdownDisclaimers[4] || facility?.pricingMarkdownDisclaimers[1],
        },
        'alumni blue': {
          name: 'Alumni Blue',
          colors: {primary: '#095CD3', secondary: '#0455c2'},
          accessType: 'Select-Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[5] || facility?.pricingMarkdownDisclaimers[1],
        },
        orange: {
          name: 'Orange',
          colors: {primary: '#ef4836', secondary: '#c1210f'},
          accessType: 'Single-Club Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[3],
        },
      };

      const selectedPlan = plans[planName];

      const newPlan = new Plan(
        selectedPlan.name,
        plan.AgreementId,
        plan?.PromoPrice,
        plan?.AgreementPrice,
        selectedPlan.colors,
        plan?.ItemPrice,
        plan?.taxTransaction,
        plan?.adjustmentPromotionFeeDiscount,
        {
          accessType: selectedPlan.accessType,
          markdownFooterContent: selectedPlan.markdownFooter,
        },
      );
      return newPlan;
    };
  }

  /**
   * Fetch plans by `facility.id` and maps it
   * @param planName
   *  Plan name
   * @param {IFacility} facility
   */
  getPlan(planName: string, facility: IFacility, fundingSource? : string): Promise<Plan> {

    if (!this.hasPlan(planName)) {
      //TODO REDIRECT TO ERROR PAGE

      return Promise.resolve(
        new Plan('?', 0, 0, 0, {
          primary: '#bbb',
          secondary: '#999'
        }, 0, 0, 0, {
          accessType: 'All-Access',
          markdownFooterContent: { html: ''}
        })
      );
    }

    return this.getMembershipPlansByFacilityId(facility?.id)
      .pipe(
        retry(3),
        map(this.mapPlans(planName, facility))
      )
      .toPromise();
  }
}

const capAllLetters = (st: string): string => {
  const stArray = st.split(' ');
  for (let i = 0; i < stArray.length; i++) {
    stArray[i] = stArray[i][0].toUpperCase() + stArray[i].substr(1);
  }
  return stArray.join(' ');
};
