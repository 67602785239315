/**
 * User Data Model (User)
 * */
export class User implements IUser {
    givenName?: string;
    familyName?: string;
    phone?: string;
    email?: string;
    password?: string;
    repassword?: string;
    barcode?: string;
    username?: string;

    constructor() {
    }
}

/**
 * iUser Data Model (IUser)
 * */
export interface IUser {
    givenName?: string;
    familyName?: string;
    phone?: string;
    email?: string;
    password?: string;
    repassword?: string;
    barcode?: string;
    username?: string;
}

export const genders = [
    { id: 1, value: 'Man' },
    { id: 2, value: 'Woman' },
    { id: 3, value: 'Prefer not to say' },
    { id: 28, value: 'Non-binary' },
]







