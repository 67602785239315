import { NgModule, ModuleWithProviders } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { SpinnerComponent } from './spinner.component';

export * from './spinner.service';
export * from './spinner.component';

@NgModule({
  imports: [],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
  providers: [SpinnerService]
})
export class SpinnerModule {
  static forRoot(): ModuleWithProviders<SpinnerModule> {
    return {
      ngModule: SpinnerModule,
      providers: [SpinnerService]
    };
  }
}
