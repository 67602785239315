export type TModalSizes = 'sm' | 'lg' | 'md';

export interface IModalData {
  modalHtmlTitle: string;
  modalClass: string;
  modalMessage: string;
  modalButtonTitle: string;
  modalButtonClass: string;
  modalLinks?: string;
}
