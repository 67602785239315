import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';
import { WcagService } from '@core/services/helpers/wcag.service';

@Directive({
    selector: '[bwWcag]'
})
/**
 * This directive is for implementing general wcag rules on elements
 */
export class WcagDirective implements AfterViewInit {
    constructor(private el: ElementRef,
                private _wcagService: WcagService) {}

    ngAfterViewInit(): void {
        this._wcagService.ariaHideInnerElementsOfHeadings(this.el);
        this._wcagService.ariaHideInnerElementsOfButtons(this.el);
        this._wcagService.ariaAddNewTabWarningForAnchors(this.el);
    }
}
