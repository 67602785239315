import {Component, DestroyRef, Input, OnDestroy, OnInit, inject} from '@angular/core';
import {IFormData} from '@shared/components/form-base/form-base.types';
import {IModal, ITermsList} from '@shared/components/terms-checkbox/terms-checkbox.types';
import {Subscription} from 'rxjs';
import {PopupService} from '@core/services/popup.service';
import {SpinnerService} from '@shared/components/spinner/spinner.module';
import {FacadeService} from '@core/services/core/facade.service';
import {ToastService} from '@core/services/helpers/toast.service';
import {confirmationToast} from './form-modal.toasts';
import {PrepopulatedTrialData} from '@core/helpers';
import {LeadGenModalCMS} from './form-modal.interface';
import { addDays, format } from 'date-fns';
import { CreateOpportunityPayload } from '@core/services/user.service';
import { WcagService } from '@core/services/helpers/wcag.service';
import { GTMEvent, GTMLeadGenFormConfirmProperties, GTMLeadGenFormProperties, TagManagerClickDirectiveHelper } from '@shared/directives/tag-manager.directive';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as Helpers from '@core/helpers';
declare let $: any;

@Component({
  selector: 'bw-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  @Input() formConfig: any;
  @Input() cmsData: LeadGenModalCMS;
  createWebLeadSubs$: Subscription;
  template: string;
  leadGenData: any;
  termsSelected: number;
  termsList: ITermsList[];
  resetForm = false;
  formData: IFormData;
  mosoRoleId: string;
  closeBeforeJoin: boolean = true;
  // Variable for Jquery object - Bootstrap modal
  modal: any;

  formId = 'form-modal';

  /**
   * @constructor
   * @param {ToastService} _toastService
   * @param {PopupService} _popupService
   * @param {SpinnerService} _spinnerService
   * @param {FacadeService} _facadeService
   * @param {GoogleTagManagerService} gtmService
   *
   */
  constructor(
    private _popupService: PopupService,
    private _spinnerService: SpinnerService,
    private _facadeService: FacadeService,
    private _toastService: ToastService,
    private _wcagService: WcagService,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    
    this.modal = $(`#${this.formId}`);
    this.template = '<img class="sp" src="./assets/img/loader.svg" alt="Loading..." />';
    this._popupService._leadGen.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.toggleLeadGenlModal.bind(this))

    this.modal
      .on('hidden.bs.modal', this.onModalHidden.bind(this))
      .on('shown.bs.modal', this.onModalShown.bind(this));
   
    this.leadGenData = {
      content_title : {
        color : this.cmsData.title_color,
        text : this.cmsData.dont_leave_appearance ? this.cmsData.dont_leave_configuration.header : this.cmsData.title
      },
      content_body : {
        color : this.cmsData.color_subtitle,
        text : this.cmsData.dont_leave_appearance ? this.cmsData.dont_leave_configuration.body : this.cmsData.subtitle
      }
    }
  }
  /**
  * Trial Modal show callback
  * @param {boolean} show
  */
  toggleLeadGenlModal(show: boolean): void {
    if (show){
      this.sentGTMEvent("Open");
    }
    this.modal.modal(show ? 'show' : 'hide');
  }

  /**
  * Modal hidden event callback
  */
  onModalHidden(): void {
    if (this.closeBeforeJoin){
      this.sentGTMEvent("Close");
    }
    this.resetFormState(true);
    this._wcagService.removeAriaOwnOfAnnouncer(this.formId);
    this._wcagService.removeStatusDiv(this.formId);
    this._popupService.turnOffLeadGen();
    this.closeBeforeJoin = true;
  }

  /**
   * Modal shown event callback
   */
  onModalShown(): void {
    localStorage.removeItem(Helpers.LED_GEN_FORM_SUBMITTED_DATA_KEY);
    this.resetFormState(false);
    this._wcagService.setAriaOwnOfAnnouncer(this.formId);
  }

  onTermsClick(modalData: IModal): void {
    this.termsSelected = (this.termsSelected !== modalData.ind) ? modalData.ind : undefined;
    this.termsList = modalData.termsList;
  }

  onDontLeaveSubmit(): void {
    localStorage.setItem('leadGenModalSent', 'true');
    this._popupService.toggleLeadGen();
  }
  /**
   * On Form submit event
   * @param {IFormData} data
   */
   onFormSubmit(data: IFormData): void {
    this._wcagService.announceFormStatus(this._wcagService.DEFAULT_MESSAGE_TYPES.LOADING, this.formId);
    this._spinnerService.show();

    const prepopulatedData: PrepopulatedTrialData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      state: data.state,
      area: data.area,
      gym: data.gym,
      createdOn: format(new Date(), Helpers.LED_GEN_FORM_SUBMITTED_DATA_DATE_FORMAT)
    };
    localStorage.setItem(Helpers.LED_GEN_FORM_SUBMITTED_DATA_KEY, JSON.stringify(prepopulatedData));
    this.closeBeforeJoin = false;
    this.formData = data;
    const submittedData: CreateOpportunityPayload = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName
    };

    if (data?.termsOfUse) {
      submittedData.termsOfUse = data.termsOfUse,
      submittedData.privacyPolicy = data.privacyPolicy
    }
    
    if (data?.area) {
      submittedData.BusinessUnitCode = data.location.id;
      submittedData.facilityState = data.state;
    }

    this.createWebLeadSubs$ = this._facadeService.createWebLead(submittedData)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: this.onCreateWebLeadChange.bind(this),
      error: this.onCreateWebLeadError.bind(this),
      complete: this.onCreateWebLeadComplete.bind(this)
    });
  }

  onCreateWebLeadComplete(): void {
    this._wcagService.removeStatusDiv(this.formId);
    this._spinnerService.hide();
    const body = {
      text: this.cmsData.conf_subtitle,
      color: this.cmsData.color_subtitle
    };
  
    const daysAdded = 14; 
    const expirationDate = format(addDays(new Date(), daysAdded), 'MM/dd/yyyy');

    let conf_text = this.cmsData.conf_body;
    if(conf_text.includes("{expirationDate}")){
       conf_text = conf_text.replace("{expirationDate}",  expirationDate);
    }
    const footer = {
      text: conf_text,
      color: this.cmsData.color_conf_body
    };

    localStorage.setItem('leadGenModalSent', 'true');

    setTimeout(() => {
      return this._toastService.setLeadGenVisible(
        confirmationToast({
          title: this.cmsData.promo_confirmation_title,
          titleColor: this.cmsData.color_promo_code,
          body,
          footer,
          buttonText: this.cmsData.conf_submit_button_text,
          buttonLink: this.cmsData.conf_submit_button_link_url,
          accessibilityAnnouncement: this._wcagService.DEFAULT_ANNOUNCEMENTS.SUCCESS,
          gtmEventOnConfirm: this.getPostConfirmGTMEvent("Confirm: " + this.cmsData.conf_submit_button_text),
          gtmEventOnClose: this.getPostConfirmGTMEvent("Confirm: Close")
        })
      );
    }, 300);
  }

  onCreateWebLeadError(): void {
    this._spinnerService.hide();
    const body = {
      text: '',
      color: ''
    };
    const footer = {
      text: '',
      color: ''
    };

    this.sentGTMEvent("Error");

    this._toastService.setLeadGenVisible(
      confirmationToast({
        title: 'Something went wrong try again later',
        titleColor: '#FF5100',
        body,
        footer,
        buttonText: 'Close',
        buttonAccessibilityText: 'Close error dialog',
        accessibilityAnnouncement: 'Form submission failed'
      })
    );
  }

  onCreateWebLeadChange(res: any) {
    this._spinnerService.hide();
    this.mosoRoleId = res?.message?.MoSoRoleId;
    this.sendGTMConfirmEvent();  
  }

  /**
   * Terms Modal close callback
   */
  onTermsClose(): void {
    this.termsSelected = undefined;
  }

  resetFormState(val: boolean): void {
    this.resetForm = val;
  }

  ngOnDestroy(): void {
    this.onTermsClose();
  }

  sentGTMEvent(clickType: string): void{
    const event = this.getDefaultGTMEvent(clickType);
    if (event != null) { this.gtmService.pushTag(event); }
  }

  sendGTMConfirmEvent(): void{
    const event = this.getPostConfirmGTMEvent("Confirm");
    if (event != null) this.gtmService.pushTag(event);
  }

  getPostConfirmGTMEvent(clickType: string): GTMLeadGenFormConfirmProperties{
    return {
        ...this.getDefaultGTMEvent(clickType),
        "state": this.formData?.state,
        "area": this.formData?.area,
        "gym": this.formData?.gym,
        "firstName": this.formData?.firstName,
        "lastName": this.formData?.lastName,
        "email": this.formData?.email,
        "memberId": this.mosoRoleId,
        "clubId": this.formData?.location?.id,
      };
  }

  getDefaultGTMEvent(clickType: string): GTMLeadGenFormProperties{
    const pageData = TagManagerClickDirectiveHelper.getBodyPageData();
    const offer = this.leadGenData?.content_title?.text;
    if(pageData != null){
      return {
        ...TagManagerClickDirectiveHelper.getDefaultEventData(GTMEvent.Click, pageData),
        "offer": offer,
        "clickType": `New Lead Gen Form (${offer}) - ${clickType}`,
      };
    }
    return null;
  }
}
