import { Location, CLocationImage } from '@blinkfitness/blink-yext-api/dist/lib/models/yextLocations.model';
import { capitalize } from 'lodash-es';

export interface IFacilities {
  [clubID: string]: IFacility;
}
export interface IFacilitiesStates {
  [region: string]: IFacility[];
}
export interface IFolder {
  id: string;
  name: string;
  parentId: string;
}
export interface IFacility {
  id: string;
  accountId: string;
  countryCode: string;
  entityType: string;
  folderId: string;
  labels: string[];
  language: string;
  schemaTypes: string[];
  timestamp: string | Date;
  uid: string;
  slug: string;
  address?: IFacilityAddress;
  description?: string;
  emails?: string[];
  name?: string;
  company?: string;
  benefits?: IFacilityBenefit[];
  pricingMarkdownDisclaimers?: string[];
  termsSlug?: string;
  coordinates?: IFacilityCoords;
  url?: string;
  locationImages?: CLocationImage[];
  distanceKilometers?: number;
  distanceMiles?: number;
  hours?: IHours;
  type?: string;
  phone?: string;
  agreementStartDate?: string | Date;
  status?: IFacilityStatus;
  freeTrialModalLocation?: string;
  planOptions?: string[];
}
export type IFacilityStatus = 'OPEN' | 'FUTURE' | 'PRESALE' | 'SWEEPS';
export interface IFacilityPlan {
  commitment: TCommitment;
  text?: string;
  plans: IFacilityPlanCommitment[];
}
export interface IFacilityPlanCommitment {
  id: number;
  groupId: number;
  itemId: number;
  color: string;
  name: string;
  commitment: TCommitment;
  access: string;
  price: number | string;
  promoPrice?: number | string;
  benefits?: string[];
  footer?: string;
}
export type TCommitment = 'Annual' | 'Monthly';
export type TPeriodStatus = 'active' | 'next' | 'prev';

export enum EPeriodPlansName {
  Green = 0,
  Blue = 1,
}
export enum EPeriodPlansCommitment {
  Monthly = 0,
  Annual = 1,
}
export interface IFacilityCoords {
  latitude: number;
  longitude: number;
}
export interface IFacilityAddress {
  city: string;
  countryCode: string;
  line1: string;
  postalCode: string;
  region: string;
  directoryArea?: string;
}
export interface IFacilityBenefit {
  name: string;
  showBlue: boolean;
  showGray: boolean;
  showGreen: boolean;
  showOrange: boolean;
}
export interface IDistance {
  distanceKilometers: number;
  distanceMiles: number;
  id: string;
}
export interface IHours {
  monday?: IHoursDay;
  tuesday?: IHoursDay;
  wednesday?: IHoursDay;
  thursday?: IHoursDay;
  friday?: IHoursDay;
  saturday?: IHoursDay;
  sunday?: IHoursDay;
  reopenDate?: string | Date;
  holidays?: IHoursHoliday[];
}
export interface IHoursDay {
  name?: string;
  isClosed: boolean;
  openIntervals?: IOpenInterval[];
}
export interface IOpenInterval {
  start?: string;
  end?: string;
  startLT?: string;
  endLT?: string;
  isOpen24h?: boolean;
}
export interface IHoursHoliday {
  date?: string | Date;
  isClosed?: boolean;
}

export class FacilityYext implements IFacility {
  id: string;
  accountId: string;
  countryCode: string;
  entityType: string;
  folderId: string;
  labels: string[];
  language: string;
  schemaTypes: string[];
  timestamp: string | Date;
  uid: string;
  slug: string;
  address?: IFacilityAddress;
  description?: string;
  emails?: string[];
  name?: string;
  company?: string;
  benefits?: IFacilityBenefit[];
  pricingMarkdownDisclaimers?: string[];
  termsSlug?: string;
  coordinates?: IFacilityCoords;
  url?: string;
  locationImages?: CLocationImage[];
  distanceKilometers?: number;
  distanceMiles?: number;
  hours?: IHours;
  type?: string;
  phone?: string;
  agreementStartDate?: string | Date;
  status?: IFacilityStatus;
  freeTrialModalLocation?: string;
  planOptions?: string[];

  constructor(data: Location, distance?: IDistance) {
    this.id = data.meta?.id;
    this.accountId = data.meta?.accountId;
    this.countryCode = data.meta?.countryCode;
    this.entityType = data.meta?.entityType;
    this.folderId = data.meta?.folderId;
    this.labels = data.meta?.labels;
    this.language = data.meta?.language;
    this.schemaTypes = data.meta?.schemaTypes;
    this.timestamp = data.meta?.timestamp;
    this.uid = data.meta?.uid;

    if (data.address) {
      this.address = data.address as IFacilityAddress;
      // @ts-expect-error: On data property
      this.address.directoryArea = data.c_directoryArea as string;
    }
    if (data.description) {
      this.description = data.description;
    }
    if (data.emails) {
      this.emails = data.emails;
    }
    if (data.geomodifier) {
      this.name = data.geomodifier;
    }
    if (data.name) {
      this.company = data.name;
    }
    if ((data as any).c_clubUniqueIdentifierSlug) {
      this.slug = (data as any).c_clubUniqueIdentifierSlug;
    }
    if ((data as any).c_clubAmenities) {
      this.benefits = this.getBenefits((data as any).c_clubAmenities);
    }
    if (
      (data as any).c_pricingDisclaimerRtf1V2
      || (data as any).c_pricingDisclaimerRtf2V2
      || (data as any).c_pricingDisclaimerRtf3V2
      || (data as any).c_pricingDisclaimerRtf4V2
      || (data as any).c_pricingDisclaimerRtf5V2
      || (data as any).c_pricingDisclaimerRtf6V2
      || (data as any).c_pricingLegalDisclaimerRtfV2
    ) {
      this.pricingMarkdownDisclaimers = this.getMarkDownPricingDisclaimer(data);
    }
    if (data.c_terms_slug) {
      this.termsSlug = data.c_terms_slug;
    }
    if (data.yextDisplayCoordinate) {
      this.coordinates = data.yextDisplayCoordinate as IFacilityCoords;
    }
    if ((data as any).c_pagesURL) {
      this.url = (data as any).c_pagesURL;
    }
    if (data.c_LocationImages) {
      this.locationImages = data.c_LocationImages;
    }
    if (distance) {
      this.distanceKilometers = distance.distanceKilometers;
      this.distanceMiles = distance.distanceMiles;
    }
    if (data.hours) {
      this.hours = this.getHours(data.hours);
    }
    if ((data as any).c_clubType) {
      this.type = (data as any).c_clubType;
    }
    if (data.mainPhone) {
      this.phone = data.mainPhone;
    }
    if ((data as any).c_agreementStartDate) {
      this.agreementStartDate = (data as any).c_agreementStartDate;
    }
    if ((data as any).c_status) {
      this.status = (data as any).c_status;
    }
    if ((data as any).c_freeTrialModalLocation) {
      this.freeTrialModalLocation = (data as any).c_freeTrialModalLocation;
    }
    if ((data as any).c_planOptions) {
      this.planOptions = (data as any).c_planOptions;
    }
  }

  /**
   * Maps hours object with capitalized name
   * and time with 12h format
   * @param hours
   */
  getHours(hours: any) {
    const {
      holidayHours: holidays,
      reopenDate,
      ...weekDays
    } = hours;

    for (const key in weekDays) {
      if (weekDays[key]) {
        const weekday: IHoursDay = weekDays[key];
        weekday.name = capitalize(key);

        if (weekday.openIntervals) {
          weekday.openIntervals = weekday.openIntervals.map(interval => {
            const newInterval = { ...interval };

            if (newInterval.start) {
              newInterval.startLT = this.convertTime24to12(newInterval.start);
            }
            if (newInterval.end) {
              newInterval.endLT = this.convertTime24to12(interval.end);
            }

            if (
              newInterval.start === '00:00'
              && newInterval.end === '23:59'
            ) {
              newInterval.isOpen24h = true;
            }

            return newInterval;
          });
        }
      }
    }

    return { ...weekDays, reopenDate, holidays } as IHours;
  }

  /**
   * Maps amenities into an benefit list
   *
   * @param amenities
   */
  getBenefits(amenities: any): IFacilityBenefit[] {
    const benefits = amenities?.find((a) => a.amenityName.includes('Benefits'));
    return benefits?.amenityDetail;
  }

  /**
   * Maps markdown (rich text) pricing disclaimers into a list
   *
   * @param data
   */
  getMarkDownPricingDisclaimer(data: any): string[] {
    return [
      (data.c_pricingDisclaimerRtf1V2 as string) || '',
      (data.c_pricingDisclaimerRtf2V2 as string) || '',
      (data.c_pricingDisclaimerRtf3V2 as string) || '',
      (data.c_pricingDisclaimerRtf4V2 as string) || '',
      (data.c_pricingDisclaimerRtf5V2 as string) || '',
      (data.c_pricingDisclaimerRtf6V2 as string) || '',
      (data.c_pricingLegalDisclaimerRtfV2 as string) || '',
    ];
  }

  /**
   * Convert 24h timing to 12h
   * @param {string} time24h
   *
   * @example
   *  '23:00' -> '11:00 pm'
   */
  private convertTime24to12(time24h: string): string {
    const [hour, minute] = time24h.split(':');
    let posfix = 'am';
    let newHour = parseInt(hour, 10);

    if (newHour > 12) {
      newHour = newHour - 12;
      posfix = 'pm';
    }

    return `${newHour}:${minute} ${posfix}`;
  }
}
