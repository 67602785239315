import { faker } from '@faker-js/faker';

export class FakerJsUtils {
   /**
    * Generate random names using the FakerJS plugin
    */
    
   public static getRandomFirstName (): string {
      return faker.person.firstName();
   }
    
   public static getRandomLastName (): string {
      return faker.person.lastName();
   }

   public static getRandomFullName (): string {
      return `${faker.person.firstName()} ${faker.person.lastName()}`;
   }
}
