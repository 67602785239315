import { Injectable } from '@angular/core';

const SHORT_DAY_DASH_SHORT_DAY_REGEX = new RegExp(
  /^\s{0,}?([a-zA-Z]{3})\s{0,}?-\s{0,}?([a-zA-Z]{3})\s{0,}?$/i
);
const SHORT_DAY_REGEX = new RegExp(/\b[a-zA-Z]{3}\b/g);
const TIME_AMPM_REGEX = new RegExp(/^(\d{1,2}):(\d{2})\s{0,}?(AM|PM)$/i);

type ShortToLongMonthMap = { [shortMonth: string]: string };

const SHORT_MONTHS_LONG_MONTH: ShortToLongMonthMap = {
  jan: 'January',
  feb: 'February',
  mar: 'March',
  apr: 'April',
  may: 'May',
  jun: 'June',
  jul: 'July',
  aug: 'August',
  sep: 'September',
  oct: 'October',
  nov: 'November',
  dec: 'December',
};

type ShortToLongDayMap = { [shortDay: string]: string };
const SHORT_DAYS_LONG_DAY: ShortToLongDayMap = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};

type dictionary = { [word: string]: string};

@Injectable({
  providedIn: 'root',
})
export class AriaLabelTranslatorService {
  constructor() {}

  /**
   * gets the Short Month value that translates to a readable Aria Label
   * @param shortMonth string value of the short month (e.g Jan, Feb, Mar)
   * @returns {string}
   */
  translateShortMonth(shortMonth: string): string {
    const normalizedShortMonth = shortMonth.toLowerCase();
    const longMonth = SHORT_MONTHS_LONG_MONTH[normalizedShortMonth];

    return longMonth ?? shortMonth;
  }

  /**
   * @description gets the Short Day value that translates to a readable Aria Label
   * @param shortDay string value of the short day (e.g Mon, Tue, Wed)
   * @returns {string}
   */
  translateShortDay(shortDay: string): string {
    const normalizedShortDay = shortDay.toLowerCase();

    const longDay = SHORT_DAYS_LONG_DAY[normalizedShortDay];

    return longDay ?? shortDay;
  }

  /**
   * @description Translates the Short day - Short day to a readable aria label
   * @param text string value of the short day - short day (e.g Mon-Thu, Fri-Sun)
   * @returns {string}
   */
  translateShortDayDashShortDay(text: string): string {
    const match = text.match(SHORT_DAY_DASH_SHORT_DAY_REGEX);
    if (!match) {
      // Invalid format returns the original text
      return text;
    }

    const textParts = text.split('-');
    if (textParts.length !== 2) {
      return text;
    }
    const [, firstDay, secondDay] = match;

    const translatedFirstDay = this.translateShortDay(firstDay.trim());
    const translatedSecondDay = this.translateShortDay(secondDay.trim());

    if (!translatedFirstDay || !translatedSecondDay) {
      // Unsupported day, return the original text.
      return text;
    }

    return `${translatedFirstDay} through ${translatedSecondDay}`;
  }

  /**
   * @description Translates the Short day schedule to a readable aria label
   * @param text string value of the short day schedule (e.g Mon-Thu, Fri, Sun-Tue)
   * @returns {string}
   */
  translateShortDaySchedule(text: string): string {
    if (text.match(SHORT_DAY_DASH_SHORT_DAY_REGEX)) {
      return this.translateShortDayDashShortDay(text);
    } else if (text.match(SHORT_DAY_REGEX)) {
      return this.translateShortDay(text);
    }
    return text;
  }

  /**
   * @description Translates the military time schedule to a readable aria label
   * @param startTime string value of the start time in military time format (e.g 09:00, 13:00, 15:00)
   * @param endTime string value of the end time in military time format (e.g 09:00, 13:00, 15:00)
   * @returns {string}
   */
  translateTimeHHSchedule(startTime?: string, endTime?: string): string {
    return `${startTime} to ${endTime}`;
  }

  /**
   * @description Translates the time schedule to a readable aria label
   * @param startTime string value of the start time (e.g 09:00 AM, 01:00 PM, 03:00 PM)
   * @param endTime string value of the end time (e.g 09:00 AM, 01:00 PM, 03:00 PM)
   * @returns {string}
   */
  translateTimeAMPMSchedule(startTime?: string, endTime?: string): string {
    return `${this.translateTimeAMPM(startTime)} to ${this.translateTimeAMPM(
      endTime
    )}`;
  }

  /**
   * @description Translates the time to a readable aria label
   * @param text string value of the time (e.g 09:00 AM, 01:00 PM, 03:00 PM)
   * @returns {string}
   */
  translateTimeAMPM(text: string): string {
    // Validate the input format using a regex.
    const match = text.trim().match(TIME_AMPM_REGEX);

    if (!match) {
      // Invalid format, return the original text.
      return text;
    }

    const [, hourStr, minuteStr, ampm] = match;
    const hour = Number(hourStr);
    const minute = Number(minuteStr);
    const period = ampm.toUpperCase();

    if (minute === 0) {
      // If the minute part is zero, format the time as "hour o'clock AM/PM".
      return `${hour} o'clock ${period}`;
    } else {
      return `${hour}:${minute} ${period}`;
    }
  }

  translatePhoneNumber(text: string): string {
    let translatedText: string = '';

    const periodCountMarker = 4;
    let periodCounter = 0;

    text.split('').forEach((digit) => {
      translatedText += digit;

      periodCounter++;

      if (periodCounter === periodCountMarker) {
        // Adds a period for every 4th character/number
        translatedText += '.';
        periodCounter = 0;
      }
      translatedText += ' ';
    });

    return translatedText;
  }

  /**
   * @description Translates the words in the text based from the dictionary
   * @param text string that will be replaced with the new words from the dictionary
   * @returns {string}
   */
  translateWords(words: dictionary, text: string) {
    let translatedText = text;
    Object.keys(words).forEach((word) => {
      const regex = new RegExp(word +'\\b', 'g');
      translatedText = translatedText.replace(regex, words[word]);
    });

    return translatedText;
  }
}
