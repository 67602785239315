import { CMSData, FacilityPlansItem } from '@models';

interface IFacilityPlan {
    benefits: any;
    foot_note: string;
    is_active: boolean;
    show_details: boolean;
    plan_title: string;
    best_value: boolean;
    background_color_for_the_title_block: string;
    plan_price: string;
    promo_price: any;
    agreement_price: any;
    startupFeeDiscount: any;
    best_value_main_color: string;
    best_value_secondary_color: string;
    tax_transaction: any;
    sale_transaction: any;
    item_price: any;
    background_color_for_the_price_block: string;
    background_color_for_the_see_benefits_block: string;
    join_now: string;

    getPlanPrice(): string;
}

export class Plans {
    plans: IFacilityPlan[];

    constructor(data: CMSData) {
        this.plans = [];
        Object.keys(data.acf.facility_plans).forEach((item: any) => {
            this.plans.push(new FacilityPlan(data.acf.facility_plans[item]));
        });
    }

}


export class FacilityPlan implements IFacilityPlan {
    benefits: any;
    foot_note: string;
    is_active: boolean;
    show_details: boolean;
    plan_title: string;
    best_value: boolean;
    background_color_for_the_title_block: string;
    best_value_main_color: string;
    best_value_secondary_color: string;
    plan_price: string;
    background_color_for_the_price_block: string;
    promo_price: any;
    agreement_price: any;
    tax_transaction: any;
    sale_transaction: any;
    startupFeeDiscount: any;
    item_price: any;
    background_color_for_the_see_benefits_block: string;
    join_now: string;

    constructor(data: FacilityPlansItem) {
        this.benefits = data.benefits;
        this.foot_note = (data.foot_note.acf && data.foot_note.acf.foot_note) ? data.foot_note.acf.foot_note : '';
        this.is_active = data.is_active;
        this.show_details = data.show_details;
        this.plan_title = data.plan_title;
        this.best_value = data.best_value;
        this.best_value_main_color = data.best_value_main_color;
        this.best_value_secondary_color = data.best_value_secondary_color;
        this.background_color_for_the_title_block = data.background_color_for_the_title_block;
        this.background_color_for_the_price_block = data.background_color_for_the_price_block;
        this.background_color_for_the_see_benefits_block = data.background_color_for_the_see_benefits_block;
        this.plan_price = data.plan_price;
        this.promo_price = data.promo_price;
        this.agreement_price = data.agreement_price;
        this.sale_transaction = data.sale_transaction;
        this.tax_transaction = data.tax_transaction;
        this.startupFeeDiscount = data.startupFeeDiscount;
        this.item_price = data.item_price;
        this.join_now = data.join_now;
    }

    getPlanPrice(): string {
        return this.plan_price;
    }

    getPlanCurrentPrice() {
        return this.sale_transaction;
    }
}

export interface IPriceFacility {
  result: IPriceFacilityResult;
}

export interface IPriceFacilityResult {
  Item: IPriceFacilityResultItem;
}
export interface IPriceFacilityResultItem {
  DateUpdated: string;
  FacilityId: string;
  agreementPrices: {
    [plan: string]: IPriceFacilityResultItemPlan;
  };
}
export interface IPriceFacilityResultItemPlan {
  AgreementId: number;
  AgreementPrice: number;
  ItemPrice: number;
  PromoPrice: number;
  adjustmentPromotionFeeDiscount: number;
  adjustmentStartupFeeDiscount: number;
  saleTransaction: number;
  startupFeeDiscount: string;
  taxTransaction: number;
}

export interface IItemDetail {
  ItemId: number;
  Name: string;
  IsKeyItem: number;
  Quantity: number;
  UnitPrice: string;
  Amount: string;
  Promotion: string;
  Discount: string;
  Tax: string;
  Total: string;
}

export interface IPromotionDetail {
  Effect : {
    discountAmount: number;
    discountMonth: number;
    discountType: string;
    promotionMosoName: string;
    promotionType: string;
  };
  EndDate: string;
  Id: string;
  Name: string;
  StartDate: string;
}

export interface IFundingPlanPrice {
  prices?: [{
    AgreementType: string;
    AgreementId: number;
    Name: string;
    StartDate: string;
    fundingSource: string;
    Location: {
        Code: string;
        Name: string;
    };
    PricingDetail: {
      Total: string;
      ItemDetail: IItemDetail[];
    };
    promotion?: IPromotionDetail;
    bundleId: string;
  }],
  key?: string;
  message?: string;
  success?: boolean;
}

export interface IProductDetail {
  product: {
    items: IPerpetualOptions[];
    name: string;
    promoName: string;
  }
}

interface IPerpetualOptions {
  PerpetualOptions: IPerpetualOptionsItem[];
}

interface IPerpetualOptionsItem {
    PaymentOptionId: number;
    default: boolean;
    name: string;
}

export interface IPricingCookie {
  idLocation: string
  alternatePrice: string;
  paymentOption: string;
}
