import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'bw-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ErrorModalComponent {

    @Output('onReady') onReady = new EventEmitter<ErrorModalComponent>();
    @Input('message') message;

    @Input('correctPosition') correctPosition = true;

}
