import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Image } from '@shared/interfaces/image.interfaces';

@Component({
  selector: 'bw-list-images',
  templateUrl: './list-images.component.html',
  styleUrls: ['./list-images.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListImagesComponent {
  @Input() list: Image[];
  @Input() listClass = '';
  @Input() itemClass = '';
}
