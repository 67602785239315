import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PrerenderService {
  prerenderMeta: HTMLLinkElement;
  locationMeta: HTMLLinkElement;

  preloadedImage: HTMLLinkElement;

  constructor(@Inject(DOCUMENT) private doc) { }
  /**
   * Add 301 meta tag for prerenderio's use
   * @params locationUrl the new redirected url
   * @return void | any type
   */
  add301Header(locationUrl?: string): void {
    // check first if there is an existing prerender-status-code that is not 301
    const existingPrerenderMeta = this.doc.head.querySelector('[name="prerender-status-code"]');
    if (existingPrerenderMeta) {
      existingPrerenderMeta.remove();
  }
    if (!this.prerenderMeta) {
        this.prerenderMeta = this.doc.createElement('meta');
        this.prerenderMeta.setAttribute('name', 'prerender-status-code');
        this.prerenderMeta.setAttribute('content', '301');
        this.doc.head.appendChild(this.prerenderMeta);

        if (locationUrl) {
            this.locationMeta = this.doc.createElement('meta');
            this.locationMeta.setAttribute('name', 'prerender-header');
            this.locationMeta.setAttribute('content', `Location: ${locationUrl}`);
            this.doc.head.appendChild(this.locationMeta);
        }
    }
  }

  /**
   * Removes 301 meta tag for prerenderio's use
   * @return void | any type
   */
  remove301Header(): void {
    if (this.prerenderMeta) {
        this.prerenderMeta.remove();
        this.prerenderMeta = null;
    }
    if (this.locationMeta) {
        this.locationMeta.remove()
        this.locationMeta = null;
    }
  }

  /**
   * Preload the largest image of the page to decrease LCP
   * @param imageUrl string
   * @return void | any type
   */
  preloadLargeContentfulImage(imageUrl: string): void {
    // ensure only one <link> exists
    if (this.preloadedImage) {
      this.preloadedImage.remove();
    }
    this.preloadedImage = this.doc.createElement('link');
    this.preloadedImage.setAttribute('rel', 'preload');
    this.preloadedImage.setAttribute('as', 'image')
    this.preloadedImage.setAttribute('href', imageUrl);
    this.doc.head.appendChild(this.preloadedImage);
  }
}
