export interface IUSAState {
  abbreviation: string;
  cities?: { name: string } [];
  hasClub?: boolean;
  href?: string;
  name: string;
}

export type ValidationConfigs = {
  minLength?: number,
  maxLength?: number,
  pattern?: string
}
