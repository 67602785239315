import { Component, OnInit, Inject, OnDestroy, inject, DestroyRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StreamService } from '@core/services/core/stream.service';
import { get } from 'lodash-es';
import {
    INotFoundCMS,
    IGeneralCMS,
    ITitleCMS,
    IButtonArea,
    IBottomInfo,
    IImageContent,
    IMetatagsCMS
} from './404-page.types'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'bw-404',
    templateUrl: '404.component.html',
    styleUrls: ['./404.component.scss']
})
export class P404Component implements OnInit, OnDestroy {
    private destroyRef = inject(DestroyRef);
    prerenderMeta: HTMLLinkElement;
    metatags: IMetatagsCMS;

    content: {
        general: IGeneralCMS;
        title: ITitleCMS;
        buttonArea: IButtonArea;
        bottomInfo: IBottomInfo;
        imageContent: IImageContent;
    };

    constructor(
        private _streamService: StreamService,
        @Inject(DOCUMENT) private doc) { }


    ngOnInit(): void {
            this._streamService.notFoundPageData.obs
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(this.onPageDataChange.bind(this))

        this.addPrerenderMeta()
    }

    private addPrerenderMeta(): void {
        const existingPrerenderMeta = this.doc.head.querySelector('[name="prerender-status-code"]');
        if (existingPrerenderMeta) {
            existingPrerenderMeta.remove();
        }
        if (!this.prerenderMeta) {
            this.prerenderMeta = this.doc.createElement('meta');
            this.prerenderMeta.setAttribute('name', 'prerender-status-code');
            this.prerenderMeta.setAttribute('content', '404');
            this.doc.head.appendChild(this.prerenderMeta);
        }
    }

    private removePrerenderMeta(): void {
        if (this.prerenderMeta) {
            this.prerenderMeta.remove();
        }
    }

    onPageDataChange(data: INotFoundCMS): void {
        if (!data) {
            this._streamService.getNotFoundPageData();
            return;
        }

        this.content = {
            general: get(data, 'acf.general_content'),
            title: get(data, 'acf.title_content'),
            buttonArea: get(data, 'acf.button_area_content'),
            bottomInfo: get(data, 'acf.bottom_info_content'),
            imageContent: get(data, 'acf.image_content'),
        }
    }

    ngOnDestroy() {
        this.removePrerenderMeta();
    }
}
