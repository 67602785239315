// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-player-wrapper {
  background: black;
  width: 100%;
  position: relative;
  margin: 0 auto;
  min-height: 250px;
}
.video-player-wrapper .close {
  z-index: 1;
  cursor: pointer;
}
.video-player-wrapper:before {
  content: "";
  display: block;
  padding-bottom: 56%;
}
.video-player-wrapper .video-player {
  position: absolute;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInlvdXR1YmUtcGxheWVyLW1vZGFsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsaUJBQUE7RUFDQSxXQUFBO0VBQ0Esa0JBQUE7RUFDQSxjQUFBO0VBQ0EsaUJBQUE7QUFDRjtBQUNFO0VBQ0UsVUFBQTtFQUNBLGVBQUE7QUFDSjtBQUVFO0VBQ0UsV0FBQTtFQUNBLGNBQUE7RUFDQSxtQkFBQTtBQUFKO0FBR0U7RUFDRSxrQkFBQTtFQUNBLE1BQUE7RUFDQSxPQUFBO0FBREoiLCJmaWxlIjoieW91dHViZS1wbGF5ZXItbW9kYWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudmlkZW8tcGxheWVyLXdyYXBwZXIge1xuICBiYWNrZ3JvdW5kOiBibGFjaztcbiAgd2lkdGg6IDEwMCU7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgbWFyZ2luOiAwIGF1dG87XG4gIG1pbi1oZWlnaHQ6IDI1MHB4O1xuXG4gIC5jbG9zZSB7XG4gICAgei1pbmRleDogMTtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG4gIH1cblxuICAmOmJlZm9yZSB7XG4gICAgY29udGVudDogXCJcIjtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBwYWRkaW5nLWJvdHRvbTogNTYlO1xuICB9XG5cbiAgLnZpZGVvLXBsYXllciB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogMDtcbiAgICBsZWZ0OiAwO1xuICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/youtube-player-modal/youtube-player-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;AACF;AACE;EACE,UAAA;EACA,eAAA;AACJ;AAEE;EACE,WAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;AADJ;AACA,g8BAAg8B","sourcesContent":[".video-player-wrapper {\n  background: black;\n  width: 100%;\n  position: relative;\n  margin: 0 auto;\n  min-height: 250px;\n\n  .close {\n    z-index: 1;\n    cursor: pointer;\n  }\n\n  &:before {\n    content: \"\";\n    display: block;\n    padding-bottom: 56%;\n  }\n\n  .video-player {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
