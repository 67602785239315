import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CmsService } from '@core/services/cms.service';
import {
  TermsAndConditionsActionTypes,
  ITermsAndConditionsRequest,
} from '@store/actions/terms-and-conditions.actions.types';

@Injectable()
export class TermsAndConditionsEffects {
  /**
   * Effect Get Facilities
   *
   * @description
   * This effect is triggered by Load All Facilities action.
   */
  getTermsOfUse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsAndConditionsActionTypes.request),
      mergeMap((action: ITermsAndConditionsRequest) =>
        this.cmsService.getTermsBySlug(action.slug).pipe(
          map((data = []) => ({
            type: TermsAndConditionsActionTypes.requestSuccess,
            data: {
              [action.id]: {
                pdf: data[0]?.acf?.pdf_link || '',
                content: data[0]?.content?.rendered || '',
                title: data[0]?.title?.rendered || '',
              },
            },
          })),
          catchError((error) =>
            of({
              type: TermsAndConditionsActionTypes.requestFailure,
              error,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private cmsService: CmsService) {}
}
