import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { throwIfAlreadyLoaded } from './module-import-guard';
/**
 * Custom Created services
 * */
import { ServicesModule } from './services/services.module';
import { AuthGuard } from './guards/auth.guard';
import { WindowRef } from './services/helpers/window-ref';
import { DoNotShowOnRefreshGuard } from './guards/do-not-show-on-refresh.guard';
/**
 * Custom Created Modules
 * */
import { LayoutModule } from './layouts/layout.module';
/**
 * Custom Created Components
 * */
import { P404Component } from '@public-pages/pages/other/404/404.component';
import { P500Component } from '@public-pages/pages/other/500.component';
import { WorkingHoursService } from './services/working-hours.service';
import { BriteverifyService } from './services/briteverify.service';
import { SharedModule } from '@shared/shared.module';
import { YextRedirectGuard } from './guards/yext-redirect.guard';
import { RedirectGuard } from './guards/redirect.guard';

@NgModule({
    declarations: [
        P404Component,
        P500Component,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        SharedModule,
        LayoutModule,
        ServicesModule,
    ],
    providers: [
        AuthGuard,
        YextRedirectGuard,
        RedirectGuard,
        WindowRef,
        DoNotShowOnRefreshGuard,
        WorkingHoursService,
        BriteverifyService
    ],
    bootstrap: [],
    exports: [P404Component, P500Component]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
