import { Component, OnDestroy, OnInit, OnChanges, ViewEncapsulation, Input, EventEmitter, Output, ViewChild, AfterViewInit, inject, DestroyRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { get } from 'lodash-es';
import { Store } from '@ngrx/store';
import { StreamService } from '@core/services/core/stream.service';
import { firstNameRule, lastNameRule, emailRule, termsRule, phoneRule, goalsRule, callMe, timeRule, locationRule, gymRule, zipCodeRule, areaRule } from './form-base.validators';
import { ICmsPage } from '@models/CMS/cms-page';
import { TermsData } from '@shared/components/terms-content/terms-content.types';
import { AppState } from '@store/store.types';
import { facilities } from '@store/reducers/facilities-reducer';
import { IFacilitiesState } from '@store/reducers/facilities-reducer.types';
import { IFacilitiesStates, IUSAState } from '@models';
import { IFoldersState } from '@store/reducers/folders.reducer.types';
import { IFacilitiesByRegionState } from '@store/reducers/facilities-by-region.reducer.types';
import { loadFacilitiesByRegion } from '@store/actions/facilities-by-region.actions';
import { facilitiesByRegion } from '@store/reducers/facilities-by-region.reducer';
import { folders } from '@store/reducers/folders.reducer';
import { constants } from '@core/const/constants';
import { loadFolders } from '@store/actions/folders.actions';
import { IFacility } from '@models';
import { IOption } from '@shared/components/select/select.types';
import { BriteVerifyValidator } from '@shared/validators';
import { goalsList, timeList } from '@shared/components/form/form.data';
import { IButtonGroup } from '@shared/components/input-button-group/input-button-group.types';
import { WcagService } from '@core/services/helpers/wcag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IFormGroupData } from './form-base.types';
import { PrepopulatedTrialData } from '@core/helpers';
import { ScreenreaderErrorsComponent, CustomFormError} from '@blinkfitness/blink-wcag-helper-components';
import * as Helpers from '@core/helpers';
import { addHours, parse } from 'date-fns';

const PATTERNS = constants.PATTERNS;

const CUSTOM_FORM_ERRORS: Array<CustomFormError> = [{
  formControlName: 'checkboxTerms',
  conditionToShow: (formControl: FormControl<boolean>): boolean => {
    return formControl?.hasError('required');
  },
  message: 'Please Agree to the Terms and Privacy Policy to proceed. Select this element to navigate to the checkbox.',
  inputId: ''
}]

@Component({
  selector: 'bw-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormBaseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private destroyRef = inject(DestroyRef)
  @ViewChild('formDirective') formDirective: NgForm;
  @ViewChild(ScreenreaderErrorsComponent) screenreaderErrors: ScreenreaderErrorsComponent;
  
  // Checkbox error messages
  @Input() termsErrorMessage = 'Please agree to the terms of use and privacy policy to proceed';
  @Input() simpleCheckboxErrorMessage = 'Please agree to the condition to proceed';
  
  // Fields
  @Input() showFirstName = true;
  @Input() showLastName = true;
  @Input() showEmail = true;
  @Input() showPhone = false;
  @Input() showLocation = false;
  @Input() showArea = false;
  @Input() showTerms = false;
  @Input() showGoals = false;
  @Input() showBestTime = false;
  @Input() showCall = false;
  @Input() showZipCode = false;
  @Input() showSMSOpt = false;
  
  // Required fields
  @Input() phoneRequired = true;
  @Input() zipCodeRequired = true;
  @Input() termsRequired = true;
  @Input() callMeRequired = true;
  
  // Submit button
  @Input() submitBtnColor_active: string;
  @Input() submitBtnBackground_active: string;
  @Input() submitBtnText_active: string;
  @Input() submitBtnColor_disabled: string;
  @Input() submitBtnBackground_disabled: string;
  @Input() submitBtnText_disabled: string;
  
  @Input() phonePlaceholder = 'Mobile Phone Number';
  @Input() callMeText = '';
  @Input() checkboxTermsId: string;
  @Input() simpleCheckboxId = 'simple-checkbox';
  @Input() checkboxSMSOptId: string;
  @Input() termsSelected: number;
  @Input() formReset = false;
  @Input() fullWidthInput = false;
  @Input() submitBtnTop = false;
  @Input() rtfLabelTermsCheckbox: string;
  @Input() SMSOptText: string;
  @Input() linkRules: string;
  @Input() termsRequiredRead = false;
  @Input() formBaseId = 'form-base';
  @Input() formPrepopulated = false;
  @Output() onSubmit = new EventEmitter();
  @Output() termsClick = new EventEmitter();
  @Output() termsChange = new EventEmitter();
  @Output() smsOptChange = new EventEmitter();
  @Output() callMeClick = new EventEmitter();

  facilities$: Observable<IFacilitiesState>;
  facilitiesByRegion$: Observable<IFacilitiesByRegionState>;
  facilitiesByRegion: IFacilitiesByRegionState;
  folders$: Observable<IFoldersState>;
  folders: IFoldersState;
  options: IOption[];
  selectedFolder: IOption;
  facilities: IFacilitiesState;
  formBase: FormGroup<IFormGroupData>;
  termsList: TermsData[];
  submittedLocation: IFacility;
  termsOfUSe: ICmsPage;
  gyms: IOption[] = [];
  areas: string[] = [];
  DELAY_LIST = 250;
  gymSelected = true;
  privacyPolicy: ICmsPage;
  goalsList: IOption[] = goalsList;
  timeList: IButtonGroup[] = timeList;
  stateData: IOption;
  isGymLoading: boolean;
  isAreaLoading: boolean;
  prepopulatedTrialData: PrepopulatedTrialData;

  termsCheckboxElem: HTMLElement;
  termsCheckboxBlurCallback: EventListener;
  simpleCheckboxElem: HTMLElement;
  simpleCheckboxBlurCallback: EventListener;

  ariaOwnIdsState = '';
  ariaOwnIdsArea = '';
  ariaOwnIdsGym = '';

  onInputBlur: () => void;

  formBaseFieldsId: string;

  errMsgRequiredField: string = "Required field";
  errMsgInvalidFormat: string = "Invalid format";

  constructor(
    private fb: FormBuilder,
    private _streamService: StreamService,
    private store: Store<AppState>,
    public briteVerifyValidator: BriteVerifyValidator,
    public _wcagService: WcagService
    ) {
      this.facilitiesByRegion$ = this.store.select(facilitiesByRegion);
      this.facilities$ = this.store.select(facilities);
      this.folders$ = this.store.select(folders)
  }

  /**
   * Lifecycle OnInit
   */
  ngOnInit() {
    this.setValidators();
    this.getTerms();

    this.facilities$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onFacilitiesChange.bind(this)),
    this.folders$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onFoldersChange.bind(this)),
    this.facilitiesByRegion$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onStatesChange.bind(this))
    
    this.formBaseFieldsId = `${this.formBaseId}-form-base-fields`;
  }

  ngOnChanges() {
    if(this.formReset) this.resetForm();
    this.setValidators();
    if(this.formPrepopulated) this.prePopulateForm();
  }

  ngAfterViewInit(): void {
    this.onInputBlur = this.screenreaderErrors.onInputBlur.bind(this.screenreaderErrors);

    const formElem: HTMLElement = document.getElementById(this.formBaseFieldsId);
    const checkboxInputId = this.checkboxTermsId + '-input-input';
    this.termsCheckboxElem = formElem?.querySelector('#' + checkboxInputId);
    this.termsCheckboxBlurCallback = () => {
      this.onInputBlur();
    }
    this.termsCheckboxElem?.addEventListener('blur', this.termsCheckboxBlurCallback);

    const simpleCheckboxInputId = this.simpleCheckboxId + '-input-input';
    this.simpleCheckboxElem = formElem?.querySelector('#' + simpleCheckboxInputId);
    this.simpleCheckboxBlurCallback = () => {
      this.onInputBlur();
    }
    this.simpleCheckboxElem?.addEventListener('blur', this.simpleCheckboxBlurCallback);
    this._wcagService.ariaHideElements('.mat-checkbox-background > svg');
    CUSTOM_FORM_ERRORS[0].inputId = checkboxInputId;
  }

  // Comes from the subscribe and load States
  onFoldersChange(folders: IFoldersState): void {
    this.folders = folders;
    if (!folders?.loading && !folders?.data) {
      this.store.dispatch( // Load States
        loadFolders({})
      );
    }

    if (folders?.data) {
      this.setOptions();
    }
  }
  
  setOptions(): void {
    const getRegion = (name: string) =>
      constants.statesList.find((region: IUSAState) => (
        region.abbreviation === name
      ));

    // Filter Folders by State
    this.options = this.folders?.data
      .filter(folder => !!getRegion(folder.name))
      .map(folder => {
        const region = getRegion(folder.name);

        return {
          value: region?.name,
          region,
          children: this.getChildren(
            this.facilitiesByRegion?.data,
            region.abbreviation,
          )
        };
      });

      this.options.forEach((op: IOption) => {
        this.ariaOwnIdsState += `state-${op.region.abbreviation} `;
      });

  }

  onStateSelectionChange(event): void {
    event.source?.focus();
    const option = this.options.find(obj => obj.value === event.value)
    this.onSelectState(option);
  }
  /**
   * Comes from the select State input
   * @param {IOption} data 
   */
  onSelectState(data: IOption): void {
    this.formFieldState('gym', 'disable');
    this.stateData = data
    const region = this.stateData?.region || '';
    
    this.showArea ? this.setUpArea(this.stateData, region) : this.setUpGym(this.stateData, region);

    this.selectedFolder = this.stateData;
    this.selectedFolder.loading = true;
  }



  onAreaSelectionChange(event): void {
    event.source?.focus();
    this.onSelectArea(event.value);
  }
  /**
   * Comes from the select Area input
   * @param {string} area 
   */
  onSelectArea(area: string): void {
    this.formFieldState('gym', 'enable');
    const gymList = this.stateData.children.filter(gymItem => gymItem.area === area);
    this.createGymsSelect(gymList)
    this.onInputBlur();
  }
  
  onGymSelectionChange(event): void {
    event.source?.focus();
    const option = this.gyms.find(obj => {
      return obj.label === event.value
    })
    this.onSelectGym(option);
  }
  /**
   * Comes from the select Gym input
   * @param {IOption} gymData 
   */
  onSelectGym(gymData: IOption): void {
    this.submittedLocation = this.facilities?.data[gymData.value];
    this.gymSelected = false;
  }

  /**
   * Start setup for create the Area input
   * @param {IOption} item 
   * @param {IUSAState} region 
   */
  setUpArea(item: IOption, region: IUSAState): void {
    this.formFieldState('area', 'enable');
    this.areas = [];
    this.loadingState('area');

    if (item?.areas?.length) {
      this.createAreasSelect(item.areas);
      
      return;
    }

    this.dispatchLoadFacilitiesByRegion(region);
  }
  
  /**
   * Start setup for create the Gym input
   * @param {IOption} item 
   * @param {IUSAState} region 
   */
  setUpGym(item: IOption, region: IUSAState): void {
    this.formFieldState('gym', 'enable');
    this.gyms = [];
    this.loadingState('gym');

    if (item?.children?.length) {
      this.createGymsSelect(item.children);
      return;
    }

    this.dispatchLoadFacilitiesByRegion(region);
  }

  /**
   * Load Facilities by region
   * Fire the onStatesChange() method
   * @param {IUSAState} region 
   */
  dispatchLoadFacilitiesByRegion(region: IUSAState): void {
    this.store.dispatch(
      loadFacilitiesByRegion({ // this fire onStatesChange()
        state: region,
      }),
    );
  }
  
  /**
   * Comes from the subscriber
   * @param {IFacilitiesByRegionState} facilities 
   */
  onStatesChange(facilities: IFacilitiesByRegionState): void {
    this.facilitiesByRegion = facilities;

    if (!facilities?.data && !this.selectedFolder || facilities?.data && !this.selectedFolder) return;
    
    const region = this.selectedFolder.region.abbreviation;

    if (!facilities.data[region]) return;

    this.selectedFolder.children = this.getChildren(facilities.data, region);
    this.selectedFolder.areas = this.getAreas(facilities.data, region);
    this.createGymsSelect(this.selectedFolder.children);
    this.createAreasSelect(this.selectedFolder.areas);
    delete this.selectedFolder.loading;
    this.selectedFolder = undefined;
  }

  /**
   * 
   * @param {IFacilitiesStates} data 
   * @param {string} region 
   * @returns Array of facilities (Gyms)
   */
  getChildren(data: IFacilitiesStates, region: string): any[] {
    const list = data && data[region] || [];

    return list.map( facility => ({
      area: facility?.address?.directoryArea,
      freeTrialModalLocation: facility?.freeTrialModalLocation,
      label: facility.name,
      value: facility.id
    }));
  }
  
  /**
   * 
   * @param {IFacilitiesStates} data 
   * @param {string} region 
   * @returns Array of Areas
   */
  getAreas(data: IFacilitiesStates, region: string): string[] {
    const areasList = data[region].filter( item => item.address?.directoryArea).map(ele => ele.address?.directoryArea);
    return [...new Set(areasList)];
  }

  /**
   * Gets data for create the Gym select
   * @param {IOption} gyms 
   */
  createGymsSelect(gyms: IOption[]) {
    this.formBase.patchValue({ gym: null });
    setTimeout(() => {
      this.gyms = [].concat(gyms);
      this.gyms.forEach((gym: IOption) => {
        this.ariaOwnIdsGym += `gym-${gym.value} `;
      })
      this.loadingState('gym');

      const prepopulatedGym = this.prepopulatedTrialData?.gym;
      if(prepopulatedGym){
        this.formBase.patchValue({ gym: prepopulatedGym });
        this.onGymSelectionChange({ gym: prepopulatedGym });
      }
    }, this.DELAY_LIST);
  }
  
  /**
   * Gets data for create the Area select
   * @param {string} areas 
   */
  createAreasSelect(areas: string[]): void {
    this.formBase.patchValue({ area: null });
    setTimeout(() => {
      this.areas = [].concat(areas);
      this.areas.forEach((area: string) => {
        this.ariaOwnIdsArea += `area-${area.split(' ').join('')} `;
      })
      this.loadingState('area');

      const prepopulatedArea = this.prepopulatedTrialData?.area;
      if(prepopulatedArea){
        this.formBase.patchValue({ area: prepopulatedArea});
        this.onAreaSelectionChange({ value: prepopulatedArea });
      }
    }, this.DELAY_LIST);
  }

  getTerms() {
      this._streamService.termsOfUseCMS.obs
      .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onTermsOfUseChange.bind(this))
      this._streamService.privacyCMS.obs
      .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onPrivacyChange.bind(this))
  }

  /**
   *
   * @param {ICmsPage} terms
   */
  onTermsOfUseChange(terms: ICmsPage): void {
    if (!terms) {
      this._streamService.getTermsOfUseCMS();
      return;
    }

    this.termsOfUSe = terms;
  }

  /**
   *
   * @param {ICmsPage} privacy
   */
  onPrivacyChange(privacy: ICmsPage): void {
    if (!privacy) {
      this._streamService.getPrivacyCMS();
      return;
    }

    this.privacyPolicy = privacy;
  }

  /**
   * Set custom validators
   */
  setValidators(): void {
    if (this.showFirstName && !this.formBase?.controls?.firstName) {
      this.formBase?.addControl(
        'firstName',
        new FormControl<string>(null, firstNameRule)
      );
    }
    
    if (this.showLastName && !this.formBase?.controls?.lastName) {
      this.formBase?.addControl(
        'lastName',
        new FormControl<string>(null, lastNameRule)
      );
    }

    if (this.showEmail && !this.formBase?.controls?.email) {
      this.formBase = <any>this.fb.group(
        emailRule(this.briteVerifyValidator)
      );
    }

    if (this.showLocation) {
      if (!this.formBase?.controls?.location) {
        this.formBase?.addControl(
          'location',
          new FormControl<string>(null, locationRule),
        );
      }

      if (!this.formBase?.controls?.gym) {
        this.formBase?.addControl(
          'gym',
          new FormControl<string>(null, gymRule),
        );

        this.formFieldState('gym', 'disable');
      }

      if (this.showArea) {
        if (!this.formBase?.controls?.area) {
          this.formBase?.addControl(
            'area',
            new FormControl<string>(null, areaRule),
          );
  
          this.formFieldState('area', 'disable');
        }
      }
    }
    
    if (this.showPhone && !this.formBase?.controls?.phone) {
      this.formBase?.addControl(
        'phone',
        new FormControl<string>('', phoneRule(this.phoneRequired)),
      );
    }
    
    if (this.showZipCode && !this.formBase?.controls?.zipCode) {
      this.formBase?.addControl(
        'zipCode',
        new FormControl<string>('', zipCodeRule(this.zipCodeRequired)),
      );
    }

    if (this.showTerms && !this.formBase?.controls?.checkboxTerms) {
      this.formBase?.addControl(
        'checkboxTerms',
        new FormControl<boolean>(false, this.termsRequired ? termsRule : null),
      );

      if (!this.termsList) {
        this.setupTerms();
      }
    }

    if (this.showSMSOpt && !this.formBase?.controls?.optedSMS) {
      this.formBase?.addControl(
        'optedSMS',
        new FormControl<boolean>(false),
      );

      if (!this.termsList) {
        this.setupTerms();
      }
    }

    if (this.showCall && !this.formBase?.controls?.checkboxCallMe) {
      this.formBase?.addControl(
        'checkboxCallMe',
        new FormControl<boolean>(false, this.callMeRequired ? callMe : null),
      );
    }
    
    if (this.showGoals && !this.formBase?.controls?.goal) {
      this.formBase?.addControl(
        'goal',
        new FormControl<string>('', goalsRule),
      );
    }

    if (this.showBestTime && !this.formBase?.controls?.time) {
      this.formBase?.addControl(
        'time',
        new FormControl<string>('', timeRule),
      );
    }
  }

  /**
   * Setup Terms component
   */
  setupTerms(): void {
    this.termsList = [{
      text: 'terms of use',
      service: this._streamService.termsOfUseCMS,
      retrieve: this._streamService.getTermsOfUseCMS,
    }, {
      text: 'privacy policy',
      service: this._streamService.privacyCMS,
      retrieve: this._streamService.getPrivacyCMS,
    }];
  }

  /**
   * On Form submit event callback
   */
  onSubmitFn(formData: any): void {
    if (this.formBase.invalid) return;
    
    const data = {...formData};
    
    if (this.showPhone) data.phone = data.phone?.replace(/[^0-9]/g, '') || '';
    if (this.showLocation) data.location = this.submittedLocation;
    if (this.showArea) data.state = formData.location;
    if (this.showTerms) {
      data.termsOfUse = this.termsOfUSe?.acf?.pdf_link;
      data.privacyPolicy = this.privacyPolicy?.acf?.pdf_link;
    }

    this.onSubmit.emit(data);
  }

  /**
   * Reset form
   */
  resetForm(): void {
    this.formFieldState('gym', 'disable');
    if (this.showArea) this.formFieldState('area', 'disable');
    this.formDirective?.resetForm();
    this.formBase?.reset();
  }

   /**
   * Prepopulate form with data
   */
  prePopulateForm(): void {
    this.prepopulatedTrialData = null;
    const ledGenData = localStorage.getItem(Helpers.LED_GEN_FORM_SUBMITTED_DATA_KEY);
    if (!this.formBase || !ledGenData) {
      return; // No data to prepopulate
    }
    const data: PrepopulatedTrialData = JSON.parse(ledGenData);
    const expirationDate = addHours(parse(data.createdOn,Helpers.LED_GEN_FORM_SUBMITTED_DATA_DATE_FORMAT, new Date()), 8);
    if (expirationDate >= new Date()){
      this.prepopulatedTrialData = data;
      // Using setTimeout with zero delay to execute in the next tick of the event loop
      setTimeout(() => {
        this.populateFormWithData(data);
      }, 0);
    }
    else{
      localStorage.removeItem(Helpers.LED_GEN_FORM_SUBMITTED_DATA_KEY);
    }
  }

  /**
  * Populate the form fields with the provided data.
  * @param data The data to populate the form with.
  */
  private populateFormWithData(data: PrepopulatedTrialData): void {
    this.formBase.patchValue({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      location: data.state,
      area: data.area,
      gym: data.gym
    });

    this.onStateSelectionChange({ value: data.state });
  }

  /**
   * Facilities state change callback
   * @param {IFacilitiesState} facilities
   */
  onFacilitiesChange(facilities: IFacilitiesState): void {
    this.facilities = facilities; //set property loading:false
  }

  /**
   *
   * @param {boolean} checked
   */
  onTermsCheckboxChange(checked: boolean): void {
    if (checked) {
      this.onTermsClose();
    }
    this.onInputBlur();
  }

  /**
   *
   * @param {boolean} checked
   */
  onSMSOptCheckboxChange(checked: boolean): void {
    const ctrl = this.formBase?.get('phone');
    ctrl.setValidators(phoneRule(checked));
    ctrl.updateValueAndValidity();
    this.smsOptChange.emit(checked);
    this.onInputBlur();
  }

  /**
   * Terms click callback
   * @param {{ index: number }} obj
   */
  onTermsClick(data: { index: number }): void {
    this.termsClick.emit({ ind: data.index, termsList: this.termsList })
  }

  /**
   * Call Me click callback
   */
   onSimpleCheckboxClick(): void {
    this.callMeClick.emit();
  }

  /**
   * Terms Modal close callback
   */
  onTermsClose(): void {
    this.termsChange.emit();
    // this.termsSelected = undefined;
  }

  /**
   * Form validators
   */

  formValidation(controlName: string): boolean {
    return this.formBase.get(controlName).invalid && (this.formBase.get(controlName).touched || this.formBase.get(controlName).dirty)
  }

  nameErrorMessage(controlName: string): string{
    if (this.formValidation(controlName)){
        if (this.formBase.get(controlName).errors.pattern)
          return "Invalid characters. Allowed:" + PATTERNS.MOSO_COGNITO_ALLOWED_NAME_CHARACTERS;
        else
          return this.errMsgRequiredField;
    }
    return "";
  }
  
  formValidationPhone(vald1: string, vald2?: string, vald3?: string, vald4?: string ): boolean {
    if(arguments.length === 1) return !this.getPhonevalueValidation();
    if(arguments.length === 2) return this.getPhonevalueValidation() && this.formBase.get('phone')?.hasError(vald1) && !this.formBase.get('phone')?.hasError(vald2);
    if(arguments.length === 3) return this.getPhonevalueValidation() && this.formBase.get('phone')?.hasError(vald1) && !this.formBase.get('phone')?.hasError(vald2) && !this.formBase.get('phone')?.hasError(vald3);
    if(arguments.length === 4) return this.getPhonevalueValidation() && this.formBase.get('phone')?.hasError(vald1) && !this.formBase.get('phone')?.hasError(vald2) && !this.formBase.get('phone')?.hasError(vald3) && !this.formBase.get('phone')?.hasError(vald4);
  }

  getPhonevalueValidation(): boolean {
    return !!this.formBase.get('phone').value && !!this.formBase.get('phone').value.length;
  }

  submitBtnState() {
    if(this.showLocation) {
      return !(this.termsOfUSe === undefined) || !(this.privacyPolicy === undefined) || this.formBase.invalid || this.formBase.pending || this.gymSelected ? true : false;
    }
    return !(this.termsOfUSe === undefined) || !(this.privacyPolicy === undefined) || this.formBase.invalid || this.formBase.pending ? true : false;
  }

  /**
   * Set the loading spinner status
   * @param {string} target  
   */
   loadingState(target: string): void {
    if (target === 'gym') {
      this.isGymLoading = !this.gyms.length && this.formBase?.get('gym').status !== 'DISABLED' || false;
      return;
    } 
    this.isAreaLoading = !this.areas.length && this.formBase?.get('area').status !== 'DISABLED' || false;
  }

  /**
   * Enable or disable the form field state
   * @param {string} target 
   * @param {string} state 
   */
  formFieldState(target: string, state: string): void {
    if (state === 'enable') {
      this.formBase?.get(target)?.enable();
      return;
    }
    this.formBase?.get(target)?.disable();
  }

  /**
   * Set focus on input with given the id
   * @param {string} inputId
   */
  focusOnInput(event: any, inputId: string): void {
    const formElem: HTMLElement = document.getElementById(this.formBaseFieldsId);
    const input: HTMLElement = formElem?.querySelector('#' + inputId);
    input?.focus()
  }

  /**
   * Lifecycle OnDestroy
   */
   ngOnDestroy(): void {
    this.resetForm();
    this.onTermsClose();

    this.termsCheckboxElem?.removeEventListener('blur', this.termsCheckboxBlurCallback);
    this.simpleCheckboxElem?.removeEventListener('blur', this.simpleCheckboxBlurCallback);
  }
}
