import { BrowserModule, Title, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleService } from './core/services/helpers/title.service';
import { ToastService } from './core/services/helpers/toast.service';
import { SeoService } from './core/services/helpers/seo.service';
import { AriaLabelTranslatorService } from './core/services/helpers/aria-label-translator.service'
import { LowerCaseUrlSerializer } from './core/services/helpers/urlSerializer';
import { UrlSerializer } from '@angular/router';
import { LoadingScripts } from './core/services/helpers/loading-scripts';
import { SpinnerModule } from './shared/components/spinner/spinner.module';
import { environment } from '@environment';
import { reducers, metaReducers } from './store/reducers';
import { FacilitiesEffects } from './store/effects/facilities.effects';
import { FacilitiesByRegionEffects } from './store/effects/facilities-by-region.effects';
import { FacilitiesByGeoEffects } from './store/effects/facilities-by-geo.effects';
import { FoldersEffects } from './store/effects/folders.effects';
import { TermsAndConditionsEffects } from './store/effects/terms-and-conditions.effects';
import { SharedModule } from './shared/shared.module';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { GTM_AUTH_NAME, GTM_ID_NAME, GTM_PREVIEW_NAME } from './core/services/configs/tag-manager.configs';
import { ScriptInjectorService } from '@core/services/helpers/scripts-injector.service';

@NgModule({
    imports: [
        BrowserModule,
        HammerModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        SpinnerModule.forRoot(),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([
            FacilitiesEffects,
            FacilitiesByRegionEffects,
            FacilitiesByGeoEffects,
            FoldersEffects,
            TermsAndConditionsEffects,
        ]),
        // GoogleTagManagerModule.forRoot({
        //     id: environment.GTM_ID,
        //   })
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        Title,
        TitleService,
        ToastService,
        SeoService,
        AriaLabelTranslatorService,
        LoadingScripts,
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
        GoogleTagManagerService,
        { provide: GTM_ID_NAME, useValue: environment.GTM_ID },
        { provide: GTM_AUTH_NAME,  useValue: environment.GTM_AUTH },
        { provide: GTM_PREVIEW_NAME,  useValue: environment.GTM_PREVIEW },
        ScriptInjectorService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
