import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

/**
 * Input Phone Component
 * A general phone input component with error messages
 *
 * @example
 *  <bw-input-phone
 *    [group]="formGroup"
 *    name="formControlName"
 *    placeholder="Phone Input placeholder"
 *  ></bw-input-phone>
*/
@Component({
  selector: 'bw-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPhoneComponent {
  @Input() group: UntypedFormGroup;
  @Input() name: string;
  @Input() placeholder = 'Mobile Phone Number*';
}
