import { Component, Input, OnInit } from '@angular/core';
import { IHeroImageComponentCMSACF } from './hero-image-layout.types';
import { PrerenderService } from '@core/services/core/prerender.service';

@Component({
  selector: 'bw-hero-image-layout',
  templateUrl: './hero-image-layout.component.html',
  styleUrls: ['./hero-image-layout.component.scss'],
})

export class HeroImageComponent implements OnInit {
  @Input() content: IHeroImageComponentCMSACF[];
  @Input() deviceType: string;

  constructor(private _prerenderService: PrerenderService) {}

  preloadImage: boolean = true;

  ngOnInit(): void {
    this.content?.forEach(o => this.adjustHeroImageContent(o));
  }

  adjustHeroImageContent(content: IHeroImageComponentCMSACF): void{
    if (content.click_url.trim() === "") content.click_url = null;
    this._prerenderService.preloadLargeContentfulImage(content.image.mobile_image);
    this._prerenderService.preloadLargeContentfulImage(content.image.tablet_image);
    this._prerenderService.preloadLargeContentfulImage(content.image.desktop_image);
  }
}
