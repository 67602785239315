import { Injectable } from '@angular/core';

export abstract class Logger {
    info: any;
    warn: any;
    error: any;
    table: any;
}

@Injectable()
export class LoggerService implements Logger {
    info: any;
    warn: any;
    error: any;
    table: any;

    invokeConsoleMethod(): void {
    }
}

