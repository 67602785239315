import { CMSData } from '.';

interface IEmergencySettingsCMSData {
  active: boolean;
  banner_color: string;
  banner_image: {
    desktop: string;
    tablet: string;
    mobile: string;
  };
  heading: string;
  message: string;
}
export interface IEmergencySettingsCMS {
  acf: IEmergencySettingsCMSData;
}

interface IEmergencySettings {
    banner_image_desktop: string;
    banner_image_mobile: string;
    banner_image_tablet: string;
    banner_color: string;
    active: boolean;
    heading: string;
    message: string;
}

export class EmergencySettings implements IEmergencySettings {
    banner_image_desktop: string;
    banner_image_mobile: string;
    banner_image_tablet: string;
    banner_color: string;
    active: boolean;
    heading: string;
    message: string;

    private constructor(data: any = {}) {
        const {
            heading,
            message,
            banner_color,
            active,
            banner_image
        } = data;

        const bannerMedia = banner_image || {};
        const { tablet, mobile, desktop } = bannerMedia;

        this.banner_image_desktop = desktop;
        this.banner_image_mobile = mobile;
        this.banner_image_tablet = tablet;
        this.banner_color = banner_color;
        this.active = !!active;
        this.heading = heading;
        this.message = message;
    }

    static fromClubData(data: CMSData) {
        const emergencySettingsSettings = (
            data &&
            data.acf &&
            data.acf.emergency_settings &&
            data.acf.emergency_settings.acf);

        return new EmergencySettings(emergencySettingsSettings);
    }

    static fromDataset(data: any) {
        const emergencySettingsSettings = (data && data.acf);
        return new EmergencySettings(emergencySettingsSettings);
    }
}
