import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class MapsService {

    private _browserLocationShareStatus: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly browserLocationShareStatus$: Observable<any> = this._browserLocationShareStatus.asObservable().pipe(distinctUntilChanged());

    private _showMapForUnhappyPath: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly showMapForUnhappyPath$: Observable<boolean> = this._showMapForUnhappyPath.asObservable().pipe(distinctUntilChanged());

    public lat = 40.727856;
    public lng = -73.9957357;
    public zoom = 15;
    public browserLat;
    public browserLng;

    public newCoordinators = new Subject();

    public openWindow = new Subject();

    constructor() {
    }

    setBrowserLocationShareStatus(data: any) {
        this.browserLat = data.lat;
        this.browserLng = data.lng;
        this._browserLocationShareStatus.next(data);
    }

    setShowMapForUnhappyPath(data: any) {
        this._showMapForUnhappyPath.next(data);
    }

}

