import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Youtube player Modal component
 * reference:
 * https://ng-bootstrap.github.io/#/components/modal/examples
*/
@Component({
  selector: 'bw-youtube-player-modal',
  templateUrl: './youtube-player-modal.component.html',
  styleUrls: ['./youtube-player-modal.component.scss']
})
export class YoutubePlayerModalComponent {
  @ViewChild('content') content: ElementRef;
  @Input() size: 'sm' | 'lg' = 'lg';
  @Output() onReady = new EventEmitter();
  @Output() onStateChange = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() onApiChange = new EventEmitter();
  @Output() onPlaybackQualityChange = new EventEmitter();
  @Output() onPlaybackRateChange = new EventEmitter();
  @Output() onModalClose = new EventEmitter();
  @Output() onModalDissmissed = new EventEmitter();
  videoId: string;
  modal: any;

  constructor(
    private modalService: NgbModal,
  ) {}

  /**
   * open(): void
   * Call this using component reference:
   * template: <bw-youtube-player-modal #playerModal></bw-youtube-player-modal>
   * component: this.playerModal<ElementRef>.open(youtubeId<string>);
   */
  open(youtubeID: string): void {
    if (!youtubeID) {
      throw new Error('Youtube id is required');
      return;
    }

    this.videoId = youtubeID;

    this.modal = this.modalService.open(this.content, {
      size: this.size,
      centered: true,
    });

    this.modal.result
      .then(
        this.onModalCloseFn.bind(this),
        this.onModalDissmissedFn.bind(this),
      );
  }

  /**
   * onModalCloseFn(result: any): void
   * This methods is called when the modal is closed
   * 
   * @param result any
   */
  onModalCloseFn(result?: any): void {
    this.onModalClose.emit(result);
  }

  /**
   * onModalDissmissedFn(reason: any): void
   * This methods is called when the modal is dissmissed
   * reason will match ModalDismissReasons.[REASON] from @ng-bootstrap/ng-bootstrap
   * 
   * @param reason any
   */
  onModalDissmissedFn(reason?: any): void {
    this.onModalDissmissed.emit(reason);
  }
}
