import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { WcagService } from '@core/services/helpers/wcag.service';
import { ICountdownModaCopy } from '@public-pages/pages/checkout/checkout/checkout.types';

@Component({
  selector: 'bw-countdown-modal',
  templateUrl: './countdown-modal.component.html',
  styleUrls: ['./countdown-modal.component.scss']
})
export class CountdownModalComponent implements OnDestroy {
  @ViewChild('timerElement') timerElement: ElementRef;
  @Output() onReady = new EventEmitter<CountdownModalComponent>();
  @Output() onCloseCountdownModal = new EventEmitter();
  @Input() correctPosition = true;
  @Input() copy: ICountdownModaCopy;
  @Input() timer: string;
  @Input() customClass: string;

  announcementInterval: NodeJS.Timer;
  INTERVAL_TIMER = 20000;
  modalId = 'countdown-timer-modal';
  
  constructor(private _wcagService: WcagService) { }

  onShow(): void {
    this._wcagService.setAriaOwnOfAnnouncer(this.modalId);
    if (this.announcementInterval) {
      clearInterval(this.announcementInterval);
      this.announcementInterval = null;
    }
    this.announcementInterval = setInterval(() => {
      let timerText = (this.timerElement?.nativeElement?.innerText || this.timerElement?.nativeElement?.textContent);
      if (this.timer === '00:00') {
        timerText += `. Your session has timed out.`;
      }
      this._wcagService.announceMessageAssertive(timerText);
    }, this.INTERVAL_TIMER);
  }

  onClose(){
    this.cleanUpModal();
    this.onCloseCountdownModal.emit();
  }

  ngOnDestroy(): void {
    this.cleanUpModal();
  }

  cleanUpModal() {
    if (this.announcementInterval) {
      clearInterval(this.announcementInterval);
      this.announcementInterval = null;
    }
    this._wcagService.removeAriaOwnOfAnnouncer(this.modalId);
  }

}
