import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from '@core/services/log4ts/logger.service';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class DoNotShowOnRefreshGuard  {
    subscription: Subscription;

    constructor(private router: Router, private logger: LoggerService) {
        // this.logger.info('-DoNotShowOnRefreshGuard-');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Promise<boolean> {
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

}
