import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { constants } from './const/constants';
import {get, keyBy} from 'lodash-es';
import { Month, FacilityYext, IFacilityCoords, IDistance, IFacilitiesStates } from '@models';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@blinkfitness/blink-yext-api/dist/lib/models/yextLocations.model';
import { IRedirection } from './guards/redirections';

export const LED_GEN_FORM_SUBMITTED_DATA_KEY = "LedGenModalSubmittedData";
export const LED_GEN_FORM_SUBMITTED_DATA_DATE_FORMAT = "yyyyMMddHHmmss";

export class PrepopulatedTrialData {
  firstName: string;
  lastName: string;
  email: string;
  state: string;
  area: string;
  gym: string;
  createdOn: string;
}

export function setFocus(selector: string): void { 
  const element = document.querySelector(selector) as HTMLElement; 
  if (element) { 
    element.focus(); 
  } 
}
/**
 * Clean All Subscriptions. Call function from ngOnDestroy()
 * cleanSubscriptions(): void
 */
export function cleanSubscriptions(subscriptions: Subscription[]): void {
  subscriptions.forEach((subscription: Subscription) => subscription && subscription.unsubscribe());
}

/**
 * Validation all form controls
 * @param {FormGroup} formGroup
 */
export function validateAllFormFields(formGroup: UntypedFormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
      control.updateValueAndValidity();
    } else if (control instanceof UntypedFormGroup) {
      validateAllFormFields(control);
    }
  });
}

/**
 * unValidate All Form controls
 * @param {FormGroup} formGroup
 */
export function unValidateAllFormFields(formGroup: UntypedFormGroup): void {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsUntouched({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      unValidateAllFormFields(control);
    }
  });
}

export function validationLengthErrorMessage(isMin: boolean, length: number): string {
    const msg = (isMin) ? constants.VALIDATION_FIELD_MESSAGES.MIN_CHARACTERS : constants.VALIDATION_FIELD_MESSAGES.MAX_CHARACTERS;
    return msg.replace("{length}", length.toString());
}


export function resetInputs(formGroup: UntypedFormGroup): void {
  formGroup.reset();
}

/**
 * Get Current Year
 * @returns {number}
 */
export function getCurrentYear(): number {
  const d = new Date();
  return d.getFullYear();
}

/**
 * Get Years limit (currently = 51)
 * @returns {any[]}
 */
export function getYearsLimit(): number[] {
  const yearsList = [];
  const d = new Date();
  const cy = d.getFullYear();

  for (let i = 0; i < 51; i++) {
    yearsList.push(cy + i);
  }

  return yearsList;
}

/**
 * getCardType(number): string
 * checking credit card numbers and returning creadit card name
 * @param number
 * @returns {string}
 */
export function getCardType(number: string): string {
  // visa
  let re = new RegExp('^4');
  if (number?.match(re) !== null) {
    return 'Visa';
  }
  // Mastercard
  if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
    return 'Mastercard';
  }
  // AMEX
  re = new RegExp('^3[47]');
  if (number?.match(re) !== null) {
    return 'AmericanExpress';
  }
  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number?.match(re) !== null) {
    return 'Discover';
  }
  return '';
}

/**
 *  filterMonth that can be selected year to year
 * @param selectedYear
 */
export function filterMonth(selectedYear: string): Month[] {
  if (selectedYear === this.getCurrentYear().toString()) {
    const d = new Date();
    const currentMonth = d.getMonth() + 1;
    return constants.monthsList.filter(o => o.number >= currentMonth);
  }

  return constants.monthsList;
}

/**
 * formats a one-digit month to two
 * example: month 2 -> 02
 * @returns {string} two digits string
 * */
export function formatMonth(m: number): string {
  return m < 10 ? '0' + m : m.toString();
}

/**
 * formatYear(yyyy: any): string
 * format year 2018 -> 18
 * */
export function formatYear(yyyy: string): string {
  return ('' + yyyy).substring(2);
}

/**
 * getRouteSlug(string): string
 * checks path and returns the page slug
 * @param path
 * @returns {string}
 */
export function getRouteSlug(path: string): string {
  if (path === '/') {
    return 'home';
  } else if (path.includes('area')) {
    return 'area';
  } else if (path.includes('locations')) {
    return (path.length > 10) ? 'club' : 'locations';
  } else if (path.includes('personaltraining/startup/member')) {
    return 'pt-startup-member';
  } else if (path.includes('personaltraining/startup')) {
    return 'pt-startup';
  } else if (path.includes('personaltraining')) {
    return 'personal-training';
  } else if (path.includes('whyblink')) {
    return 'why-blink';
  } else if (path.includes('faq')) {
    return 'faq';
  } else if (path.includes('privacy')) {
    return 'privacy';
  } else if (path.includes('terms')) {
    return 'terms';
  } else if (path.includes('ccparequest')) {
    return 'ccpa-request';
  } else if (path.includes('blinkapp')) {
    return 'blink-app';
  } else if (path.includes('sweepstakes')) {
    return 'sweepstakes';
  } else if (path.includes('virtual-fitness')) {
    return 'virtual-fitness';
  } else if (path.includes('checkout')) {
    return 'checkout';
  }

  return 'other';
}

/**
 * getOffsetTop(HTMLElement, number): number
 * Checks element nested offset top
 * @param el: HtmlElement | Element
 * @param accumulator: number
 * @returns {string}
 */
export function getOffsetTop(el: HTMLElement, accumulator: number = 0): number {
  const offset = accumulator + get(el, 'offsetTop', 0);
  const parentEl = el.offsetParent as HTMLElement;

  return (
    parentEl
      ? getOffsetTop(parentEl, offset)
      : offset
  );
}

/**
 * getArea(Router): string | null
 * Gets the current area from url
 * @param {Router | NavigationEnd} event?
 * @returns string | null
 */
export function getArea(event?: Router | NavigationEnd): string | null {
  if (!event || !event.url) {
    return null;
  }

  const { url } = event;
  if (/^\/locations\/area\/.*$/.test(url)) {
    const [, path] = url.split('/locations/area/') as Array < string > ;
    const [currentArea] = (path && path.split('?') || []) as Array < string > ;
    return currentArea;
  }

  return null;
}

/**
 * getLocation(Router): string | null
 * Gets the location club from url
 * @param {Router | NavigationEnd} event?
 * @returns string | null
 */
export function getLocation(event?: Router | NavigationEnd): string | null {
  if (!event || !event.url) {
    return null;
  }

  const { url } = event;
  if (!url.includes('locations/area') && /^\/locations\/.*$/.test(url)) {
    const [, path] = url.split('/locations/') as Array<string>;
    const [currentFacility] = (path && path.split('?') || []) as Array<string>;

    return currentFacility;
  }

  return null;
}

/**
 * Map Yext Location to Reducer interface
 *
 * @param {Location[]} data
 * @returns {Facility[]}
 *  A list of Facilities with type Facility
 */
export function mapYextResponseToReducer(data: Location[], distances: IDistance[] = []): FacilityYext[] {
  return data.map((entity, index) => new FacilityYext(entity, distances[index]));
}

/**
 * Function to find distance between two positions and converting to miles
 * @param {IFacilityCoords} coords1
 * @param {IFacilityCoords} coords2
 */
export function haversineDistance(coords1: IFacilityCoords, coords2: IFacilityCoords) {
  const toRad = (x: number) => x * Math.PI / 180;

  const { longitude: lon1, latitude: lat1 } = coords1;
  const { longitude: lon2, latitude: lat2 } = coords2;
  const R = 6371; // Earth's radius in Km
  const x1 = lat2 - lat1;
  const dLat = toRad(x1);
  const x2 = lon2 - lon1;
  const dLon = toRad(x2);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  // Convert to miles
  d /= 1.60934;

  return d;
}

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
export function deepMergeObj(target: object, source: object): any {
  const isObject = (obj: object) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMergeObj(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}


/**
 * Creates an object with arrays coming from loadFacilitiesByRegion action
 * @param {IFacilitiesStates} facilities
 */
export function getKeys(facilities: IFacilitiesStates): IFacilitiesStates {
  let obj = {};

  for (const key in facilities) {
    if (key && facilities && facilities[key]) {
      obj = {
        ...obj,
        ...keyBy(facilities[key], 'id'),
      };
    }
  }

  return obj;
}

/**
 * Get a list of invalid controls
 * @param {FormGroup} form
 *  FormGroup instance
 * @returns {string[]}
 *  A list of invalid fields
 */
export function findInvalidControls(form: UntypedFormGroup): string[] {
  const invalid = [];
  const controls = form.controls;

  for (const name in controls) {
    if (controls[name]?.invalid) {
      invalid.push(name);
    }
  }

  return invalid;
}

/**
 * Timeout for async/await functions
 * @param {number} ms
 *  delay in milliseconds
 * @returns Promise
 * @example
 *  async () => {
 *    // do something
 *    await sleep(1000);
 *    // do something else after 1000ms
 *  }
 */
export function sleep(ms: number): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Converts the query params object into a query string
 * @param {{ [key: string]: string }} queryParams
 *  Query Params object
 * @returns {string}
 *  Query string
 * @example
 *  objectToQueryString({ param1: 'test1', param2: 'test2' })
 *    -> 'param1=test1&param2=test2'
 */
export function objectToQueryString(queryParams: { [key: string]: string }): string {
  return Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
}

/**
 * Checks if it is a full or partial url
 * @param {string} item
 * @returns {boolean}
 *  Wether has 'http' or '//' or not
 */
export function isFullUrl(link: string): boolean {
  return link?.includes('http') || link?.includes('//');
}

/**
 * Returns all strings with the first character capitalized
 * @param {string} text
 * @returns {string} 
 */
export function capitalizeFirstLetter(text: string): string {
  const stArray = text.split(' ');
  for (let i = 0; i < stArray.length; i++) {
    stArray[i] = stArray[i][0].toUpperCase() + stArray[i].slice(1);
  }
  return stArray.join(' ');
}

/**
 * Returns the final redirection path based on path and list of redirection href
 * @param {string} path
 * @param {IRedirection[]} redirectionUrls
 * @returns {IRedirection} 
 */
export function getRedirectionPath(path: string, redirectionUrls: IRedirection[]): IRedirection {
  const pathSplit = path.split('/');
  const redirection: IRedirection = redirectionUrls.find(item => item.path === path);
  const redirectionWithPathSplit: IRedirection = redirectionUrls.find(item => item.path === `/${pathSplit[1]}`);
  if (!redirection && !redirectionWithPathSplit) {
    return null;
  }
  const finalRedirection = redirection ? redirection : {
    path: redirectionWithPathSplit.path,
    href: `${redirectionWithPathSplit.href}/${pathSplit.slice(2, pathSplit.length).join('/')}`
  };
  return finalRedirection;
}
