import { on, createReducer } from '@ngrx/store';
import { IFacilitiesAction } from '@store/actions/facilities.actions.types';
import * as Actions from '@store/actions/facilities.actions';
import * as ActionsByRegion from '@store/actions/facilities-by-region.actions';
import * as ActionsByGeo from '@store/actions/facilities-by-geo.actions';
import { IFacilitiesState } from './facilities-reducer.types';
import { AppState } from '@store/store.types';
import { request, requestError } from './common';
import { IFacilitiesByRegionAction } from '@store/actions/facilities-by-region.actions.types';
import { IFacilities } from '@models';
import { deepMergeObj, getKeys } from '@core/helpers';

/**
 * Initial state for the reducer.
 * That's the value when page loads.
 */
const initialState: IFacilitiesState = {
  loading: false,
};

/**
 * It merges new values with current state
 * @param {IFacilitiesState} state
 * @param {IFacilitiesAction} action
 * @returns {IFacilitiesState}
 *  The facilities state
 */
const mergeFacilities = (
  state: IFacilitiesState,
  { facilities }: IFacilitiesAction,
): IFacilitiesState => ({
  ...state,
  loading: false,
  error: null,
  data: deepMergeObj({ ...state.data }, facilities),
});

/**
 *
 * @param {IFacilitiesState} state
 * @param {IFacilitiesByRegionAction} action
 */
const mergeFacilitiesByRegion = (
  state: IFacilitiesState,
  { facilities }: IFacilitiesByRegionAction,
): IFacilitiesState => ({
  ...state,
  loading: false,
  error: null,
  data: deepMergeObj({ ...state.data }, getKeys(facilities)),
});

/**
 * Facilities Reducer
 */
export const facilitiesReducer = createReducer(
  initialState,
  on(Actions.loadFacilities, request),
  on(Actions.loadFacilityById, request),
  on(Actions.loadFacilitiesSuccess, mergeFacilities),
  on(
    Actions.loadFacilityByIdSuccess,
    (state: IFacilitiesState, { facility }) => ({
      ...state,
      loading: false,
      error: null,
      data: {
        ...state.data,
        ...facility,
      },
    }),
  ),
  on(Actions.loadFacilitiesFailure, requestError),
  on(Actions.loadFacilityByIdFailure, requestError),
  /* Facilities by Geo Search */
  on(ActionsByGeo.loadFacilitiesByGeoSearch, request),
  on(ActionsByGeo.loadFacilitiesByGeoSearchSuccess, mergeFacilities),
  on(ActionsByGeo.loadFacilitiesByGeoSearchFailure, requestError),
  /* Facilities by State */
  on(ActionsByRegion.loadFacilitiesByRegion, request),
  on(ActionsByRegion.loadFacilitiesByRegionSuccess, mergeFacilitiesByRegion),
  on(ActionsByRegion.loadFacilitiesByRegionFailure, requestError),
);

/**
 * Gets facilities reducer
 * @param {AppState} state
 * @returns {IFacilitiesState}
 *  The facilities state
 */
export const facilities = (state: AppState): IFacilitiesState =>
  state.facilities;

/**
 * Gets data from facilities reducer
 * @param {AppState} state
 * @returns {IFacilities}
 *  The facilities state data
 */
export const facilitiesData = (state: AppState): IFacilities =>
  state.facilities?.data;
