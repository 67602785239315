interface IDayHours {
    from_day?: string;
    sequence?: number;
    start_at?: number;
    to_day?: string;
    getDay(day: any): string;
}

export class DayHours implements IDayHours {
    from_day: string;
    sequence: number;
    start_at: number;
    to_day: string;

    constructor(from?: string, seq?: number, at?: number, to?: string) {
        this.from_day = from ? from : '';
        this.sequence = seq ? seq : 0;
        this.start_at = at ? at : 0;
        this.to_day = to ? to : '';
    }

    getDay(day: string) {
        switch (day) {
            case 'sun':
                day = 'Sunday';
                break;
            case 'mon':
                day = 'Monday';
                break;
            case 'tue':
                day = 'Tuesday';
                break;
            case 'wed':
                day = 'Wednesday';
                break;
            case 'thu':
                day = 'Thursday';
                break;
            case 'fri':
                day = 'Friday';
                break;
            case 'sat':
                day = 'Saturday';
                break;
        }
        return day;
    }
}
