export class FacilityShortData {
    constructor(data: any) {
        return data.map(item => ({
            facilityId: item.acf.moso_id,
            id: item.id,
            lat: Number(item.acf.geo_location.lat),
            lng: Number(item.acf.geo_location.lng),
            title: item.acf.title,
            icon: item.acf.type === 'open' ? './assets/img/map/pin_open_club.png' : './assets/img/map/pin_presale_club.png',
            draggable: false,
            street: item.acf.address.street + ' ' + item.acf.address.street2,
            city: item.acf.address.city,
            state: item.acf.address.state,
            postalcode: item.acf.address.zip,
            email: item.acf.contacts.email,
            phone: item.acf.contacts.phone,
            website: 'http://blinkfitness.com',
            type: item.acf.type,
            slug: item.slug,
            acf: item.acf
        }));
    }
}
