import { Validators, FormControl } from '@angular/forms';
import { constants } from '@core/const/constants';
import creditCardPattern from './credit-card-pattern.validator';
import { ValidationConfigs } from '@models';

type CreditCardFormValidation = {
  name_on_card: ValidationConfigs
  card_number: ValidationConfigs
  card_month: ValidationConfigs
  card_year: ValidationConfigs
  security_code: ValidationConfigs
}

export const creditCardAccountValidationConfigs: CreditCardFormValidation = {
  name_on_card: {
    minLength: 2,
    maxLength: 40
  },
  card_number: {
    minLength: 12,
    maxLength: 16
  },
  card_month: null,
  card_year: null,
  security_code: {
    minLength: 3,
    maxLength: 4
  }
}

const PATTERNS = constants.PATTERNS;

export const ccValidations = {  
  name_on_card: new FormControl<string>('', [
    Validators.required,
    Validators.minLength(creditCardAccountValidationConfigs.name_on_card.minLength),
    Validators.maxLength(creditCardAccountValidationConfigs.name_on_card.maxLength),
    Validators.pattern(PATTERNS.CORPORATE_MEMBERSHIP_TEXT2),
  ]),
  card_number: new FormControl<string>('', [
    Validators.required,
    Validators.minLength(creditCardAccountValidationConfigs.card_number.minLength),
    Validators.maxLength(creditCardAccountValidationConfigs.card_number.maxLength),
    creditCardPattern,
    Validators.pattern(PATTERNS.NUMBER),
  ]),
  card_month: new FormControl<string>('', [
    Validators.required,
  ]),
  card_year: new FormControl<string>('', [
    Validators.required,
  ]),
  security_code: new FormControl<string>('', [
    Validators.required,
    Validators.maxLength(creditCardAccountValidationConfigs.security_code.maxLength),
    Validators.minLength(creditCardAccountValidationConfigs.security_code.minLength),
    Validators.pattern(PATTERNS.NUMBER),
  ]),
};
