import { Attribute, Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';


@Directive({
    selector: '[bwCompare]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CompareDirective, multi: true }]
})
export class CompareDirective implements Validator {

    constructor(@Attribute('bwCompare') public comparer: string,
        @Attribute('parent') public parent: string) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        const e = c.root.get(this.comparer);
        if (!c.value) {
            return;
        }
        if (!e.value) {
            return;
        }

        // value not equal in verify control
        if (e && c.value.toLowerCase() !== e.value.toLowerCase() && !this.isParent) {
            return { ...e.errors, 'compare': true };
        }

        // user typing in password and match
        if (e && c.value.toLowerCase() === e.value.toLowerCase() && this.isParent) {
            if (e.errors) {
                delete e.errors['compare'];
                if (Object.keys(e.errors).length > 0) {
                    const errs = { ...e.errors };
                    e.setErrors(errs);
                } else {
                    e.setErrors(null);
                }
            }
        }

        // user typing in password and mismatch
        if (e && c.value.toLowerCase() !== e.value.toLowerCase() && this.isParent) {
            e.setErrors({ ...e.errors, 'compare': true });
        }
    }

    private get isParent() {
        if (!this.parent) {
            return false;
        }
        return this.parent === 'true';
    }
}
