import { Injectable } from '@angular/core';
import { LoadingScripts } from './loading-scripts';
import { IZendeskWidget } from './helpers.types';

@Injectable()
export class ZendeskService {
  widget: IZendeskWidget;

  /**
   * ZendeskService
   * @param loadingScript
   */
  constructor(
    private loadingScript: LoadingScripts,
  ) {}

  /**
   * Zendesk script load callback
   * @param {IZendeskWidget} zendeskWidget
   */
  onLoadScript(zendeskWidget: IZendeskWidget) {
    if (!zendeskWidget) {
      return;
    }

    this.widget = zendeskWidget;
    this.show();
  }

  /**
   * Load zendesk script
   */
  load(): void {
    if (this.widget) {
      this.show();
      return;
    }

    this.loadingScript.loadScriptZendesk()
      .then(this.onLoadScript.bind(this));
  }

  /**
   * Hide zendesk widget
   */
  hide(): void {
    if (!this.widget) {
      return;
    }

    this.widget('webWidget', 'close');
    this.widget('webWidget', 'hide');
    this.widget('webWidget', 'reset');
  }

  /**
   * Show zendesk widget
   */
  show(): void {
    if (!this.widget) {
      return;
    }

    this.widget('webWidget', 'reset');
    this.widget('webWidget', 'helpCenter:setSuggestions', { labels: ['suggested'] });
    this.widget('webWidget', 'show');
    const helpWidget = document.querySelector('iframe#launcher');
    setTimeout(() => {

      helpWidget?.setAttribute('role', 'application');
      helpWidget?.setAttribute('aria-label', 'Help Chat Widget');
  
      const helpWidgetParent = document.querySelector('iframe#launcher')?.parentElement;
      helpWidgetParent?.setAttribute('role', 'region');
      helpWidgetParent?.setAttribute('aria-label', 'Help Chat');
    }, 5000)
  }
}
