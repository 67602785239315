import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

@Component({
    templateUrl: '500.component.html'
})
export class P500Component implements OnInit, OnDestroy {
    prerenderMeta: HTMLLinkElement;

    constructor(@Inject(DOCUMENT) private doc) {}

    private addPrerenderMeta(): void {
        const existingPrerenderMeta = this.doc.head.querySelector('[name="prerender-status-code"]');
        if (existingPrerenderMeta) {
            existingPrerenderMeta.remove();
        }
        if (!this.prerenderMeta) {
            this.prerenderMeta = this.doc.createElement('meta');
            this.prerenderMeta.setAttribute('name', 'prerender-status-code');
            this.prerenderMeta.setAttribute('content', '500');
            this.doc.head.appendChild(this.prerenderMeta);
        }
    }

    private removePrerenderMeta(): void {
        if (this.prerenderMeta) {
            this.prerenderMeta.remove();
        }
    }

    ngOnInit() {
        this.addPrerenderMeta();
    }

    ngOnDestroy() {
        this.removePrerenderMeta();
    }
}
