import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'displayOnDevice'
})
export class DisplayOnDevice implements PipeTransform {
  transform(displayOptions: string[], deviceType: string) {
    return displayOptions.find(item => {
      return item === deviceType
    })
  }
}