import { Injectable } from '@angular/core';

@Injectable()
export class ScreenAndDeviceService {
    tabletBreakpoint = 820;
    mobileBreakpoint = 425;

    /**
     * Get the screen resolution
     * It allows to pass custom breakpoints
     * @param customTabletBreakpoint 
     * @param customMobileBreakpoint 
     * @returns The device type
     */
    getScreenType(customTabletBreakpoint?: number, customMobileBreakpoint?: number): string {
        const screenSize = window.innerWidth;
        
        const isMobile = customMobileBreakpoint ? screenSize <= customMobileBreakpoint : screenSize <= this.mobileBreakpoint;
        
        const isTablet = customTabletBreakpoint 
        ? screenSize > customMobileBreakpoint && screenSize <= customTabletBreakpoint 
        : screenSize > this.mobileBreakpoint && screenSize <= this.tabletBreakpoint;
        
        const isDesktop = customTabletBreakpoint ? screenSize > customTabletBreakpoint : screenSize > this.tabletBreakpoint;

        if (isMobile) return "mobile";
        if (isTablet) return "tablet";
        if (isDesktop) return "desktop";
    }

}
