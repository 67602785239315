import { Component, ViewEncapsulation, EventEmitter, Input, Output, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WcagService } from '@core/services/helpers/wcag.service';
import { WCAGUtils } from '@core/services/helpers/wcag.utils';

@Component({
  selector: 'bw-simple-checkbox',
  templateUrl: './simple-checkbox.component.html',
  styleUrls: ['./simple-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SimpleCheckboxComponent implements OnInit, AfterViewInit {
  @ViewChild('rtfCopyEle') rtfCopyEle: ElementRef;
  @Input() rtfCopy: string;
  @Input() preFix: string;
  @Input() posFix: string;
  @Input() linkCopy: string;
  @Input() linkColor: string;
  @Input() name: string;
  @Input() group: UntypedFormGroup;
  @Input() errorMessage: string = 'Please agree to proceed to the next step';
  @Input() id = 'simple-checkbox';
  @Output() linkClick = new EventEmitter();
  @Output() onChange = new EventEmitter<boolean>();

  inputId: string;
  ariaLabel: string;
  ariaLabelTimeout: NodeJS.Timeout;

  ARIA_LABEL_DELAY_MILLI = 700

  onKeyPress = WCAGUtils.onKeyPress;

  constructor(private _wcagService: WcagService) { }

  ngOnInit(): void {
    this.inputId = `${this.id}-input`;
  }

  ngAfterViewInit(): void {
    if (this.rtfCopy) {
      const arrHTMLElements = Array.from(this.rtfCopyEle.nativeElement.children[0].children);
      arrHTMLElements.forEach((element: HTMLElement) => {
        if (element.tagName.toLowerCase() !== 'a') {
          element.setAttribute('aria-hidden', 'true');
        }
      });
    }
    this.buildLabel();
  }

  buildLabel(): void{
    if (this.rtfCopy) {
      const nativeElement = this.rtfCopyEle.nativeElement;
      this.ariaLabel = (nativeElement?.innerText || nativeElement?.textContent)?.trim().replace(/(\r\n|\r|\n)/g, ' ').replace(/\s+/g, ' ');
    } else {
      this.ariaLabel = `${(this.preFix || '')} ${this.linkCopy} + ${(this.posFix || '')}`;
    }
    this._wcagService.addAriaLabelToCheckboxInput(this.inputId, this.ariaLabel, this.ariaLabelTimeout, this.ARIA_LABEL_DELAY_MILLI);
  }

  onCheckboxChange(event: MatCheckboxChange): void {
    this.onChange.emit(event.checked);
  }

  onLinkClick(event: PointerEvent): void {
    if (event.target['href']) {
      event.preventDefault();
      this.linkClick.emit({ name: this.name });
    }
  }

  onFocusOut(): void{
    this.group.get(this.name).markAsDirty()
  }
}
