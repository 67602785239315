// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 1160px) {
  .hero-image-container {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .layout-wrapper {
    min-height: 370px;
  }
  .hero-image {
    max-width: 1176px;
  }
}
@media screen and (max-width: 767px) {
  .layout-wrapper {
    min-height: 112px;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlcm8taW1hZ2UtbGF5b3V0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0k7SUFDSSxnQkFBQTtJQUNBLG1CQUFBO0VBQ047RUFFRTtJQUNJLGlCQUFBO0VBQU47RUFFRTtJQUNJLGlCQUFBO0VBQU47QUFDRjtBQUdBO0VBQ0k7SUFDSSxpQkFBQTtFQUROO0FBQ0YiLCJmaWxlIjoiaGVyby1pbWFnZS1sYXlvdXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMTYwcHgpIHtcbiAgICAuaGVyby1pbWFnZS1jb250YWluZXJ7XG4gICAgICAgIG1hcmdpbi10b3A6IDk2cHg7XG4gICAgICAgIG1hcmdpbi1ib3R0b206IDk2cHg7XG4gICAgfVxuXG4gICAgLmxheW91dC13cmFwcGVyIHtcbiAgICAgICAgbWluLWhlaWdodDogMzcwcHg7XG4gICAgfVxuICAgIC5oZXJvLWltYWdlIHtcbiAgICAgICAgbWF4LXdpZHRoOiAxMTc2cHg7XG4gICAgfVxufVxuXG5AbWVkaWEgc2NyZWVuIGFuZCAobWF4LXdpZHRoOiA3NjdweCkge1xuICAgIC5sYXlvdXQtd3JhcHBlciB7XG4gICAgICAgIG1pbi1oZWlnaHQ6IDExMnB4O1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/hero-image-layout/hero-image-layout.component.scss"],"names":[],"mappings":"AAAA;EACI;IACI,gBAAA;IACA,mBAAA;EACN;EAEE;IACI,iBAAA;EAAN;EAEE;IACI,iBAAA;EAAN;AACF;AAGA;EACI;IACI,iBAAA;EADN;AACF;AACA,42BAA42B","sourcesContent":["@media screen and (min-width: 1160px) {\n    .hero-image-container{\n        margin-top: 96px;\n        margin-bottom: 96px;\n    }\n\n    .layout-wrapper {\n        min-height: 370px;\n    }\n    .hero-image {\n        max-width: 1176px;\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .layout-wrapper {\n        min-height: 112px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
