import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, retry} from 'rxjs/operators';
import {Plan, IFacility, IItemDetail, IFundingPlanPrice, IProductDetail} from '@models';
import {environment} from '@environment';
import {capitalizeFirstLetter} from '@core/helpers';

@Injectable({
  providedIn: 'root'
})
export class FundingPlanService {

  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor(
    private httpClient: HttpClient) {
  }

  /**
   * Checks if it's a valid plan
   * @param {string} planName
   */
  hasPlan(planName: string): boolean {
    return ['green', 'gray', 'blue', 'orange', 'alumni-blue'].indexOf(planName?.toLowerCase()) > -1;
  }

  /**
   * Request plans
   * @param moso_id 
   * @param planName 
   * @param fundingSource 
   * @param promoCode 
   * @param elapsedSeconds 
   * @param startDate 
   * @param paymentOption 
   * @returns Pan info
   */
  getMembershipPlansByFacilityId(moso_id: string, planName: string, fundingSource: string, promoCode?: string, elapsedSeconds?: number, startDate?: string | Date, paymentOption?: string): Observable<IFundingPlanPrice> {
    const params = {
      planName: capitalizeFirstLetter(planName),
    }
    
    if (fundingSource) params['fundingSource'] = fundingSource.toUpperCase();
    if (promoCode) params['promoCode'] = promoCode;
    if (elapsedSeconds) params['elapsedSeconds'] = elapsedSeconds;
    if (startDate) params['startDate'] = startDate.toString();
    if (paymentOption) params['paymentOption'] = paymentOption;

    /**
     * e.g. endpoint: v2/locations/601/product/variations/prices?planName=Blue&fundingSource=CC
     */ 
    return this.httpClient.get<IFundingPlanPrice>(`${environment.API_CONF.PRODUCT_CATALOG_API}/v2/locations/${moso_id}/product/variations/prices?`, { params });
  }

  /**
   * Maps plans fetched from CMS
   * @param {string} _planName
   * @param {IFacility} facility
   */
   mapPlans(_planName: string, facility: IFacility) {
    return (result: IFundingPlanPrice) => {
      if (!result?.prices[0]) return;
      
      if (_planName === 'alumni-blue') _planName = 'Alumni Blue';
      

      /**
       * The default funding source or required funding source is in the index 0 in the prices[] property
       */
      const itemDetailArr = result?.prices[0].PricingDetail.ItemDetail;
      const totalMonthlyFeeDiscount = this.getTotalDiscountFromDetails(itemDetailArr, 'Monthly');
      const totalStartupFeeDiscount = this.getTotalDiscountFromDetails(itemDetailArr, 'Startup');
      
      const agreementPrice = this.getPlanData(itemDetailArr, 'Monthly', 'Amount');
      const price = agreementPrice - totalMonthlyFeeDiscount;
      const startupFee = this.getPlanData(itemDetailArr, 'Startup', 'Amount') - totalStartupFeeDiscount;
      const discount = - this.getPlanData(itemDetailArr, 'Monthly', 'Promotion');
      const taxes = this.getTax(itemDetailArr);
      const planName = _planName.toLowerCase();
      const unitPrice = this.getPlanData(itemDetailArr, 'Monthly', 'UnitPrice').toString();
      const itemQuantity = this.getPlanData(itemDetailArr, 'Monthly', 'Quantity');
      const itemName = itemDetailArr[0].Name;

      const blueNoCommit = facility?.planOptions?.includes('BLUE_NON-COMMIT');
      const plans = {
        green: {
          colors: {primary: '#00A676', secondary: '#008f00'},
          accessType: 'All-Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[0],
        },
        gray: {
          colors: {primary: '#435362', secondary: '#363d42'},
          accessType: 'Single-Club Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[2],
        },
        blue: {
          colors: {primary: '#095CD3', secondary: '#0455c2'},
          accessType: 'Select-Access',
          markdownFooter: (!blueNoCommit) ? facility?.pricingMarkdownDisclaimers[1] : facility?.pricingMarkdownDisclaimers[4] || facility?.pricingMarkdownDisclaimers[1],
        },
        'alumni blue': {
          colors: {primary: '#095CD3', secondary: '#0455c2'},
          accessType: 'Select-Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[5] || facility?.pricingMarkdownDisclaimers[1],
        },
        orange: {
          colors: {primary: '#ef4836', secondary: '#c1210f'},
          accessType: 'Single-Club Access',
          markdownFooter: facility?.pricingMarkdownDisclaimers[3],
        },
      };

      const selectedPlan = plans[planName];

      const newPlan = new Plan(
        result?.prices[0].Name,
        result?.prices[0].AgreementId,
        price,
        agreementPrice,
        selectedPlan.colors,
        startupFee,
        taxes,
        discount,
        {
          accessType: selectedPlan.accessType,
          markdownFooterContent: selectedPlan.markdownFooter,
        },
        result?.prices[0].fundingSource,
        result?.prices[0].promotion,
        result?.prices[0].StartDate,
        unitPrice,
        result?.prices[0].bundleId,
        itemName,
        itemQuantity
      );
      
      return newPlan;
    };
  }

  /**
   * Returns a total discount from detail
   * @param {IItemDetail[]} itemDetail 
   * @param {string} planName 
   * @returns {(number)}
   */
  private getTotalDiscountFromDetails(itemDetail: IItemDetail[], planName: string): number {
    const itemDetailData = itemDetail.filter( item => {
      if (item.Name.includes(planName)) return item;
    });
    return Number(itemDetailData[0].Promotion) + Number(itemDetailData[0].Discount);
  }

  /**
   * Returns a property value cast to number from a plan inside 'ItemDetail' property
   * @param {IItemDetail[]} itemDetail 
   * @param {string} planName 
   * @param {string} property 
   * @returns {(number)}
   */
  private getPlanData(itemDetail: IItemDetail[], planName: string, property: string): number {
    const itemDetailData = itemDetail.filter( item => {
      if (item.Name.includes(planName)) return item;
    });
    
    return parseFloat(itemDetailData[0][property]);
  }
  
  /**
   * Returns a tax value summing Monthly plan Tax value plus Startup plan Tax value 
   * @param {IItemDetail[]} itemDetail 
   * @returns {number}
   */
  private getTax(itemDetail: IItemDetail[]): number {
    let taxes: number = 0;
    itemDetail.forEach( item => {
      if (item.Name.includes('Monthly') || item.Name.includes('Startup')) taxes += parseFloat(item.Tax);
    });

    return taxes;
  }

  /**
   * Fetch plans by `facility.id` and maps it
   * @param {string} planName
   * @param {IFacility} facility
   * @param {IFacility} fundingSource
   */
  getPlan(planName: string, facility: IFacility, fundingSource: string, promoCode?: string, elapsedSeconds?: number, paymentOption?: string): Promise<Plan> {
    if (!this.hasPlan(planName)) {
      return Promise.resolve(
        new Plan('?', 0, 0, 0, {
          primary: '#bbb',
          secondary: '#999'
        }, 0, 0, 0, {
          accessType: 'All-Access',
          markdownFooterContent: { html: ''}
        })
      );
    }

    const startDate = (facility?.status == "PRESALE") ? facility?.agreementStartDate : null;
    return this.getMembershipPlansByFacilityId(facility?.id, planName, fundingSource, promoCode, elapsedSeconds, startDate, paymentOption)
    .pipe(
      retry(3),
      map(this.mapPlans(planName, facility))
    )
    .toPromise();
  }

  /**
   * Get the product data,
   * here you can find the SKU name and the Promotion default name
   * @param {string} locationID
   * @param {string} bundleID
   */
  getProductData(locationID: string, bundleID: string): Observable<IProductDetail> {
    return this.httpClient.get<IProductDetail>(`${environment.API_CONF.PRODUCT_CATALOG_API}/v2/locations/${locationID}/product/${bundleID}`);
  }
}
