import { Directive, ElementRef, HostListener } from '@angular/core';
import { environment } from '@environment';

@Directive({
    selector: '[bwNumberOnly]'
})
export class NumberOnlyDirective {
    // Allow decimal numbers
    // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g); //
    // Allow decimal numbers with limit of 16
    private regex: RegExp = new RegExp(/^([0-9]){0,16}$/g);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Meta', 'Control', 'v', 'c', 'x', 'a'];


    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
