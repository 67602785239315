import {Component, OnInit, ViewEncapsulation, Input, inject, DestroyRef} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {PopupService} from '@core/services/popup.service';
declare let $: any;

@Component({
    selector: 'bw-warning-modal',
    templateUrl: './warning-modal.component.html',
    styleUrls: ['./warning-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WarningModalComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    @Input() warningModalData: any;
    constructor(private _popupService: PopupService) {}

    ngOnInit() {
        this._popupService._warningModal.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: boolean) => {
            if (res) {
                $('#warningModal').modal('show');
            } else {
                $('#warningModal').modal('hide');
            }
        });


        $('#warningModal').on('hidden.bs.modal', () => {
            this._popupService.turnOffWarningModal();
        });
    }
}
