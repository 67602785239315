import { createReducer, on } from '@ngrx/store';
import * as Actions from '@store/actions/facilities-by-geo.actions';
import { IFacilitiesByGeoState } from './facilities-by-geo.reducer.types';
import { AppState } from '@store/store.types';
import { IFacilitiesAction } from '@store/actions/facilities.actions.types';

/**
 * Initial state for the reducer.
 * That's the value when page loads.
 */
export const initialState: IFacilitiesByGeoState[] = [];

const mergeGeoStates = (
  state: IFacilitiesByGeoState[],
  { search }: IFacilitiesAction,
): IFacilitiesByGeoState[] => ([
  ...state,
  { search, facilities: [] },
]);
/**
 * Facilities By State Reducer
 */
export const facilitiesByGeoReducer = createReducer(
  initialState,
  on(Actions.loadFacilitiesByGeoSearchSuccess, (
    state: IFacilitiesByGeoState[],
    { facilities, search }: IFacilitiesAction,
  ): IFacilitiesByGeoState[] => [
    ...state,
    { search, facilities },
  ]),
  on(Actions.loadFacilitiesByGeoSearchFailure, mergeGeoStates)
);

/**
 * Gets facilitiesByGeo reducer
 * @param {AppState} state
 * @returns {IFacilitiesByGeoState}
 *  The facilitiesByGeo state
 */
export const facilitiesByGeo = (state: AppState): IFacilitiesByGeoState[] =>
  state.facilitiesByGeo;
