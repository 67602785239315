// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.blink-select-options {
  padding: 0;
  margin: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1vcHRpb25zLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtBQUNGOztBQUNBO0VBQ0UsVUFBQTtFQUNBLFNBQUE7QUFFRiIsImZpbGUiOiJzZWxlY3Qtb3B0aW9ucy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG59XG4uYmxpbmstc2VsZWN0LW9wdGlvbnMge1xuICBwYWRkaW5nOiAwO1xuICBtYXJnaW46IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/select/select-options/select-options.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AACA;EACE,UAAA;EACA,SAAA;AAEF;AACA,4aAA4a","sourcesContent":[":host {\n  display: block;\n}\n.blink-select-options {\n  padding: 0;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
