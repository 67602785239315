import { CMSData } from '@models';

interface IContacts {
    email: string;
    phone: string;
}

export class Contacts implements IContacts {
    email: string;
    phone: string;

    constructor(data: CMSData) {
        this.email = data.acf.contacts.email;
        this.phone = data.acf.contacts.phone;
    }
}
